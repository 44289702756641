import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Fachrul,
  fadil,
  GooglePlay,
  instagram,
  linkedin,
  LogoContentTen,
  logofix,
  LogoLP,
} from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import style from "./PrivacyPolicy.module.css";
import Login from "../../Auth/Login/Login";
import OptionRegister from "../../Auth/option-register/OptionRegister";
import { FaBars, FaTimes } from "react-icons/fa";

const PrivacyPolicy: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModal2, setShowModal2] = useState<boolean>(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const toggleModal2 = () => {
    setShowModal2((prevState) => !prevState);
  };

  const approve = () => {
    console.log("approved");
  };
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.navbar}>
        <div className={style.leftNavbar}>
          <img src={logofix} alt="" className={style.logo} />
        </div>
        <div className={`${style.centerNavbar} ${isNavOpen && style.navOpen}`}>
          <Link className={style.LinkLI} to={"/"}>
            <li
              className={style.contentLI}
              // onClick={history("/landing-page/pricing")}
            >
              Halaman Utama
            </li>
          </Link>
          <li className={style.contentLI}>Tentang</li>
          <li className={style.contentLI}>Servis</li>
          <li className={style.contentLI}>Kontak</li>
          <li className={style.contentLI}>Harga</li>
        </div>
        <div className={style.rightNavbar}>
          <button
            className={style.bttnLoginNavbar}
            onClick={() => {
              toggleModal();
            }}
          >
            Masuk
          </button>
          <button
            className={style.bttnSignupNavbar}
            onClick={() => {
              toggleModal2();
            }}
          >
            Daftar
          </button>
          <button
            className={`${style.navToggle} ${isNavOpen && style.navOpen}`}
            onClick={handleNavToggle}
          >
            {isNavOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
      {/* komen */}
      <div className={style.wrapperSK}>
        <div className={style.boxSK}>
          <h1 className={style.h2titleSK}>Privacy Policy</h1>
          <Gap height={25} width={undefined} />
          <p className={style.subTitleSK}>
            Ngabsign memiliki prisip privasi dan keamanan data yaitu : tidak
            menggunakan data anda untuk sebuah iklan, dan kami hanya meggunakan
            data anda untuk kepentingan kegiatan di lingkup aplikasi Ngabsign
            bukan untuk kepentingan komersil. Berikut yang kami lakukan untuk
            menjaga privasi dan keamanan data Anda:
          </p>
          <ol>
            <li className={style.li1}>
              Dasar hukum privasi dan kemanan data. Kami memproses data pribadi
              Anda untuk kepentingan pemrosesan data yang anda input dan
              menghasilkan output yang perusahaan Anda butuhkan demi kepentingan
              sah kami sebagai penyedia layanan.
            </li>

            <li className={style.li1}>
              Data pribadi Anda yang kami butuhkan adalah:
              <ul>
                <li className={style.li2}>
                  Data akun dasar Ngabsign, meliputi nama lengkap, tempat dan
                  tanggal lahir, department, email, nomor ponsel, dan alamat
                  domisili. Kami akan menjaga data tersebut hanya untuk
                  kepentingan perusahaan Anda dan tidak akan kami sebarkan untuk
                  kepentingan komersil atau non komersil.
                </li>
                <li className={style.li2}>
                  Data lokasi Anda, kami merecord data lokasi Anda ketika
                  melakukan absensi, dan laporan kerja untuk kami proses dan
                  kami laporkan kepada Admin perusahaan Anda.
                </li>
                <li className={style.li2}>
                  Data aktivitas absensi, cuti, izin, sakit, dan laporan kerja
                  akan tersimpan dalam sistem Ngabsign dan tidak akan kami
                  sebarkan untuk kepentingan di luar manajemen perusahaan Anda.
                </li>
              </ul>
            </li>
            <li className={style.li1}>
              Bagaimana kami menjaga kemanan dan memproses data pribadi anda ?
              <ul>
                <li className={style.li2}>
                  Kemanan enskripsi data Setiap data sensitif dikirim
                  menggunakan tingkat enkripsi 256 bit SSL, salah satu standar
                  enkripsi paling aman. 256 bit SSL merupakan teknologi yang
                  digunakan oleh bank-bank untuk menjaga agar informasi akun
                  Anda aman. SSL memastikan bahwa data yang diterima tidak
                  mengalami perubahan dalam perjalanannya dan tidak dapat dibaca
                  oleh pihak-pihak lain.
                </li>
                <li className={style.li2}>
                  Penyimpanan data Ngabsign menyimpan semua data di server
                  Google Cloud. Google merupakan Cloud Infrastructure as a
                  Service paling dipercaya di dunia. Artinya, infrastruktur
                  Ngabsign aman dan dijamin oleh Google.
                </li>
                <li className={style.li2}>
                  Backup data Data anda kami dicadangkan setiap hari, jadi Anda
                  tidak perlu khawatir kehilangan data. Kami juga meninjau
                  server, stasiun data, dan aplikasi di setiap hari oleh tim
                  ahli kami sehingga setiap temuan dapat segera ditindaklanjuti.
                </li>
                <li className={style.li2}>
                  Tidak ada iklan Dapat dipastikan pada aplikasi Ngabsign tidak
                  ada iklan yang tayang selama Anda mengoperasikannya. Hal ini
                  dikarenakan tujuan kami bukan untuk mendapatkan keuntungan
                  dari data yang kami miliki, namun untuk membantu Anda bisa
                  melakukan rangkaian pekerjaan dengan Ngabsign dengan
                  menyenangkan.
                </li>
              </ul>
            </li>
          </ol>

          <Gap height={25} width={undefined} />
          <h1 className={style.definisi}>Cover Risiko</h1>
          <p className={style.subTitleSK}>
            Sebagai penyedia layanan aplikasi presensi karyawan, Ngabsign
            memiiki tanggung jawab meminimalkan risiko yang mungkin akan muncul
            pada pengguna saat melakukan absensi. Berikut beberapa tanggung
            jawab yang dapat diterapkan Ngabsign terhadap risiko yang muncul
            pada pengguna saat absensi:
          </p>
          <ol>
            <li className={style.li1}>
              Kesulitan dalam mengakses aplikasi: Memastikan aplikasi Ngabsign
              dapat diakses dengan mudah melaluo perangkat mobile/desktop dan
              selalu tersedia untuk diunduh (Playstore dll).
            </li>
            <li className={style.li1}>
              Keterbatasan koneksi internet: Mengoptimalkan aplikasi agar dapat
              berjalan dengan baik pada koneksi internet yang lambat.
            </li>
            <li className={style.li1}>
              Masalah teknis yang muncul saat melakukan absensi: Memberikan
              dukungan teknis yang tersedia 24/7 dan panduan yang jelas tentang
              penggunaan aplikasi dengan benar.
            </li>
            <li className={style.li1}>
              Keterbatasan perangkat yang digunakan untuk melakukan absensi:
              Memastikan aplikasi dapat diakses melalui berbagai jenis perangkat
              dan platform.
            </li>
            <li className={style.li1}>
              Keterbatasan fitur: Menambahkan fitur yang lebih lengkap melalui
              research yang sesuai dengan kebutuhan pengguna dan mengikuti
              perkembangan teknologi terbaru.
            </li>
            <li className={style.li1}>
              Masalah keamanan data: Menjaga keamanan data dengan implementasi
              protokol keaman yang ketat dan menghindari tindakan yang dapat
              merugikan pengguna.
            </li>
            <li className={style.li1}>
              Tidak adanya notifikasi absensi: Memberikan notifikasi yang jelas
              dan akurat saat memasuki waktu Clock in dan Clock out.
            </li>
            <li className={style.li1}>
              Masalah verifikasi pengguna: Memperbarui teknologi verifikasi
              identitas pengguna yang lebih aman dan akurat.
            </li>
            <li className={style.li1}>
              Masalah pada database aplikasi yang dapat menyebabkan hilangnya
              data absensi pengguna: Melakukan backup data secara berkala dan
              memperbaiki masalah pada database dengan segera.
            </li>
            <li className={style.li1}>
              Gangguan yang terjadi pada server aplikasi: Memperbarui dan
              mengelola server dengan baik untuk meminimalkan risiko terjadinya
              gangguan pada server.
            </li>
            <li className={style.li1}>
              Keterbatasan ruang penyimpanan: Mengurangi ukuran aplikasi dan
              menyediakan fitur untuk membersihkan data aplikasi yang tidak
              diperlukan.
            </li>
          </ol>
          <Gap height={25} width={undefined} />
          <h1 className={style.definisi}>Hapus Data</h1>
          <p className={style.subTitleSK}>
            Anda tidak bisa menghapus data pada akun Anda, mulai dari data akun
            dasar (nama, email, dan foto) atau data laporan absensi, cuti, klaim
            dan laporan kerja. Karena hal tersebut akan meningkatkan kecurangan
            atau ketidakakuratan data yang dihasilkan.
          </p>

          <Gap height={25} width={undefined} />
          <h1 className={style.definisi}>Hapus Akun</h1>
          <p className={style.subTitleSK}>
            Anda tidak dapat menghapus akun Anda. Akun anda akan dijadikan
            status Resign oleh admin, akun anda dipakai sebagai arsip
            perusahaan.
          </p>

          <Gap height={25} width={undefined} />
          <h1 className={style.definisi}>Pembayaran</h1>
          <p className={style.subTitleSK}>
            Segala bentuk pembayaran pada Ngabsign terkait akses aplikasi akan
            dilakukan lewat email admin terdaftar.
          </p>

          <Gap height={25} width={undefined} />
          <h1 className={style.definisi}>
            Perubahan pada syarat dan ketentuan ini
          </h1>
          <p className={style.subTitleSK}>
            Kami akan meninjau dan dapat memperbarui syarat dan ketentuan ini
            dari waktu ke waktu. Setiap perubahan pada syarat dan ketentuan ini
            akan menjadi efektif ketika kami memposting syarat dan ketentuan
            yang direvisi pada halaman ini. Silakan periksa situs web kami
            secara berkala untuk melihat pembaruan atau perubahan pada syarat
            dan ketentuan kami.
          </p>

          <Gap height={25} width={undefined} />
          <h1 className={style.definisi}>Pertanyaan atau Kendala Aplikasi</h1>
          <p className={style.subTitleSK}>
            Jika Anda memiliki pertanyaan tentang privasi dan kebijakan data
            kami, silakan hubungi Ngabsign.id@gmail.com
          </p>
          <Gap height={25} width={undefined} />
        </div>
      </div>
      <Login
        title="Approve User"
        visible={showModal}
        onClose={() => toggleModal()}
        onSubmit={() => approve()}
      ></Login>
      <OptionRegister
        title="Approve User"
        visible={showModal2}
        onClose={() => toggleModal2()}
        onSubmit={() => approve()}
      ></OptionRegister>
    </div>
  );
};

export default PrivacyPolicy;
