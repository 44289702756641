import React, { useEffect, useState } from "react";
//dependency component
//my own components
import styles from "./DashboardNavbar.module.css";

//images
import {
  arrowright,
  boladunia,
  FaUserCircle,
  fotoprofile,
  IconDropdown,
  keluar,
  logofix,
  notif,
  tandatanya,
} from "../../assets";
import { UserResponse } from "../../types/user";
import { profileService, userService } from "../../services";
import Gap from "../atoms/Gap";
import { useNavigate } from "react-router-dom";
import { logout } from "../../utils/auth";
import { ProfileResponse } from "../../types/profile";

export interface NavbarProps {
  children: React.ReactNode;
}

const Navbar: React.FC<NavbarProps> = () => {
  const [profile, setProfile] = useState<ProfileResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const history = useNavigate();

  //handle logout
  const _onLogout = () => {
    logout();
    alert("Logout berhasil");
    history("/");
  };

  useEffect(() => {
    const data = async () => {
      try {
        const user = await profileService.getProfile();
        setProfile(user.data);
        console.log(user.data);
        localStorage.getItem("@token");
        return false;
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        }
      }
    };
    data();
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.left}>
          <img src={logofix} alt="logo ngabsign" className={styles.logo} />
        </div>

        <div className={styles.right}>
          <img className="icon" src={notif} id={styles.iconUser}></img>
          <div className={styles.dropdown}>
            <div
              className={styles.user}
              onClick={() => {
                setShow((prev) => !prev);
              }}
              //  onClick={() => history('/')}
            >
              {profile?.data.user_profile.avatar === null ? (
                <img src={fotoprofile} className={styles.navbarAvatar} />
              ) : (
                <img
                  className={styles.navbarAvatar}
                  src={profile?.data.user_profile.avatar}
                  alt="avatar"
                />
              )}
              {/* {profile?.data.employee.avatar == null ? <img className={styles.navbarAvatar} src={profile?.data.employee.avatar} alt="avatar" /> : <FaUserCircle className={styles.navbarAvatar}/> } */}
              <Gap height={undefined} width={10} />
              <div className={styles.dropdownContainer}>
                <span className={styles.userName}>
                  {profile?.data.user_profile.full_name.length === 10
                    ? profile?.data.user_profile.full_name
                    : profile?.data.user_profile.full_name.slice(0, 12) + "..."}
                </span>
                <span className={styles.departmenName}>
                  {profile?.data.user_profile.department}
                </span>
              </div>
              <img
                src={IconDropdown}
                alt="Icon Dropdown"
                className={styles.iconDropdown}
              />
            </div>

            {show ? (
              <div className={styles.list_child}>
                <div className={styles.containerProfile}>
                  <div className={styles.topContainerProfle}>
                    {profile?.data.user_profile.avatar === null ? (
                      <img
                        src={fotoprofile}
                        className={styles.navbarAvatarProfile}
                      />
                    ) : (
                      <img
                        className={styles.navbarAvatarProfile}
                        src={profile?.data.user_profile.avatar}
                        alt="avatar"
                      />
                    )}
                    <span className={styles.userNameProfile}>
                      {profile?.data.user_profile.full_name}
                    </span>
                    <Gap height={5} width={undefined} />
                    <span className={styles.perusahaanNameProfile}>
                      {profile?.data.user_profile.company_name}
                    </span>
                    <span className={styles.departmenNameProfile}>
                      {profile?.data.user_profile.department}
                    </span>
                  </div>
                  {/* <Gap height={undefined} width={10} /> */}
                  <div className={styles.midContainerProfile}>
                    <div>
                      <span className={styles.editProfile}>Edit Profile</span>
                    </div>
                    <Gap height={undefined} width={10} />
                    <div
                      className={styles.arrowRight}
                      onClick={() => history("/profile/informasi")}
                    >
                      <img src={arrowright} alt="" />
                    </div>
                  </div>
                  <div className={styles.bottomContainerProfile}>
                    <span className={styles.opsiLainnya}>Opsi Lainnya</span>
                    <Gap height={10} width={undefined} />
                    <div className={styles.bahasaProfile}>
                      <img src={boladunia} alt="" />
                      <Gap height={undefined} width={10} />
                      <span> Bahasa:Indonesia</span>
                    </div>
                    <Gap height={10} width={undefined} />
                    <div className={styles.bantuanProfile}>
                      <img src={tandatanya} alt="" />
                      <Gap height={undefined} width={10} />
                      <span>Butuh Bantuan?</span>
                    </div>
                    <Gap height={10} width={undefined} />
                    <div className={styles.keluarProfile}>
                      <img src={keluar} alt="" />
                      <Gap height={undefined} width={10} />
                      <span onClick={_onLogout}>Keluar</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
