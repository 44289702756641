import { useRef, useEffect, useState } from "react";
import { AiFillCamera } from "react-icons/ai";
import { BiCameraOff } from "react-icons/bi";

import styles from "./camera.module.css";
import { useNavigate } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import { MdKeyboardBackspace } from "react-icons/md";
import { IoMdArrowBack } from "react-icons/io";
import axios from "axios";
import ModalCameraPermission from "../../../component/ModalCameraPermission/ModalCameraPermission";

const Camera: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  let mediaStream: MediaStream | null = null;
  const [picture, setPicture] = useState<string | null>(null);
  const history = useNavigate();
  const state = localStorage.getItem("state");
  const [showModal, setShowModal] = useState<boolean>(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const approve = () => {
    console.log("approved");
  };

  const [showPermissionDialog, setShowPermissionDialog] = useState(false);
  const [cameraPermissionGranted, setCameraPermissionGranted] = useState(false);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      mediaStream = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.log("Error starting camera", error);
    }
  };

  const stopCamera = () => {
    const video = videoRef.current;
    if (video && (video as any).srcObject) {
      const stream = (video as any).srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track: any) => {
        track.stop();
      });

      (video as any).srcObject = null;
    }
  };

  // const takePicture = () => {
  //   if (videoRef.current && canvasRef.current) {
  //     const video = videoRef.current;
  //     const canvas = canvasRef.current;
  //     canvas.width = video.videoWidth;
  //     canvas.height = video.videoHeight;
  //     canvas
  //       .getContext("2d")
  //       ?.drawImage(video, 0, 0, canvas.width, canvas.height);
  //     const image = canvas.toDataURL("image/png");
  //     // setPicture(image);

  //     localStorage.setItem("image", image);
  //     if (state === "Clock In") {
  //       stopCamera();
  //       history("/absensi/clock-in/user-location/camera/konfirmasi-clock-in");
  //     }
  //     if (state === "Clock Out") {
  //       stopCamera();
  //       history("/absensi/clock-out/user-location/camera/konfirmasi-clock-out");
  //     }

  //     console.log("Gambar diambil");
  //   }
  // };
  const takePicture = () => {
    navigator.permissions
      .query({ name: "camera" as PermissionName })
      .then((result) => {
        if (result.state === "granted") {
          if (videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext("2d")
              ?.drawImage(video, 0, 0, canvas.width, canvas.height);
            const image = canvas.toDataURL("image/png");
            // setPicture(image);
            localStorage.setItem("image", image);

            if (state === "Clock In") {
              stopCamera();
              history(
                "/absensi/clock-in/user-location/camera/konfirmasi-clock-in"
              );
            }
            if (state === "Clock Out") {
              stopCamera();
              history(
                "/absensi/clock-out/user-location/camera/konfirmasi-clock-out"
              );
            }

            console.log("Gambar diambil");
          }
        } else {
          alert("Wajib akses kamera");
        }
      });
  };

  const checkCameraPermission = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      if (videoDevices.length > 0) {
        const permissions = await navigator.permissions.query({
          name: "camera" as PermissionName,
        });

        if (permissions.state === "granted" || permissions.state === "prompt") {
          console.log("Izin akses kamera diberikan");
          setCameraPermissionGranted(true);
          startCamera();
        } else if (permissions.state === "denied") {
          console.log("Izin akses kamera ditolak");
          setCameraPermissionGranted(false);
          setShowModal(true);
        }
      } else {
        console.log("Tidak ada perangkat kamera yang tersedia");
      }
    } catch (error) {
      console.log("Error cek izin akses kamera", error);
    }
  };

  const handleAllowAccess = async () => {
    setShowModal(false);
    await requestCameraPermission();
  };
  const handleBlockAccess = () => {
    console.log("Akses kamera diblokir oleh pengguna");
    setShowModal(true);
  };

  const requestCameraPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      console.log("Izin akses kamera diberikan");
      setCameraPermissionGranted(true);
      startCamera();
    } catch (error) {
      console.log("Izin akses kamera ditolak", error);
      setCameraPermissionGranted(false);
    }
  };

  useEffect(() => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    if (showModal && !cameraPermissionGranted) {
      const permissions = navigator.permissions.query({
        name: "camera" as PermissionName,
      });
      permissions.then((result) => {
        if (result.state === "denied") {
          handleBlockAccess();
        }
      });
    }
  }, [showModal, cameraPermissionGranted]);

  useEffect(() => {
    if (cameraPermissionGranted) {
      requestCameraPermission();
    }
  }, [cameraPermissionGranted]);

  // const handleButtonNext = () => {
  //   navigator.permissions
  //     .query({ name: "camera" as PermissionName })
  //     .then((result) => {
  //       if (result.state === "granted") {
  //         if (state === "Clock In") {
  //           stopCamera();
  //           history(
  //             "/absensi/clock-in/user-location/camera/konfirmasi-clock-in"
  //           );
  //         } else if (state === "Clock Out") {
  //           stopCamera();
  //           history(
  //             "/absensi/clock-out/user-location/camera/konfirmasi-clock-out"
  //           );
  //         }
  //       } else {
  //         alert("Wajib akses kamera");
  //       }
  //     });
  // };

  return (
    <div className={styles.wrapper}>
      <div className={styles.rectangleCamera}>
        {state === "Clock In" ? <p>Clock In</p> : <p>Clock Out</p>}
      </div>
      <div className={styles.wrapperCamera}>
        <LoadScript
          googleMapsApiKey="AIzaSyAn_Dndb3iqrnn3z05ML9WtyqhdGhJjJRg"
          libraries={["geometry", "places"]}
        >
          <button
            className={styles.btnClockIn}
            onClick={() => {
              console.log("kembali button clicked");
              window.history.back();
            }}
          >
            <IoMdArrowBack></IoMdArrowBack>
          </button>
        </LoadScript>
        <video className={styles.videoCamera} ref={videoRef} autoPlay />
        <canvas className={styles.canvasCamera} ref={canvasRef} />
        <button
          className={styles.btnTakePicture}
          onClick={() => {
            takePicture();
          }}
        >
          <AiFillCamera />
        </button>

        {showModal && (
          <ModalCameraPermission
            title="Opss!! Tidak dapat mengambil gambar"
            visible={showModal}
            onClose={handleBlockAccess}
            onGrantPermission={handleAllowAccess}
            onSubmit={handleBlockAccess}
          />
        )}
      </div>
    </div>
  );
};

export default Camera;
