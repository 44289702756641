import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BRI, Gopay, GopayOnline, rectangle } from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import { TransaksiService } from "../../../services";
import { TransaksiResponse } from "../../../types/transaksi";
import styles from "./paymentGopay.module.css";
import { BsFillCaretUpFill } from "react-icons/bs";
import { GetStatusResponse } from "../../../types/orderId";

type idParams = {
  order_id: string;
};

interface Action {
  name: string;
  method: string;
  url: string;
}

interface meta {
  status_code: string;
  status_message: string;
  transaction_id: string;
  order_id: string;
  merchant_id: string;
  gross_amount: string;
  currency: string;
  payment_type: string;
  transaction_time: string;
  transaction_status: string;
  fraud_status: string;
  actions: Action[];
  expire_time: string;
}

const PaymentGopay: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [show2, setShow2] = useState<boolean>(false);
  const [show3, setShow3] = useState<boolean>(false);
  // const [getDataTransaksi, setGetDataTransaksi] = useState<GetStatusResponse>();
  const [getStatusTransaksi, setGetStatusTransaksi] =
    useState<GetStatusResponse>();
  const { order_id } = useParams<idParams>();
  const [meta, setMeta] = useState<meta>({
    status_code: "",
    status_message: "",
    transaction_id: "",
    order_id: "",
    merchant_id: "",
    gross_amount: "",
    currency: "",
    payment_type: "",
    transaction_time: "",
    transaction_status: "",
    fraud_status: "",
    actions: [
      {
        name: "",
        method: "",
        url: "",
      },
      {
        name: "",
        method: "",
        url: "",
      },
      {
        name: "",
        method: "",
        url: "",
      },
      {
        name: "",
        method: "",
        url: "",
      },
    ],
    expire_time: "",
  });

  const history = useNavigate();
  const [copyText, setCopyText] = useState("");
  const [qParam, setQParam] = useSearchParams();

  const handleCopy = () => {
    navigator.clipboard.writeText(copyText);
    alert("Berhasil di copy");
  };

  useEffect(() => {
    const getTransaksi = async (params: string | null) => {
      if (params === null) return;
      try {
        const getStatusTransaksi = await TransaksiService.getTransaksiResponse(
          params
        );
        setGetStatusTransaksi(getStatusTransaksi.data);
        setMeta(
          JSON.parse(
            getStatusTransaksi.data.data.transaction.transaction_meta
              ? getStatusTransaksi.data.data.transaction.transaction_meta
              : ""
          )
        );
        console.log("sadasd", getTransaksi);
        console.log(
          JSON.parse(
            getStatusTransaksi.data.data.transaction.transaction_meta
              ? getStatusTransaksi.data.data.transaction.transaction_meta
              : ""
          )
        );
      } catch (error: any) {
        if (error.response.status == 401) {
          console.log("asdasd", error);
          history("/");
        }
      }
    };
    getTransaksi(qParam.get("id"));
  }, []);
  console.log("asdasd", getStatusTransaksi);
  //   console.log(meta)

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperTop}>
        <p className={styles.topTitle}>
          Selesaikan pembayaran sebelum
          <span className={styles.spanTitle}>
            {getStatusTransaksi?.data.transaction.transaction_expire}
          </span>
        </p>
      </div>

      <div className={styles.wrapperContent}>
        <div className={styles.topGopayContent}>
          <img className={styles.imgGopay} src={GopayOnline} alt="" />
        </div>
        <div className={styles.midGopayContent}>
          <img
            className={styles.imgGP}
            src={
              meta.actions[0].url.length > 0 ? meta.actions[0].url : "loading"
            }
            alt=""
          />
        </div>
        <div className={styles.bottomGopayContent}>
          <p>
            Silakan scan QR Code dengan aplikasi Gojek dan lakukan pembayaran
            dengan Gopay
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentGopay;
