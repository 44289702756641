import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DetailClockOut from "./screen/absensi/DetailClockIOut/DetailClockOut";
import EditLaporanKegiatan from "./screen/LaporanKegiatan/EditLaporanKegiatan/EditLaporanKegiatan";

import {
  Home,
  Login,
  RegisterKaryawan,
  RegisterPerusahaan,
  OptionRegister,
  Perizinan,
  LaporanKegiatan,
  ClockOut,
  ClockIn,
  ClockInUserLocation,
  ManualAbsensi,
  MonitoringKehadiran,
  AdminPerizinan,
  DashboardAdmin,
  User,
  DetailLaporanKegiatan,
  DetailPembayaran,
  // PaymentMandiri,
  PaymentBri,
  Profile,
  LandingPage,
  PresensiDigital,
  RiwayatPresensi,
  LaporanKegiatanLandingPage,
  PerformaKerja,
  JadwalKerja,
  PaymentMandiri,
  Pricing,
  PerizinanAll,
  PerizinanAccept,
  // PaymentMandiri,
} from "./screen/index";
import Informasi from "./screen/profile/Informasi/Informasi";
import KataSandi from "./screen/profile/kata-sandi/KataSandi";
import Gaji from "./screen/profile/gaji/gaji";
import Perusahaan from "./screen/profile/perusahaan/perusahaan";
import Testing from "./screen/Admin/testing/testing";
import PaymentBni from "./screen/Auth/payment-Bni/paymentBni";
import PaymentAlfamart from "./screen/Auth/payment-alfamart/paymentAlfamart";
import PaymentIndomaret from "./screen/Auth/payment-indomaret/paymentIndomaret";
import PaymentBca from "./screen/Auth/payment-Bca/paymentBca";
import PaymentPermata from "./screen/Auth/payment-permata/paymentPermata";
import PaymentGopay from "./screen/Auth/payment-gopay/paymentGopay";
import PerizinanReject from "./screen/Admin/Perizinan/list-perizinan/list-perizinan-reject/PerizinanReject";
import PerizinanLP from "./screen/LandingPage/Perizinan/PerizinanLP";
import Camera from "./screen/absensi/camera/camera";
import KonfirmasiClockIn from "./screen/absensi/KonfirmasiClockIn/KonfirmasiClockIn";
import DetailClockIn from "./screen/absensi/DetailClockIn/DetailClockIn";
import UserLocation from "./screen/absensi/ClockIn/user-location/UserLocation";
import KonfirmasiClockOut from "./screen/absensi/KonfirmasiClockOut/KonfirmasiClockOut";
import TeamStructure from "./screen/LandingPage/Team Structure/TeamStructure";
import SK from "./screen/LandingPage/Syarat dan Ketentuan/SK";
import PrivacyPolicy from "./screen/LandingPage/Privacy Policy/PrivacyPolicy";
import Attendance from "./screen/absensi/Attendance/Attendance";
import PengajuanIzin from "./screen/Perizinan/PengajuanIzin/PengajuanIzin";
import PenerimaanIzin from "./screen/Perizinan/PenerimaanIzin/PenerimaanIzin";
import PenolakanIzin from "./screen/Perizinan/PenolakanIzin/PenolakanIzin";
import FormPerizinan from "./screen/Perizinan/FormPerizinan/FormPerizinan";
import DetailPerizinan from "./screen/Perizinan/DetailPerizinan/DetailPerizinan";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          {/* <Route path="/auth/login" element={<Login />} /> */}

          <Route path="/" element={<LandingPage />}>
            <Route path="/presensi-digital" element={<PresensiDigital />} />
            <Route path="/riwayat-presensi" element={<RiwayatPresensi />} />
            <Route
              path="/laporan-kegiatan"
              element={<LaporanKegiatanLandingPage />}
            />
            <Route path="/performa-kerja" element={<PerformaKerja />} />
            <Route path="/jadwal-kerja" element={<JadwalKerja />} />

            <Route path="/perizinanLP" element={<PerizinanLP />} />
            {/* <Route path="/landing-page/gaji" /> */}
          </Route>
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/team-structure" element={<TeamStructure />} />
          <Route path="/syarat-ketentuan" element={<SK />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* <Route path="/dashboard" element={<Home />} /> */}
          <Route path="/perizinan" element={<Perizinan />}>
            <Route path="/perizinan" element={<PengajuanIzin />} />
            <Route path="penerimaan-izin" element={<PenerimaanIzin />} />
            <Route path="penolakan-izin" element={<PenolakanIzin />} />
          </Route>
          <Route path="/form-perizinan" element={<FormPerizinan />} />
          <Route path="/detail-perizinan/:id" element={<DetailPerizinan />} />
          <Route path="/laporan-kegiatan/:id" element={<LaporanKegiatan />} />
          {/* <Route path="/absensi/clock-in" element={<ClockIn />} /> */}
          <Route path="/absensi" element={<ClockIn />} />
          {/* <Route path="/attendance" element={<Attendance />} /> */}
          <Route
            path="/absensi/clock-in/user-location"
            element={<ClockInUserLocation />}
          />
          <Route
            path="/absensi/clock-in/user-location/camera"
            element={<Camera />}
          />

          <Route path="/absensi/clock-out" element={<ClockOut />} />
          {/* <Route
            path="/absensi/clock-out/user-location"
            element={<UserLocation />}
          />
          <Route
            path="/absensi/clock-out/user-location/camera"
            element={<Camera />}
          /> */}

          <Route path="/absensi/manual-absensi" element={<ManualAbsensi />} />
          <Route
            path="/absensi/clock-in/user-location/camera/konfirmasi-clock-in"
            element={<KonfirmasiClockIn />}
          />
          <Route
            path="/absensi/clock-out/user-location/camera/konfirmasi-clock-out"
            element={<KonfirmasiClockOut />}
          />
          <Route
            path="/absensi/clock-in/user-location/camera/konfirmasi-clock-in/detail-clock-in"
            element={<DetailClockIn />}
          />
          <Route
            path="/absensi/clock-out/user-location/camera/konfirmasi-clock-out/detail-clock-out"
            element={<DetailClockOut />}
          />
          {/* <Route
            path="/absensi/clock-out/detail-clock-out"
            element={<DetailClockOut />}
          /> */}
          <Route path="/user" element={<User />} />
          <Route
            path="/laporan-kegiatan/detail-laporan-kegiatan"
            element={<DetailLaporanKegiatan />}
          />
          <Route
            path="/laporan-kegiatan/edit-laporan-kegiatan/:id"
            element={<EditLaporanKegiatan />}
          />
          <Route path="/profile" element={<Profile />}>
            <Route path="/profile/informasi" element={<Informasi />} />
            <Route path="/profile/kata-sandi" element={<KataSandi />} />
            <Route path="/profile/gaji" element={<Gaji />} />
            <Route
              path="/profile/profile-perusahaan"
              element={<Perusahaan />}
            />
          </Route>
          <Route></Route>
          {/* <Route path="/auth/login" element={<Login/>}></Route> */}
          <Route
            path="/auth/register-karyawan"
            element={<RegisterKaryawan />}
          ></Route>
          <Route
            path="/auth/register-perusahaan"
            element={<RegisterPerusahaan />}
          ></Route>
          {/* <Route path="/auth/option-register" element={<OptionRegister />}>
            {" "}
          </Route> */}
          <Route
            path="/auth/register-perusahaan/detail-pembayaran"
            element={<DetailPembayaran />}
          >
            {" "}
          </Route>
          <Route
            path="/auth/register-perusahaan/detail-pembayaran/mandiri_va"
            element={<PaymentMandiri />}
          >
            {" "}
          </Route>
          <Route
            path="/auth/register-perusahaan/detail-pembayaran/bri_va"
            element={<PaymentBri />}
          >
            {" "}
          </Route>
          <Route
            path="/auth/register-perusahaan/detail-pembayaran/bni_va"
            element={<PaymentBni />}
          >
            {" "}
          </Route>
          <Route
            path="/auth/register-perusahaan/detail-pembayaran/alfamart"
            element={<PaymentAlfamart />}
          >
            {" "}
          </Route>
          <Route
            path="/auth/register-perusahaan/detail-pembayaran/indomaret"
            element={<PaymentIndomaret />}
          >
            {" "}
          </Route>
          <Route
            path="/auth/register-perusahaan/detail-pembayaran/gopay_online"
            element={<PaymentGopay />}
          >
            {" "}
          </Route>
          <Route
            path="/auth/register-perusahaan/detail-pembayaran/bca_va"
            element={<PaymentBca />}
          >
            {" "}
          </Route>
          <Route
            path="/auth/register-perusahaan/detail-pembayaran/permata_va"
            element={<PaymentPermata />}
          ></Route>
        </Route>

        <Route>
          <Route path="/admin" element={<DashboardAdmin />}></Route>
          <Route
            path="/admin/monitoring-kehadiran"
            element={<MonitoringKehadiran />}
          ></Route>
          <Route path="/testing" element={<AdminPerizinan />}></Route>
          <Route path="/admin/perizinan" element={<Testing />}>
            <Route
              path="/admin/perizinan/menunggu-persetujuan"
              element={<PerizinanAll />}
            />{" "}
            <Route
              path="/admin/perizinan/diterima"
              element={<PerizinanAccept />}
            />{" "}
            <Route
              path="/admin/perizinan/ditolak"
              element={<PerizinanReject />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
