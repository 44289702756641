import React, { useEffect, useState } from "react";
import styles from "./testing.module.css";

import Gap from "../../../component/atoms/Gap";
import DashLayoutAdmin from "../../../layouts/dashboard-layout-admin/DashLayoutAdmin";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { adminPerizinanService, kehadiranService } from "../../../services";
// import Pagination from "../../../component/pagination/Pagination";
// import { MonitoringKehadiranResponse } from "../../../types/kehadiran";
import DetailPerizinan from "../../../component/DetailPerizinan/DetailPerizinan";

const Testing: React.FC = () => {
  const navigate = useNavigate();
  const [monitoringKehadiran, setMonitoringKehadiran] = useState<any[]>([]);
  const [monitoringKehadiranPagination, setMonitoringKehadiranPagination] =
    useState<any>([]);
  const [pagePagination, setPagePagination] = useState<number>();
  const [search, setSearch] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const approve = () => {
    console.log("approved");
  };

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const inputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value;
    setSearch(data);
  };

  useEffect(() => {
    const data = async () => {
      try {
        const user = await adminPerizinanService.getAdminPerizinan();
        setMonitoringKehadiran(user.data.data.permission);
        console.log(user);
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log(error.data);
        }
      }
    };
    data();
  }, []);

  const DataPagination = (data: string): void => {
    setMonitoringKehadiranPagination(data);
  };
  const DataPagePagination = (data: number): void => {
    setPagePagination(data);
  };

  return (
    <DashLayoutAdmin>
      <div className={styles.wrapper}>
        {/* <div className={styles.topWrapper}> */}
        <div className={styles.detailContainer}>
          <div className={styles.container}>
            <div className={styles.topContainer}>
              <Gap height={30} width={undefined} />
              <h2 className={styles.pageTitle}>Kelola Perizinan Karyawan</h2>
              <h3 className={styles.pageSubTitle}>
                Verifikasi pengajuan perizinan karyawan anda
              </h3>
              <Gap height={10} width={undefined} />
            </div>
            <div className={styles.contentPerizinan}>
              <Link
                className={styles.link}
                to="/admin/perizinan/menunggu-persetujuan"
              >
                <li className={styles.licontentPerizinan}>
                  Menunggu Persetujuan
                </li>
              </Link>
              <Link className={styles.link} to="/admin/perizinan/diterima">
                <li className={styles.licontentPerizinan}>Diterima</li>
              </Link>
              <Link className={styles.link} to="/admin/perizinan/ditolak">
                <li className={styles.licontentPerizinan}>Ditolak</li>
              </Link>
            </div>
            <div className={styles.outlet}>
              <Outlet />
            </div>
            {/* <div className={styles.input}>
              <div className={styles.search}>
                <input
                  className={styles.inputSearch}
                  onChange={inputSearch}
                  id="search"
                  type="text"
                  name="search"
                  placeholder="Cari berdasarkan nama"
                />
              </div>
              <Gap height={undefined} width={30} /> */}

            {/* <div className={styles.pilihDepartemen}>
                <input
                  className={styles.inputPilihDepartemen}
                  id="search"
                  type="text"
                  name="search"
                  placeholder="Pilih Departemen"
                />
              </div>
              <Gap height={undefined} width={30} /> */}

            {/* <div className={styles.absensiHarian}>
                <input
                  className={styles.inputAbsensiHarian}
                  id="search"
                  type="text"
                  name="search"
                  placeholder="Absensi Harian"
                />
              </div>
              <Gap height={undefined} width={30} /> */}
            {/* <div className={styles.filter}>
                <img className={styles.imgFilter} src={filter} alt="Filter" />
                </div> */}
          </div>
        </div>
        {/* <div className="wrap_pagenation_filter">
          <Pagination
            getDataTable={DataPagination}
            page={DataPagePagination}
            DataPerrent={monitoringKehadiranSatu}
          />
        </div> */}
      </div>
    </DashLayoutAdmin>
  );
};

export default Testing;
