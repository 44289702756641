import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Gap from "../../../component/atoms/Gap";
import { perizinanService, userService } from "../../../services";
import { UserResponse } from "../../../types/user";
import { compressFile } from "../../../utils/compressor";
import styles from "./FormPerizinan.module.css";

const Perizinan: React.FC = () => {
  const [profile, setProfile] = useState<UserResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useNavigate();

  useEffect(() => {
    const data = async () => {
      try {
        const user = await userService.getProfile();
        setProfile(user.data);
        console.log(user.data);
        localStorage.getItem("@token");
        return false;
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        }
      }
    };
    data();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (form: any) => {
    const formData: any = new FormData();
    formData.append("type", form.type);
    formData.append("start_date", form.start_date);
    formData.append("end_date", form.end_date);
    formData.append("description", form.description);

    if (form.attachment[0].size > 2_000_000) {
      alert(
        `Maaf, tidak dapat melampirkan file karena ukuran file yang Anda pilih melebihi 2MB.`
      );
      return console.log("File Lebih dari 2MB");
    }

    try {
      console.log("Before compression: ", form.attachment[0].size);
      // Compress the file before uploading
      const compressedFile = await compressFile(form.attachment[0]);
      console.log("compressed : ", compressedFile.size);
      formData.append("attachment", compressedFile, `compressedFile.jpg`);
    } catch (error: any) {
      console.log("Failed compressing file", error);
      alert("Gagal memproses file anda!");
    }

    console.log(formData);
    setLoading(true);

    try {
      const perizinan = await perizinanService.perizinan(formData);
      console.log(perizinan);
      localStorage.getItem("@token");
      alert("Berhasil melakukan pengajuan perizinan!");
      history("/perizinan");
    } catch (error: any) {
      if (error.response && error.response.status) {
        if (error.response.status === 400) {
          console.log("kesalahan");
          console.log(error);
        } else if (error.response.status === 401) {
          history("/");
          alert("Sesi anda berakhir, silahkan login ulang!");
        } else if (error.response.status === 403) {
          alert(error.response.data.meta.message);
          console.log(error);
        } else {
          console.log(error);
          if (error.response.data.meta.message) {
            alert(error.response.data.meta.message);
          } else {
            alert("Terjadi masalah!");
          }
        }
      } else {
        console.log("Error without response or status:", error);
        alert("Terjadi masalah!");
      }
    }

    setLoading(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        {/* <div className={styles.topWrapper}> */}
        <div className={styles.detailContainer}>
          <div className={styles.container}>
            <div className={styles.leftContainer}>
              <div
                className="toggle-close w-full grid grid-cols-3 items-center justify-center shadow  sticky top-0"
                onClick={() => history("/perizinan")}
              >
                <div className="pl-4">
                  <BiArrowBack className="text-2xl text-black" />
                </div>
                <h6 className="col-span-2 text-black font-semibold">
                  Pengajuan Izin
                </h6>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.perizinanFullname}>
                  <label className={styles.labelFullname} htmlFor="fullname">
                    Nama Lengkap
                  </label>
                  <Gap height={5} width={undefined} />
                  <input
                    className={styles.inputFullname}
                    type="text"
                    value={`${profile?.data.employee.full_name}`}
                  />
                </div>

                <Gap height={10} width={undefined} />

                <div className={styles.perizinanDepartemen}>
                  <label className={styles.labelDepartemen} htmlFor="fullname">
                    Departemen
                  </label>
                  <Gap height={5} width={undefined} />
                  <input
                    className={styles.inputDepartemen}
                    type="text"
                    value={`${profile?.data.employee.department}`}
                  />
                </div>

                <Gap height={10} width={undefined} />

                <div className={styles.jenisPerizinan}>
                  <label className={styles.labelJenisPerizinan}>
                    Jenis Perizinan
                  </label>
                  <select
                    className={styles.inputJenisPerizinan}
                    {...register("type", { required: true })}
                  >
                    <option value="permit">Cuti</option>
                    <option value="sick">Sakit</option>
                  </select>
                </div>

                <Gap height={10} width={undefined} />

                <div className={styles.waktuPerizinan}>
                  <div className={styles.tglMulaiPerizinan}>
                    <label
                      className={styles.labelTanggalMulai}
                      htmlFor="fullname"
                    >
                      Tanggal Mulai
                    </label>
                    <Gap height={5} width={undefined} />
                    <input
                      className={styles.inputTanggalMulai}
                      type="date"
                      {...register("start_date", { required: true })}
                    />
                  </div>

                  <Gap height={undefined} width={30} />

                  <div className={styles.tglAkhirPerizinan}>
                    <label
                      className={styles.labelTanggalAkhir}
                      htmlFor="fullname"
                    >
                      Tanggal Akhir
                    </label>
                    <Gap height={5} width={undefined} />

                    <input
                      className={styles.inputTanggalAkhir}
                      type="date"
                      {...register("end_date", { required: true })}
                    />
                  </div>
                </div>

                <Gap height={10} width={undefined} />

                <div className={styles.lampiran}>
                  <label className={styles.labelLampiran} htmlFor="fullname">
                    Lampiran
                  </label>
                  <Gap height={5} width={undefined} />
                  <input
                    className={styles.inputLampiran}
                    // value={fileInput}
                    type="file"
                    placeholder="Lampirkan Dokumen Pendukung"
                    {...register("attachment", {
                      required: "File is required",
                    })}
                    accept="image/*" // Specify accepted file types as image
                  />
                </div>

                <Gap height={10} width={undefined} />

                <div className={styles.keterangan}>
                  <label className={styles.labelKeterangan} htmlFor="fullname">
                    Keterangan
                  </label>
                  <Gap height={5} width={undefined} />
                  <textarea
                    className={styles.inputKeterangan}
                    placeholder="Tambahkan Keterangan"
                    {...register("description", { required: true })}
                  />
                </div>

                <Gap height={15} width={undefined} />
                <button className={styles.btnPerizinan} type="submit">
                  Ajukan
                </button>
                <Gap height={30} width={undefined} />
              </form>
            </div>
            <div className={styles.rightContainer}>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Perizinan;
