import React from "react";
import { gpBlack, jadwalKerja, laporanKegiatanLP } from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import style from "./JadwalKerja.module.css";

const JadwalKerja: React.FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.wrapperLeft}>
        <img src={jadwalKerja} alt="" />
      </div>
      {/* <Gap height={undefined} width={50} /> */}
      <div className={style.wrapperRight}>
        <div className={style.title}>
          Atur Jadwal Kerja hanya dengan sekali klik
        </div>
        <Gap height={14} width={undefined} />
        <div className={style.subTitle}>
          Atur jadwal mulai dari hari kerja hingga jam masuk dan pulang
          perusahaan Anda dengan lebih mudah dan tersinkronisasi secara otomatis
          ke akun Karyawan.
        </div>
        <Gap height={44} width={undefined} />
        <div>
          <button className={style.button}>Mulai Gratis</button>
        </div>
        <Gap height={40} width={undefined} />
        <div>
          <img src={gpBlack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default JadwalKerja;
