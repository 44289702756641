import React, { useEffect, useState } from "react";
import { Fachrul } from "../../assets";
import { kehadiranService } from "../../services";
import { MonitoringKehadiranResponse } from "../../types/kehadiran";
import Gap from "../atoms/Gap";
import styles from "./DetailKehadiran.module.css";

interface ModalProps {
  title: string;
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children?: React.ReactNode;
  closeText?: string;
  submitText?: string;
  // closeBtnProps?: Omit<ButtonProps, "children">;
  // submitBtnProps?: Omit<ButtonProps, "children">;
}

const DetailKehadiran: React.FC<ModalProps> = (props) => {
  const { children, onClose, visible } = props;
  const [monitoringKehadiran, setMonitoringKehadiran] =
    useState<MonitoringKehadiranResponse>();

  useEffect(() => {
    const data = async () => {
      try {
        const user = await kehadiranService.getKehadiran();
        setMonitoringKehadiran(user.data);
        console.log(user);
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log(error.data);
        }
      }
    };
    data();
  }, []);

  if (!visible) return <></>;

  return (
    <div className={styles.wrapper}>
      <div className={styles.formWrapper}>
        <div className={styles.topWrapper}>
          <span onClick={onClose}>X</span>
        </div>
        <div className={styles.topFormWrapper}>
          <div className={styles.formTitle}>
            <span className={styles.title}>Nama Lengkap</span>
            <Gap height={5} width={undefined} />
            <span className={styles.isititle}>
              {
                monitoringKehadiran?.data.list_attendance[0].attendance[0]
                  .employee.full_name
              }
            </span>
          </div>
          <Gap height={10} width={undefined} />
          <div className={styles.formTitle}>
            <span className={styles.title}>Departemen</span>
            <Gap height={5} width={undefined} />
            <span className={styles.isititle}>
              {
                monitoringKehadiran?.data.list_attendance[0].attendance[0]
                  .employee.department
              }
            </span>
          </div>
        </div>
        <div className={styles.contentFormWrapper}>
          <div className={styles.topKotak}>Clock In</div>
          <div className={styles.isiKotak}>
            <div className={styles.imageKotak}>
              <img
                className={styles.image}
                src={
                  monitoringKehadiran?.data.list_attendance[0].attendance[0]
                    .image
                }
                // src={Fachrul}
                alt=""
              />
            </div>
            <div className={styles.isiKotaknya}>
              <div className={styles.isinya}>
                <span className={styles.spanKotak}>Tanggal</span>
                <span className={styles.isispanKotak}>
                  {
                    monitoringKehadiran?.data.list_attendance[0].attendance[0]
                      .date
                  }
                </span>
              </div>
              <div className={styles.isinya}>
                <span className={styles.spanKotak}>Status</span>
                <span className={styles.isispanKotak}>
                  {
                    monitoringKehadiran?.data.list_attendance[0].attendance[0]
                      .status
                  }
                </span>
              </div>
              <div className={styles.isinya}>
                <span className={styles.spanKotak}>Waktu</span>
                <span className={styles.isispanKotak}>
                  {
                    monitoringKehadiran?.data.list_attendance[0].attendance[0]
                      .time
                  }
                </span>
              </div>
              <div className={styles.isinya}>
                <span className={styles.spanKotak}>Lokasi</span>
                <span className={styles.isispanKotak}>
                  {
                    monitoringKehadiran?.data.list_attendance[0].attendance[0]
                      .address
                  }
                </span>
              </div>
              <div className={styles.isinya}>
                <span className={styles.spanKotak}>Catatan</span>
                <span className={styles.isispanKotak}>
                  {
                    monitoringKehadiran?.data.list_attendance[0].attendance[0]
                      .description
                  }
                </span>
              </div>
              {/* <p>huhuhu</p> */}
            </div>
          </div>
        </div>
        <Gap height={10} width={undefined} />
        <div className={styles.contentFormWrapper}>
          <div className={styles.topKotak}>Clock Out</div>
          <div className={styles.isiKotak}>
            <div className={styles.imageKotak}>
              <img
                className={styles.image}
                src={
                  monitoringKehadiran?.data.list_attendance[0].attendance[0]
                    .image
                }
                // src={Fachrul}
                alt=""
              />
            </div>
            <div className={styles.isiKotaknya}>
              <div className={styles.isinya}>
                <span className={styles.spanKotak}>Tanggal</span>
                <span className={styles.isispanKotak}>
                  {
                    monitoringKehadiran?.data.list_attendance[0].attendance[0]
                      .date
                  }
                </span>
              </div>
              <div className={styles.isinya}>
                <span className={styles.spanKotak}>Status</span>
                <span className={styles.isispanKotak}>
                  {
                    monitoringKehadiran?.data.list_attendance[0].attendance[0]
                      .status
                  }
                </span>
              </div>
              <div className={styles.isinya}>
                <span className={styles.spanKotak}>Waktu</span>
                <span className={styles.isispanKotak}>
                  {
                    monitoringKehadiran?.data.list_attendance[0].attendance[0]
                      .time
                  }
                </span>
              </div>
              <div className={styles.isinya}>
                <span className={styles.spanKotak}>Lokasi</span>
                <span className={styles.isispanKotak}>
                  {
                    monitoringKehadiran?.data.list_attendance[0].attendance[0]
                      .address
                  }
                </span>
              </div>
              <div className={styles.isinya}>
                <span className={styles.spanKotak}>Catatan</span>
                <span className={styles.isispanKotak}>
                  {
                    monitoringKehadiran?.data.list_attendance[0].attendance[0]
                      .description
                  }
                </span>
              </div>
              {/* <p>huhuhu</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailKehadiran;
