import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BRI, Permata, rectangle } from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import { TransaksiService } from "../../../services";
import { TransaksiResponse } from "../../../types/transaksi";
import styles from "./paymentPermata.module.css";
import { BsFillCaretUpFill } from "react-icons/bs";
import { GetStatusResponse } from "../../../types/orderId";

type idParams = {
  order_id: string;
};

interface va_numbers {
  bank: string;
  va_number: string;
}

interface meta {
  status_code: string;
  status_message: string;
  transaction_id: string;
  order_id: string;
  gross_amount: string;
  currency: string;
  payment_type: string;
  transaction_time: string;
  transaction_status: string;
  fraud_status: string;
  permata_va_number: string;
  merchant_id: string;
}

const PaymentPermata: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [show2, setShow2] = useState<boolean>(false);
  const [show3, setShow3] = useState<boolean>(false);
  // const [getDataTransaksi, setGetDataTransaksi] = useState<GetStatusResponse>();
  const [getStatusTransaksi, setGetStatusTransaksi] =
    useState<GetStatusResponse>();
  const { order_id } = useParams<idParams>();
  const formatRupiah = (value: any) => {
    if (!value) return;
    return value
      .toString()
      .split("")
      .reverse()
      .join("")
      .match(/\d{1,3}/g)
      .join(".")
      .split("")
      .reverse()
      .join("");
  };
  const [meta, setMeta] = useState<meta>({
    status_code: "",
    status_message: "",
    transaction_id: "",
    order_id: "",
    gross_amount: "",
    currency: "",
    payment_type: "",
    transaction_time: "",
    transaction_status: "",
    fraud_status: "",
    permata_va_number: "",
    merchant_id: "",
  });
  const history = useNavigate();
  const [copyText, setCopyText] = useState("");
  const [qParam, setQParam] = useSearchParams();

  const handleCopy = () => {
    navigator.clipboard.writeText(copyText);
    alert("Berhasil di copy");
  };

  useEffect(() => {
    const getTransaksi = async (params: string | null) => {
      if (params === null) return;
      try {
        const getStatusTransaksi = await TransaksiService.getTransaksiResponse(
          params
        );
        setGetStatusTransaksi(getStatusTransaksi.data);
        setMeta(
          JSON.parse(
            getStatusTransaksi.data.data.transaction.transaction_meta
              ? getStatusTransaksi.data.data.transaction.transaction_meta
              : ""
          )
        );
        console.log("sadasd", getTransaksi);
        console.log(
          JSON.parse(
            getStatusTransaksi.data.data.transaction.transaction_meta
              ? getStatusTransaksi.data.data.transaction.transaction_meta
              : ""
          )
        );
      } catch (error: any) {
        if (error.response.status == 401) {
          console.log("asdasd", error);
          history("/");
        }
      }
    };
    getTransaksi(qParam.get("id"));
  }, []);
  console.log("asdasd", getStatusTransaksi);
  //   console.log(meta)

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperTop}>
        <p className={styles.topTitle}>
          Selesaikan pembayaran sebelum
          <span className={styles.spanTitle}>
            {getStatusTransaksi?.data.transaction.transaction_expire}
          </span>
        </p>
      </div>

      <div className={styles.wrapperContent}>
        <div className={styles.leftWrapper}>
          <div className={styles.formLeftWrapper}>
            <div className={styles.leftTitleWrapper}>
              <img src={Permata} alt="" />
              <div className={styles.leftTitleTopWrapper}>
                <h4>{getStatusTransaksi?.data.transaction.company.name}</h4>
                {/* <h4>50 Karyawan</h4> */}
              </div>
            </div>
            <Gap height={10} width={undefined} />
            <div className={styles.leftcontentWrapperFirst}>
              <h4>Nomor Virtual Akun</h4>
              {/* <div className={styles.leftSubTitleTopWrapper}> */}
              {/* <h4>Kode Perusahaan : 70012</h4> */}
              {/* </div> */}
            </div>
            <Gap height={10} width={undefined} />
            <div className={styles.leftContentWrapper}>
              <div className={styles.inputContentWrapperVA}>
                <p
                // onChange={(e) => setCopyText(e.target.value)}
                >
                  {meta.permata_va_number.length > 0
                    ? meta.permata_va_number
                    : "loading"}
                </p>
                <span onClick={handleCopy}>Salin</span>
              </div>
            </div>
            <Gap height={20} width={undefined} />
            <div className={styles.leftContentWrapper}>
              <p>Jumlah Bayar</p>
              <Gap height={10} width={undefined} />
              <div className={styles.inputContentWrapper}>
                <p>
                  Rp.
                  {formatRupiah(
                    getStatusTransaksi?.data.transaction.transaction_amount
                  )}
                </p>
                <span onClick={handleCopy}>Salin</span>
              </div>
              <div className={styles.danger}>
                {/* <div className={styles.segitigabiadap}>
                            </div> */}
                <img src={rectangle} alt="" />
                <div className={styles.dangerText}>
                  <p>
                    PENTING!{" "}
                    <span>
                      {" "}
                      Mohon melakukan transaksi sesuai jumlah yang tertera
                      (termasuk 3 digit terakhir)
                    </span>
                  </p>
                </div>
              </div>
              <Gap height={10} width={undefined} />
              <div className={styles.textTransaksi}>
                <h3>Sudah Menyelesaikan Transaksi?</h3>
                <Gap height={5} width={undefined} />
                <p>
                  Setelah pembayaran Anda dikonfirmasi, kami akan mengirim bukti
                  pembelian ke alamat email Anda.
                </p>
              </div>
              <Gap height={20} width={undefined} />
              <div className={styles.buttonTransaksi}>
                <button className={styles.buttonBlue}>Saya Sudah Bayar</button>
                <Gap height={10} width={undefined} />
                <button className={styles.buttonWhite}>Batalkan Pesanan</button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightWrapper}>
          <div>
            <div
              className={styles.inputPetunjukWrapper}
              onClick={() => {
                setShow((prev) => !prev);
              }}
            >
              <p>Pembayaran via ATM Mandiri / Bersama</p>
              <BsFillCaretUpFill className={styles.iconDropdown} />
            </div>
            <Gap height={10} width={undefined} />
            {show ? (
              <div className={styles.list_child}>
                <div className={styles.listpetunjuk}>
                  <ol className={styles.list}>
                    <li> Di menu utama, Pilih Transaksi Lainnya</li>
                    <li> Pilih Transfer</li>
                    <li>Pilih Antar Bank Online </li>
                    <li>
                      Masukkan nomor 013 {meta.permata_va_number} (kode 013 dan
                      16 angka Virtual Account){" "}
                    </li>
                    <li>
                      Masukkan jumlah harga yang akan Anda bayar secara lengkap
                      (tanpa pembulatan). Jumlah nominal yang tidak sesuai
                      dengan tagihan akan menyebabkan transaksi gagal{" "}
                    </li>
                    <li>Kosongkan nomor referensi dan tekan Benar</li>
                    <li>
                      Di halaman konfirmasi transfer akan muncul jumlah yang
                      dibayarkan, no.rekening tujuan. Jika informasinya telah
                      cocok tekan Benar
                    </li>
                  </ol>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <Gap height={20} width={undefined} />
          <div>
            <div
              className={styles.inputPetunjukWrapper}
              onClick={() => {
                setShow2((prev) => !prev);
              }}
            >
              <p>Pembayaran via ATM BCA / Prima</p>
              <BsFillCaretUpFill className={styles.iconDropdown2} />
            </div>
            <Gap height={10} width={undefined} />
            {show2 ? (
              <div className={styles.list_child}>
                <div className={styles.listpetunjuk}>
                  <ol className={styles.list}>
                    <li>Di menu utama, Pilih Transaksi Lainnya </li>
                    <li>Pilih Transfer </li>

                    <li>Pilih Ke Rek Bank Lain </li>
                    <li>Masukkan 013 (Kode Bank Permata) lalu tekan Benar </li>
                    <li>
                      {" "}
                      Masukkan jumlah harga yang akan Anda bayar secara lengkap
                      (tanpa pembulatan), lalu tekan Benar. Penting: Jumlah
                      nominal yang tidak sesuai dengan tagihan akan menyebabkan
                      transaksi gagal
                    </li>
                    <li>
                      {" "}
                      Masukkan {meta.permata_va_number} (16 digit no. virtual
                      account pembayaran) lalu tekan Benar
                    </li>
                    <li>
                      Di halaman konfirmasi transfer akan muncul jumlah yang
                      dibayarkan, no.rekening tujuan. Jika informasinya telah
                      cocok tekan Benar
                    </li>
                  </ol>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <Gap height={20} width={undefined} />
          <div>
            <div
              className={styles.inputPetunjukWrapper}
              onClick={() => {
                setShow3((prev) => !prev);
              }}
            >
              <p>Pembayaran via ATM Permata / Alto</p>
              <BsFillCaretUpFill className={styles.iconDropdown3} />
            </div>
            <Gap height={10} width={undefined} />
            {show3 ? (
              <div className={styles.list_child}>
                <div className={styles.listpetunjuk}>
                  <ol className={styles.list}>
                    <li>Di menu utama, Pilih Transaksi Lainnya</li>

                    <li>Pilih Pembayaran</li>

                    <li>Pilih Pembayaran Lainnnya </li>

                    <li>Pilih Virtual Account </li>
                    <li>
                      Masukkan 16 digit no. Virtual Account{" "}
                      {meta.permata_va_number} dan tekan Benar
                    </li>
                    <li>
                      Di halaman konfirmasi transfer akan muncul jumlah yang
                      dibayarkan, no. Virtual Account, dan nama toko. Jika
                      informasinya telah cocok tekan Benar
                    </li>
                    <li>Pilih rekening pembayaran Anda dan tekan Benar</li>
                  </ol>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPermata;
