import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { calendar, imgnotfound } from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import {
  clockInService,
  clockOutService,
  userService,
} from "../../../services";
import { UserResponse } from "../../../types/user";
import styles from "./ClockOut.module.css";
import { LoadScript } from "@react-google-maps/api";
import { CheckPresensiResponse } from "../../../types/checkpresensi";

const ClockOut: React.FC = () => {
  const [presensi, setPresensi] = useState<CheckPresensiResponse>();
  const [startDate, setStartDate] = useState(new Date());
  const [profile, setProfile] = useState<UserResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (form: any) => {
    const formData: any = new FormData();
    setLoading(true);
    try {
      const clockIn = await clockOutService.clockOut(form);
      console.log(clockIn);
    } catch (error: any) {
      if (error.response.status == 400) {
        console.log("kesalahan");
      } else if (error.response.status == 403) {
        alert("Sudah melakukan clock Out");
      } else {
        if (error.response.data.meta.message) {
          alert(error.response.data.meta.message);
        } else {
          alert("Terjadi masalah!");
        }
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    const data = async () => {
      try {
        const user = await userService.getProfile();
        setProfile(user.data);
        console.log(user.data);
        localStorage.getItem("@token");
        return false;
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        }
      }
    };
    data();

    const present = async () => {
      try {
        const present = await clockInService.getCheckClockIn();
        setPresensi(present.data);
        // console.log(present.data);
        console.log(present.data.data.clock_in, "isinyadetail");

        localStorage.getItem("@token");
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        }
      }
    };
    present();
  }, []);

  return (
    // <DashboardLayout>

    <>
      {/* <div className={styles.topWrapper}> */}
      <div className={styles.detailContainer}>
        <div className={styles.container}>
          <div className={styles.leftContainerWrapper}>
            <div className={styles.leftContainer}>
              <div className={styles.rectangleProfileWrapper}>
                <img
                  className={styles.rectangleProfile}
                  src={
                    presensi?.data.clock_out === true
                      ? presensi.data.attendance[1].image
                      : imgnotfound
                  }
                  alt=""
                />
              </div>
              <div className={styles.wrapperInputClockIn}>
                <h2 className={styles.pageTitle}>Clock Out</h2>
                <Gap height={10} width={undefined} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles.perizinanFullname}>
                    <label className={styles.labelFullname} htmlFor="fullname">
                      Nama Lengkap
                    </label>
                    <Gap height={5} width={undefined} />
                    <p
                      className={styles.inputFullname}
                    >{`${profile?.data.employee.full_name}`}</p>
                  </div>

                  <Gap height={10} width={undefined} />

                  <div className={styles.perizinanDepartemen}>
                    <label
                      className={styles.labelDepartemen}
                      htmlFor="departemen"
                    >
                      Departemen
                    </label>
                    <Gap height={5} width={undefined} />
                    <p
                      className={styles.inputDepartemen}
                    >{`${profile?.data.employee.department}`}</p>
                  </div>

                  <Gap height={10} width={undefined} />

                  <div className={styles.tanggalClockIn}>
                    <label
                      className={styles.labelTanggalClockIn}
                      htmlFor="date"
                    >
                      Tanggal
                    </label>
                    <Gap height={5} width={undefined} />

                    {/* <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                /> */}
                    {/* <DatePicker
                    className={styles.inputTanggalClockIn}
                    selected={startDate}
                    onChange={(date) => date && setStartDate(date)}
                    showDisabledMonthNavigation
                    readOnly
                  /> */}
                    {presensi?.data.clock_out === true ? (
                      <DatePicker
                        className={styles.inputTanggalClockIn}
                        selected={startDate}
                        onChange={(date) => date && setStartDate(date)}
                        showDisabledMonthNavigation
                        readOnly
                        dateFormat="dd MMMM yyyy"
                      />
                    ) : (
                      <p>-</p>
                    )}
                  </div>

                  <Gap height={10} width={undefined} />

                  <div className={styles.jamMasuk}>
                    <label className={styles.labelJamMasuk} htmlFor="fclockin">
                      Jam Keluar
                    </label>
                    <Gap height={5} width={undefined} />

                    {/* <DatePicker
                    className={styles.inputJamMasuk}
                    selected={startDate}
                    onChange={(date) => date && setStartDate(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    showDisabledMonthNavigation
                    readOnly
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  /> */}
                    {presensi?.data.clock_out === true ? (
                      <DatePicker
                        className={styles.inputJamMasuk}
                        selected={startDate}
                        onChange={(date) => date && setStartDate(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        showDisabledMonthNavigation
                        readOnly
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        value={`${presensi?.data.attendance[1].createdAt.slice(
                          11,
                          16
                        )}`}
                      />
                    ) : (
                      <p>-</p>
                    )}
                  </div>

                  <Gap height={15} width={undefined} />
                </form>
              </div>
            </div>

            <div className={styles.wrapperbtnClockIn}>
              <LoadScript
                googleMapsApiKey="AIzaSyAn_Dndb3iqrnn3z05ML9WtyqhdGhJjJRg"
                libraries={["geometry", "places"]}
              >
                <div>
                  <button
                    className={styles.btnClockIn}
                    onClick={() => {
                      localStorage.setItem("state", "Clock Out");
                      history("/absensi/clock-in/user-location");
                    }}
                    style={
                      presensi?.data.clock_in === true
                        ? presensi?.data.clock_out === true
                          ? { background: "#b0b0b0" }
                          : {}
                        : {}
                    }
                    disabled={
                      presensi?.data.clock_in === true
                        ? presensi?.data.clock_out === true
                          ? true
                          : false
                        : false
                    }
                  >
                    Clock Out
                    {/* komen */}
                  </button>
                </div>
              </LoadScript>
            </div>
          </div>

          {/* <div className={styles.rightContainer}>
            <div>
              <Gap height={30} width={undefined} />

              <h3 className={styles.rightTitle}>Riwayat Absensi</h3>
              <Gap height={10} width={undefined} />
              <img
                className={styles.calendar}
                src={calendar}
                alt="calendar"
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
    // {/* </div>  */}
    // </DashboardLayout>
  );
};

export default ClockOut;
