import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./DashboardSidebar.module.css";
import {
  AbsensiBlue,
  AbsensiWhite,
  DashboardBlue,
  DashboardWhite,
  IconDropdown,
  LaporanKegiatanBlue,
  LaporanKegiatanWhite,
  PerizinanBlue,
  PerizinanWhite,
} from "../../assets/index";
import Gap from "../atoms/Gap";
import ModalFace from "../modalface/modalface";
import ModalFace2 from "../modal-clock-out/modalclockin";
import {
  clockInService,
  clockOutService,
  laporanKegiatanService,
} from "../../services";
import { clockInResponse } from "../../types/clockIn";
import DetailClockIn from "../../screen/absensi/KonfirmasiClockIn/KonfirmasiClockIn";
import { CheckPresensiResponse } from "../../types/checkpresensi";
import ModalDanger from "../modal-danger/modaldanger";
import { checkLaporanResponse } from "../../types/checklaporankegiatan";

export interface SidebarsProps {
  children: React.ReactNode;
}

const Sidebars: React.FC<SidebarsProps> = () => {
  const [show, setShow] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModal2, setShowModal2] = useState<boolean>(false);
  const [showModal3, setShowModal3] = useState<boolean>(false);
  const [checkClockIn, setCheckClockIn] = useState<CheckPresensiResponse>();
  const [checkClockOut, setCheckClockOut] = useState<CheckPresensiResponse>();
  const [checkReport, setCheckReport] = useState<checkLaporanResponse>();
  const [checkLaporan, setCheckLaporan] = useState<checkLaporanResponse>();

  const history = useNavigate();

  const checkClockIn2 = async () => {
    try {
      const checkClockIn = await clockInService.getCheckClockIn();
      setCheckClockIn(checkClockIn.data);
      localStorage.getItem("@token");
      console.log(checkClockIn.data.data.clock_in, "isinya");
    } catch (error: any) {
      if (error.response.status == 400) {
        console.log("kesalahan");
        console.log(error);
      } else if (error.response.status == 403) {
        history(
          "/absensi/clock-in/user-location/camera/konfirmasi-clock-in/detail-clock-in"
        );
      }
    }
  };

  const checkClockOut2 = async () => {
    try {
      const checkClockOut = await clockOutService.getCheckClockOut();
      setCheckClockOut(checkClockOut.data);
      localStorage.getItem("@token");
      console.log(checkClockOut.data.data.clock_out, "isinya");
    } catch (error: any) {
      if (error.response.status == 400) {
        console.log("kesalahan");
        console.log(error);
      } else if (error.response.status == 403) {
        // history(
        //   "/absensi/clock-out/user-location/camera/konfirmasi-clock-out/detail-clock-out"
        // );
        alert("Can not clock out, please wait at 17:00:00");
      }
    }
  };

  const checkReport2 = async () => {
    try {
      const checkReport = await laporanKegiatanService.checkLaporanKegiatan();
      setCheckReport(checkReport.data);
      localStorage.getItem("@token");
      console.log(checkReport.data.data.daily_report.status, "isinya");
      if (checkClockOut?.data.clock_out === true) {
        history(
          "/absensi/clock-out/user-location/camera/konfirmasi-clock-out/detail-clock-out"
        );
      } else {
        history("/absensi/clock-out");
      }
    } catch (error: any) {
      // if (error.response.status == 400) {
      //   console.log("kesalahan");
      //   console.log(error)
      // } else if (error.response.status == 403){
      //  console.log(error)
      // }
      // console.log(error.response.status, 'errornya')
      if (error.response.status == 403) {
        // alert("Can not clock out, please wait at 17:00:00");
        setShowModal3((prev) => !prev);
        // history("/absensi/clock-out");
        console.log("403", error);
      }
    }
  };

  const checkreport3 = async () => {
    try {
      const checkLaporan = await laporanKegiatanService.checkLaporanKegiatan();
      setCheckLaporan(checkLaporan.data);
      localStorage.getItem("@token");
      console.log(checkLaporan.data.data.daily_report.status, "isinya");
      if (checkLaporan?.data.data.daily_report.status === "done") {
        // (checkLaporan?.data.data.daily_report.id)
        history("/laporan-kegiatan/detail-laporan-kegiatan");
      }
      // else {
      //   setShowModal3((prev) => !prev);
      // }
    } catch (error: any) {
      if (error.response.status == 400) {
        console.log("kesalahan");
        console.log(error);
      } else if (error.response.status == 403) {
        setShowModal3((prev) => !prev);
        // console.log("kesalahan apayaa");
        // console.log(error);
        // history("/laporan-kegiatan/detail-laporan-kegiatan");
      }
    }
  };

  useEffect(() => {
    checkClockOut2();
    checkClockIn2();
  }, []);

  const approve = () => {
    console.log("approved");
  };

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const toggleModal2 = () => {
    setShowModal2((prevState) => !prevState);
  };

  const toggleModal3 = () => {
    setShowModal3((prevState) => !prevState);
  };

  let location = useLocation();
  // console.log(show)
  console.log(checkClockOut, "ni");
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {/* {location.pathname === "/dashboard" ? (
          <Link to="/dashboard" className={styles.linkSidebarActive}>
            <img
              src={DashboardBlue}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5 className={styles.textColor}>Dashboard</h5>
          </Link>
        ) : (
          <Link to="/dashboard" className={styles.linkSidebar}>
            <img
              src={DashboardWhite}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5 className={styles.text}>Dashboard</h5>
          </Link>
        )} */}

        {/* <div className="dropdown"> */}
        <div
          className={styles.listWrapper}
          onClick={() => {
            history("/absensi");
          }}
        >
          <div
            className={`${
              location.pathname === "/absensi" ||
              location.pathname === "/absensi/clock-out" ||
              location.pathname === "/absensi/manual-absensi"
                ? styles.linkSidebarActive
                : styles.linkSidebar
            }`}
          >
            <img
              src={
                location.pathname === "/absensi/clock-in" ||
                location.pathname === "/absensi/clock-out" ||
                location.pathname === "/absensi/manual-absensi"
                  ? AbsensiBlue
                  : AbsensiWhite
              }
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5
              className={`${
                location.pathname === "/absensi/clock-in" ||
                location.pathname === "/absensi/clock-out" ||
                location.pathname === "/absensi/manual-absensi"
                  ? styles.textColor
                  : styles.text
              }`}
            >
              Absensi
            </h5>
            {/* <img
                src={IconDropdown}
                alt="Icon Dropdown"
                className={styles.iconDropdown}
              /> */}
          </div>
        </div>
        <Gap height={10} width={undefined} />
        {show ? (
          <div className={styles.list_child}>
            {location.pathname === "/absensi/clock-in" ? (
              <p className={styles.linkSidebarActive2}>
                <h5 className={styles.textColor2}>Clock In</h5>
              </p>
            ) : (
              <p
                onClick={() => {
                  if (checkClockIn?.data.clock_in === false) {
                    history("/absensi/clock-in");
                  } else {
                    history(
                      "/absensi/clock-in/user-location/camera/konfirmasi-clock-in/detail-clock-in"
                    );
                  }
                }}
                className={styles.linkSidebar2}
              >
                <h5 className={styles.text2}>Clock In</h5>
              </p>
            )}
            {/* <Gap height={10} width={undefined} /> */}
            {location.pathname === "/absensi/clock-out" ? (
              <p className={styles.linkSidebarActive2}>
                <h5 className={styles.textColor2}>Clock Out</h5>
              </p>
            ) : (
              <p
                onClick={() => {
                  checkReport2();
                }}
                className={styles.linkSidebar2}
              >
                <h5 className={styles.text2}>Clock Out</h5>
              </p>
            )}
          </div>
        ) : (
          <></>
        )}
        {/* </div> */}
        {location.pathname === "/laporan-kegiatan" ? (
          <Link to="/laporan-kegiatan" className={styles.linkSidebarActive}>
            <img
              src={LaporanKegiatanBlue}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5 className={styles.textColor}>Laporan Kegiatan</h5>
          </Link>
        ) : (
          <p
            onClick={() => {
              history("/laporan-kegiatan/:id");
            }}
            className={styles.linkSidebar}
          >
            <img
              src={LaporanKegiatanWhite}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5 className={styles.text}>Laporan Kegiatan</h5>
          </p>
        )}
        {location.pathname === "/perizinan" ? (
          <Link to="/perizinan" className={styles.linkSidebarActive}>
            <img
              src={PerizinanBlue}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5 className={styles.textColor}>Perizinan</h5>
          </Link>
        ) : (
          <Link to="/perizinan" className={styles.linkSidebar}>
            <img
              src={PerizinanWhite}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5 className={styles.text}>Perizinan</h5>
          </Link>
        )}
        {/* User 
         {location.pathname === "/user" ? (
          <Link to="/user" className={styles.linkSidebarActive}>
            <img src={PerizinanBlue} alt="icon sidebar" className={styles.iconSidebar} />
            <h5 className={styles.textColor}>User</h5>
          </Link>
        ) : (
          <Link to="/user" className={styles.linkSidebar}>
            <img src={PerizinanWhite} alt="icon sidebar" className={styles.iconSidebar} />
            <h5 className={styles.text}>User</h5>
          </Link>
        )}
        {/* User  */}
        {/* {location.pathname === "/laporan-kegiatan/detail-laporan-kegiatan" ? (
          <Link to="/laporan-kegiatan/detail-laporan-kegiatan" className={styles.linkSidebarActive}>
            <img src={PerizinanBlue} alt="icon sidebar" className={styles.iconSidebar} />
            <h5 className={styles.textColor}>Laporan</h5>
          </Link>
        ) : (
          <Link to="/laporan-kegiatan/detail-laporan-kegiatan" className={styles.linkSidebar}>
            <img src={PerizinanWhite} alt="icon sidebar" className={styles.iconSidebar} />
            <h5 className={styles.text}>Laporan</h5>
          </Link>
        )}  */}
      </div>
      <ModalFace
        title="Approve User"
        visible={showModal}
        onClose={() => toggleModal()}
        onSubmit={() => approve()}
      ></ModalFace>

      <ModalFace2
        title="Approve User"
        visible={showModal2}
        onClosed={() => toggleModal2()}
        onSubmit={() => approve()}
      ></ModalFace2>
      {/* <ModalDanger
        title="Approve User"
        visible={showModal3}
        onClose={() => toggleModal3()}
        onSubmit={() => approve()}
      ></ModalDanger> */}
    </div>
  );
};

export default Sidebars;
