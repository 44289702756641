import React, { useEffect, useState } from "react";
import styles from "./DetailLaporan.module.css";
import { Link, useNavigate } from "react-router-dom";
import Gap from "../../../component/atoms/Gap";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import { calendar } from "../../../assets";
import { useForm } from "react-hook-form";
import { laporanKegiatanService } from "../../../services";
import { LaporanKegiatanResponse } from "../../../types/laporanKegiatan";
import ModalLocationPermission from "../../../component/Modal Lokasi Permission/ModalLokasiPermission";

const DetailLaporanKegiatan: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [laporanKegiatan, setLaporanKegiatan] =
    useState<LaporanKegiatanResponse>();
  const history = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };
  const approve = () => {
    console.log("approved");
  };

  useEffect(() => {
    const laporan = async () => {
      try {
        const laporan = await laporanKegiatanService.getLaporanKegiatan();
        setLaporanKegiatan(laporan.data);
        localStorage.getItem("@token");
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          history("/");
        }
      }
    };
    laporan();
  }, []);
  console.log(laporanKegiatan?.data.daily_report);
  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        {/* <div className={styles.topWrapper}> */}
        <div className={styles.detailContainer}>
          <div className={styles.container}>
            <div className={styles.leftContainer}>
              <Gap height={30} width={undefined} />
              <h2 className={styles.pageTitle}>Laporan Kegiatan</h2>

              <div className={styles.laporans}>
                <Gap height={40} width={undefined} />
                <span className={styles.textLaporan}>
                  {" "}
                  {laporanKegiatan?.data.daily_report[0].description}
                </span>
              </div>
              <Gap height={40} width={undefined} />
              {/* <button type="button" className={styles.btnLapKeg}>Edit</button> */}
              <Link
                to={
                  "/laporan-kegiatan/edit-laporan-kegiatan/" +
                  laporanKegiatan?.data.daily_report[0].id
                }
                className={styles.btnLapKeg}
              >
                Edit
              </Link>
              <Gap height={40} width={30} />
            </div>

            <div className={styles.rightContainer}>
              {/* <div>
                <Gap height={30} width={undefined} />
                  <img className={styles.calendar} src={calendar} alt="calendar" />
                </div> */}
            </div>
          </div>
        </div>
        {/* <ModalLocationPermission
          title="Approve User"
          visible={showModal}
          onClose={() => toggleModal()}
          onSubmit={() => approve()}
        ></ModalLocationPermission> */}
      </div>
      {/* </div>  */}
    </DashboardLayout>
  );
};

export default DetailLaporanKegiatan;
