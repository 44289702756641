import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Gap from "../../../component/atoms/Gap";
import { userService } from "../../../services";
import { UserResponse } from "../../../types/user";
import styles from "./perusahaan.module.css";

const Perusahaan: React.FC = () => {
  const [profile, setProfile] = useState<UserResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const history = useNavigate();

  useEffect(() => {
    const data = async () => {
      try {
        const user = await userService.getProfile();
        setProfile(user.data);
        console.log(user.data);
        localStorage.getItem("@token");
        return false;
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        }
      }
    };
    data();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.topinformasiProfile}>
        <span>Informasi Pribadi</span>
      </div>
      <div className={styles.border}></div>
      <div className={styles.formProfile}>
        <span>Data Perusahaan</span>

        <Gap height={5} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Nama Lengkap
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="text"
            value={`${profile?.data.employee.full_name}`}
          />
        </div>

        <Gap height={10} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Tempat Tanggal Lahir
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="text"
            value="Balikpapan, 31/12/2022"
          />
        </div>

        <Gap height={30} width={undefined} />

        <span> Kontak Pribadi</span>

        <Gap height={10} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Email
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="text"
            value="abcsampaiz@gmail.com"
          />
        </div>

        <Gap height={10} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Nomor Handphone
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="text"
            value="081234567899"
          />
        </div>

        <Gap height={10} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Alamat Domisili
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="text"
            value="Jl. Abdi Praja V, Sepinggan, Kota Balikpapan, Kalimantan Timur"
          />
        </div>

        <Gap height={35} width={undefined} />

        <button className={styles.buttonProfile}>Simpan Perubahan</button>
      </div>
    </div>
  );
};

export default Perusahaan;
