import axios from "axios";

export const httpRequestV2 = axios.create({
  baseURL: "https://www.apis.ngabsign.id/api/v2/",
});

httpRequestV2.interceptors.request.use((config) => {
  const appToken = localStorage.getItem("@token");

  if (appToken) {
    config.headers = {
      Authorization: `Bearer ${appToken}`,
    };
  }

  return config;
});

httpRequestV2.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("@token");
      console.log("Error", error);
    }
    return Promise.reject(error);
  }
);

export default httpRequestV2;
