import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Fachrul,
  fadil,
  GooglePlay,
  instagram,
  linkedin,
  LogoContentTen,
  logofix,
  LogoLP,
} from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import style from "./SK.module.css";
import Login from "../../Auth/Login/Login";
import OptionRegister from "../../Auth/option-register/OptionRegister";
import { FaBars, FaTimes } from "react-icons/fa";

const SK: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModal2, setShowModal2] = useState<boolean>(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const toggleModal2 = () => {
    setShowModal2((prevState) => !prevState);
  };

  const approve = () => {
    console.log("approved");
  };
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.navbar}>
        <div className={style.leftNavbar}>
          <img src={logofix} alt="" className={style.logo} />
        </div>
        <div className={`${style.centerNavbar} ${isNavOpen && style.navOpen}`}>
          <Link className={style.LinkLI} to={"/"}>
            <li
              className={style.contentLI}
              // onClick={history("/landing-page/pricing")}
            >
              Halaman Utama
            </li>
          </Link>
          <li className={style.contentLI}>Tentang</li>
          <li className={style.contentLI}>Servis</li>
          <li className={style.contentLI}>Kontak</li>
          <li className={style.contentLI}>Harga</li>
        </div>
        <div className={style.rightNavbar}>
          <button
            className={style.bttnLoginNavbar}
            onClick={() => {
              toggleModal();
            }}
          >
            Masuk
          </button>
          <button
            className={style.bttnSignupNavbar}
            onClick={() => {
              toggleModal2();
            }}
          >
            Daftar
          </button>
          <button
            className={`${style.navToggle} ${isNavOpen && style.navOpen}`}
            onClick={handleNavToggle}
          >
            {isNavOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
      {/* komen */}
      <div className={style.wrapperSK}>
        <div className={style.boxSK}>
          <h1 className={style.h2titleSK}>Syarat dan Ketentuan Ngabsign</h1>
          <Gap height={25} width={undefined} />
          <p className={style.subTitleSK}>
            Terima kasih telah mengunduh dan mengunjungi aplikasi Ngabsign.
            Sebelum mengakses atau menggunakan layanan yang ada di dalam situs
            aplikasi Ngabsign, pastikan anda membaca dengan cermat dan hati-hati
            Syarat dan Ketentuan Penggunaan situs dan aplikasi Ngabsign (“Syarat
            dan Ketentuan”) yang ada di halaman ini
          </p>
          <Gap height={25} width={undefined} />
          <h1 className={style.definisi}>DEFINISI</h1>
          <Gap height={15} width={undefined} />
          <ol className={style.ol1}>
            <li className={style.li1}>
              Syarat dan ketentuan ini menjelaskan persyaratan di mana pengguna
              aplikasi Ngabsign 'Anda' menggunakan Layanan kami pada{" "}
              <a href="https://www.ngabsign.id">https://www.ngabsign.id</a>.
              Syarat dan ketentuan ini harus dibaca dan disepakati oleh Anda.
            </li>
            <li className={style.li1}>
              Ngabsign merupakan layanan aplikasi produktivitas kerja berbasis
              cloud oleh PT Media Kreasi Abadi.
            </li>
            <li className={style.li1}>
              Cloud adalah sistem komputasi yang bekerja dengan menggunakan
              teknologi internet dan penyimpanan virtual. Istilah cloud (awan)
              merujuk pada storage yang terdiri dari sekumpulan server yang
              tersimpan rapi dan dikelola oleh penyedia layanan cloud.
            </li>
            <li className={style.li1}>
              Email adalah merupakan sarana dalam mengirim surat yang dilakukan
              melalui media internet. Pada aplikasi Ngabsign, email dapat
              digunakan sebagai akses login pengguna.
            </li>
            <li className={style.li1}>
              Informasi Rahasia berarti setiap data atau informasi yang
              berhubungan dengan kerjasama sebagaimana diungkapkan oleh Ngabsign
              dan Pelanggan, baik dalam bentuk yang dapat dibaca dengan mesin,
              teks, gambar, foto-foto, desain, perencanaan, presentasi atau
              dalam bentuk lainnya, termasuk tetapi tidak terbatas pada
              informasi keuangan, know-how, proses-proses, ide-ide,
              penemuan-penemuan (baik yang dapat dipatenkan atau tidak),
              skematik rahasia dagang, teknologi, data intelijen pasar,
              pemasaran dan strategi bisnis lainnya dan informasi bisnis serta
              komersial lainnya yang sifatnya rahasia (baik dalam bentuk data
              atau bentuk lainnya).
            </li>
          </ol>
          <Gap height={25} width={undefined} />
          {/* Ketentuan Layanan */}
          <h1 className={style.definisi}>Ketentuan Layanan Ngabsign</h1>
          <p className={style.subTitleSK}>
            Syarat dan ketentuan pengguna kami ini merupakan hal-hal yang perlu
            diperhatikan, dibaca dan dipahami oleh pengguna Ngabsign dan
            bersifat mengikat, yang menjelaskan persyaratan di mana Anda
            menggunakan Layanan kami pada https://www.ngabsign.id .
          </p>
          <Gap height={25} width={undefined} />
          {/* Daftar isi */}
          <h1 className={style.definisi}>Daftar Isi</h1>
          <p className={style.subTitleSK}>
            Syarat dan ketentuan pengguna Ngabsign ini menjelaskan hal-hal
            berikut: Ketentuan umum, aktivasi akses Ngabsign, Absensi dengan
            Ngabsign, pengajuan cuti/izin/sakit dengan Ngabsign, aktifitas kerja
            dengan Ngabsign, privasi dan keamanan data Anda, hapus data, hapus
            akun, pembayaran perubahan syarat dan ketentuan, pertanyaan atau
            kendala Aplikasi.
          </p>
          {/* Ketentuan Umum */}
          <Gap height={25} width={undefined} />
          <h1 className={style.definisi}>Ketentuan Umum</h1>
          <ol>
            <li className={style.li1}>
              Seluruh pegawai dari perusahaan manapun dan email aktif dapat
              menjadi pengguna Ngabsign.
            </li>
            <li className={style.li1}>
              Pegawai yang telah didaftarkan oleh perusahaan di aplikasi
              Ngabsign merupakan pengguna Ngabsign.
            </li>
            <li className={style.li1}>
              Untuk mengakses layanan aplikasi Ngabsign, pengguna dapat
              mendownload aplikasi di Google Play dengan keyword "Ngabsign".
            </li>
            <li className={style.li1}>
              Setiap 1 akses email hanya dapat digunakan untuk 1 pegawai.
            </li>
            <li className={style.li1}>
              Pengguna dapat melakukan kegiatan absensi, cuti, izin, sakit,
              laporan kerja, cek jadwal, dan melihat performance di Aplikasi
              Ngabsign ketika masa langganan aktif dan memiliki koneksi
              internet.
            </li>
            <li className={style.li1}>
              Hal-hal yang dapat dipenuhi untuk menggunakan layanan aplikasi
              Ngabsign:
              <ul typeof="circle">
                <li className={style.li2}>
                  Alamat email harus dalam keadaan aktif.
                </li>
                <li className={style.li2}>
                  Jaringan data yang memiliki koneksi internet aktif.
                </li>
                <li className={style.li2}>
                  GPS harus dalam keadaan menyala dalamkeadaan tertentu.
                </li>
              </ul>
            </li>
            <li className={style.li1}>
              Pengguna dan Password:
              <ul typeof="circle">
                <li className={style.li2}>
                  Password diperlukan sekali Anda masuk ke aplikasi Ngabsign
                  untuk memastikan bahwa benar-benar Anda yang melakukan
                  kegiatan di dalam Aplikasi Ngabsign.
                </li>
                <li className={style.li2}>
                  Password dapat diganti dengan cara konfirmasi ke admin untuk
                  mendapatkan password baru.
                </li>
                <li className={style.li2}>
                  Anda wajib menjaga kerahasiaan password yang anda miliki.
                </li>
              </ul>
            </li>
            <li className={style.li1}>
              Pengguna dan Foto Profil:
              <ul typeof="circle">
                <li className={style.li2}>
                  Foto profil digunakan untuk identifikasi bahwa yang Login di
                  Aplikasi Ngabsign.
                </li>
                <li className={style.li2}>
                  Foto profil dapat diganti dengan secara langsung lewat
                  Aplikasi Ngabsign menu profil.
                </li>
              </ul>
            </li>
            <li className={style.li1}>
              Ketika Anda menggunakan sistem fake GPS maka secara otomatis
              aplikasi Ngabsign tidak dapat digunakan.
            </li>
          </ol>

          <Gap height={25} width={undefined} />

          {/* Aktivasi Ngabsign */}
          <h1 className={style.definisi}>Aktivasi Ngabsign</h1>
          <ol>
            <li className={style.li1}>
              Anda dapat melakukan aktivasi akun aplikasi Ngabsign dengan cara
              menghubungi admin perusahaan Anda atau jika ini pertama kalinya
              dan anda tidak memiliki perusahan silahkan bisa registrasi di{" "}
              <a href="https://www.ngabsign.id">https://www.ngabsign.id</a>.
            </li>
            <li className={style.li1}>
              Setelah proses registrasi selesai, Anda akan mendapatkan akses via
              email yang telah terdaftar.
            </li>
            <li className={style.li1}>
              Anda dapat download aplikasi di Google Play dengan keyword
              Ngabsign.
            </li>
          </ol>
          <Gap height={25} width={undefined} />

          <h1 className={style.definisi}>Presensi dengan Ngabsign</h1>
          <p className={style.subTitleSK}>
            Anda dapat melakukan serangkaian kegiatan absensi dengan aplikasi
            Ngabsign sebagai berikut:
          </p>
          <ol>
            <li className={style.li1}>
              Absen masuk dan pulang: Anda dapat melakukan absen masuk dan
              pulang sesuai jadwal di aplikasi Ngabsign. Data waktu, lokasi, dan
              foto selfie akan secara otomatis tercatat dalam sistem Ngabsign.
            </li>
            <li className={style.li1}>
              Mulai lembur dan selesai lembur: Ketika Anda klik mulai lembur,
              waktu akan otomatis tercatat oleh sistem Ngabsign sesuai waktu di
              ponsel Anda yang disesuaikan dengan waktu server. Hal yang sama
              berlaku untuk selesai lembur. Lembur akan dihitung jika
              mendapatkan persetujuan dari admin. Lembur hanya bisa dilakukan
              sekali setiap hari.
            </li>
          </ol>
          <Gap height={25} width={undefined} />

          <h1 className={style.definisi}>Pengajuan Cuti/Izin/Sakit</h1>
          <p className={style.subTitleSK}>
            Pengajuan cuti/izin/sakit merupakan menu yang dapat digunakan Anda
            untuk mengajukan cuti/izin/sakit kepada atasan dengan sistem
            Ngabsign sebagai berikut:
          </p>
          <ol>
            <li className={style.li1}>
              Pengajuan surat izin: Karyawan dapat mengisi formulir surat izin
              di aplikasi karyawan dan tanda tangan atasan melalui aplikasi
              admin untuk persetujuan perizinan.
            </li>
            <li className={style.li1}>
              Memilih tipe perizinan sakit atau cuti.
            </li>
            <li className={style.li1}>Memilih tanggal perizinan.</li>
            <li className={style.li1}>
              Jika surat izin sakit, sertakan surat keputusan dari dokter dan
              diunggah sebagai gambar.
            </li>
          </ol>
          <Gap height={25} width={undefined} />

          <h1 className={style.definisi}>Aktivitas Kerja dengan Ngabsign</h1>
          <p className={style.subTitleSK}>
            Berikut yang dapat Anda lakukan saat mengirim aktivitas kerja dengan
            Ngabsign:
          </p>
          <ol>
            <li className={style.li1}>
              Aktivitas laporan kerja: Anda dapat mengisi laporan kerja sebelum
              Clock Out.
            </li>
            <li className={style.li1}>
              Deskripsi laporan: Anda dapat menuliskan deskripsi kunjungan
              hingga 200 karakter untuk setiap laporan kerja.
            </li>
            <li className={style.li1}>
              Histori dan status laporan kerja: Semua laporan kerja yang Anda
              kirimkan akan disimpan di aplikasi Ngabsign.
            </li>
          </ol>
          <Gap height={25} width={undefined} />

        
        </div>
      </div>
      <Login
        title="Approve User"
        visible={showModal}
        onClose={() => toggleModal()}
        onSubmit={() => approve()}
      ></Login>
      <OptionRegister
        title="Approve User"
        visible={showModal2}
        onClose={() => toggleModal2()}
        onSubmit={() => approve()}
      ></OptionRegister>
    </div>
  );
};

export default SK;
