import React from "react";
import { GoogleMap, LoadScript, Marker, MarkerF } from "@react-google-maps/api";
import marker from "../../assets/images/marker.png";
import styles from "./MiniMap.module.css";
interface MapWithAMarkerProps {
  state: {
    lat: string;
    lng: string;
  };
  apiKey: "AIzaSyAn_Dndb3iqrnn3z05ML9WtyqhdGhJjJRg";
}

const MapWithAMarker: React.FC<MapWithAMarkerProps> = (props) => {
  const mapContainerStyle = {
    width: "90%",
    height: "225px",
  };

  const center = {
    lat: parseFloat(props.state.lat),
    lng: parseFloat(props.state.lng),
  };

  return (
    <div className={styles.wrapperMiniMap}>
      <LoadScript googleMapsApiKey={props.apiKey}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={15}
          center={center}
        >
          <MarkerF position={center} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapWithAMarker;
