import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./MonitoringKehadiran.module.css";

import Gap from "../../../component/atoms/Gap";
import DashLayoutAdmin from "../../../layouts/dashboard-layout-admin/DashLayoutAdmin";
import { Link, useNavigate } from "react-router-dom";
import { DownloadService, kehadiranService } from "../../../services";
import Pagination from "../../../component/pagination/Pagination";
import { MonitoringKehadiranResponse } from "../../../types/kehadiran";
import httpRequestV2 from "../../../services/apiv2";
import axios from "axios";
import DatePicker from "react-datepicker";
import { download } from "../../../assets";
import { Download } from "../../../services/kehadiran";
import DetailKehadiran from "../../../component/DetailKehadiran/DetailKehadiran";
import { DownloadRequest } from "../../../types/download";
import { useForm } from "react-hook-form";

const MonitoringKehadiran: React.FC = () => {
  const navigate = useNavigate();
  const [monitoringKehadiran, setMonitoringKehadiran] = useState<any[]>([]);
  const [monitoringKehadiranPagination, setMonitoringKehadiranPagination] =
    useState<any>([]);
  const [pagePagination, setPagePagination] = useState<number>();
  const [search, setSearch] = useState<string>("");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DownloadRequest>({
    start_date: "",
    end_date: "",
  });
  console.log(search);
  const [zeroData, setZeroData] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setData((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (form: any) => {
    console.log(form);
    setLoading(true);
    try {
      const DownloadData = await DownloadService.Download(data);
      console.log(DownloadData);
      alert("berhasil");
    } catch (error: any) {
      if (error.response.status == 400) {
        console.log("kesalahan");
        console.log(error);
      } else if (error.response.status == 302) {
        console.log("jhgjhghj", error);
      }
    }

    setLoading(false);
  };

  console.log(data);

  const approve = () => {
    console.log("approved");
  };

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const inputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value;
    setSearch(data);
  };

  useEffect(() => {
    const data = async () => {
      try {
        const user = await kehadiranService.getKehadiran();
        setMonitoringKehadiran(user.data.data.list_attendance);
        console.log(user);
        if (user.data.data.list_attendance.length === 0) {
          setZeroData(true);
        }
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log(error.data);
        }
      }
    };
    data();
  }, []);

  // useEffect(() => {
  //   const download = async () => {
  //     try {
  //       const download = await kehadiranService.Download();
  //       // setMonitoringKehadiran(user.data.data.attendance);
  //       console.log(download)
  //     } catch (error: any) {
  //       if (error.response.status == 400) {
  //         console.log(error.data);
  //       }
  //     }
  //   };
  //   download();
  // },[]);

  const DataPagination = (data: string): void => {
    setMonitoringKehadiranPagination(data);
  };
  const DataPagePagination = (data: number): void => {
    setPagePagination(data);
  };

  // const download = () => {
  // if (location.search === "?type=withdrawal&source=verified") {
  //   setStatus("Approve");
  // } else if (location.search === "?type=withdrawal&source=unverified") {
  //   setStatus("Not Confirm");
  // } else {
  //   setStatus("Reject");
  // }
  //   var config = {
  //     method: "post",
  //     url: `https://www.apis-dev.ngabsign.id/api/v2/sub-admin/download/attendance/excel`,
  //     headers: {
  //       Authorization: `${localStorage.getItem("@token")}`,
  //     },
  //   };

  //   axios(config)
  //     .then((res) => {
  //       window.open(`${config.url}`);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <DashLayoutAdmin>
      <div className={styles.wrapper}>
        {/* <div className={styles.topWrapper}> */}
        <div className={styles.detailContainer}>
          <div className={styles.container}>
            <div className={styles.topContainer}>
              <Gap height={30} width={undefined} />
              <h2 className={styles.pageTitle}>Monitoring Kehadiran</h2>
              <h3 className={styles.pageSubTitle}>
                Kelola data kehadiran karyawan anda
              </h3>
              <Gap height={10} width={undefined} />
            </div>

            <div className={styles.input}>
              <div className={styles.search}>
                <input
                  className={styles.inputSearch}
                  onChange={inputSearch}
                  // value={searchTerm}
                  id="search"
                  type="text"
                  name="search"
                  placeholder="Cari berdasarkan nama"
                />
              </div>
              <Gap height={undefined} width={30} />

              <div className={styles.pilihDepartemen}>
                <input
                  className={styles.inputPilihDepartemen}
                  id="search"
                  type="text"
                  name="search"
                  placeholder="Pilih Departemen"
                />
              </div>
              <Gap height={undefined} width={30} />

              <div className={styles.absensiHarian}>
                <input
                  className={styles.inputAbsensiHarian}
                  id="search"
                  type="text"
                  name="search"
                  placeholder="Absensi Harian"
                />
              </div>
              <Gap height={undefined} width={30} />
              {/* <div className={styles.filter}>
                <img className={styles.imgFilter} src={filter} alt="Filter" />
                </div> */}
            </div>
            <div className={styles.tablecontainer}>
              <table className={styles.table}>
                <thead className={styles.head}>
                  <tr className={styles.tr}>
                    <th className={styles.th}>Nama</th>
                    <th className={styles.th}>Departement</th>
                    <th className={styles.th}>Tanggal</th>
                    <th className={styles.th}>Status</th>
                    <th className={styles.th}>Clock In</th>
                    <th className={styles.th}>Clock Out</th>
                    {/* <th className={styles.th}>State</th> */}
                    <th className={styles.th}>Lihat Detail</th>
                  </tr>
                </thead>
                <tbody className={styles.body}>
                  {monitoringKehadiranPagination && search.length >= 1
                    ? monitoringKehadiran
                        .filter((response: any) => {
                          if (search === "") {
                            return response;
                          } else if (
                            response.attendance[0].employee.full_name
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return response;
                          }
                        })
                        .map((item, index) => {
                          return (
                            <tr key={index} className={styles.trBody}>
                              <td className={styles.td}>
                                <p className={styles.isi}>
                                  {/* {item.employee.full_name} */}
                                  {item.attendance[0].employee.full_name}
                                </p>
                              </td>
                              <td className={styles.td}>
                                <p className={styles.isi}>
                                  {/* {item.employee.department} */}
                                  {item.attendance[0].employee.department}
                                </p>
                              </td>
                              <td className={styles.td}>
                                <p className={styles.isi}>
                                  {" "}
                                  {item.attendance[0].date}
                                </p>
                              </td>
                              <td className={styles.td}>
                                <p className={styles.isi}>
                                  {item.attendance[0].status}
                                </p>
                              </td>
                              <td className={styles.td}>
                                <p
                                  className={styles.isi}
                                  defaultValue={
                                    (item.attendance[0].state = "Clock In")
                                  }
                                >
                                  {item.attendance[0].time}
                                </p>
                              </td>
                              <td className={styles.td}>
                                <p className={styles.isi}>
                                  {" "}
                                  {item.attendance[0].time}w
                                </p>
                              </td>
                              {/* <td  className={styles.td}>
                          <p className={styles.isi}>{item.state}</p>
                        </td> */}
                              <td className={styles.td}>
                                <Link
                                  to={""}
                                  className={styles.isi}
                                  onClick={() => {
                                    toggleModal();
                                  }}
                                >
                                  {" "}
                                  Lihat Detail
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                    : monitoringKehadiranPagination.map(
                        (item: any, index: any) => (
                          <tr>
                            <td className={styles.td}>
                              <p className={styles.isi}>
                                {/* {item.employee.full_name} */}
                                {item.attendance[0].employee.full_name}
                              </p>
                            </td>
                            <td className={styles.td}>
                              <p className={styles.isi}>
                                {/* {item.employee.department} */}
                                {item.attendance[0].employee.department}
                              </p>
                            </td>
                            <td className={styles.td}>
                              <p className={styles.isi}>
                                {" "}
                                {item.attendance[0].date}
                              </p>
                            </td>
                            <td className={styles.td}>
                              <p className={styles.isi}>
                                {item.attendance[0].status}
                              </p>
                            </td>
                            <td className={styles.td}>
                              <p
                                className={styles.isi}
                                defaultValue={
                                  (item.attendance[0].state = "Clock In")
                                }
                              >
                                {item.attendance[0].time}
                              </p>
                            </td>
                            <td className={styles.td}>
                              <p className={styles.isi}>
                                {" "}
                                {item.attendance[0].time}w
                              </p>
                            </td>
                            {/* <td  className={styles.td}>
                          <p className={styles.isi}>{item.state}</p>
                        </td> */}
                            <td className={styles.td}>
                              <Link
                                to={""}
                                className={styles.isi}
                                onClick={() => {
                                  toggleModal();
                                }}
                              >
                                {" "}
                                Lihat Detail
                              </Link>
                            </td>
                          </tr>
                        )
                      )}
                </tbody>
              </table>
              <Gap height={50} width={undefined} />
              {zeroData && (
                <div className={styles.zeroData}>Belum ada data</div>
              )}
            </div>
          </div>
          {/* <div className="wrap_pagenation_filter">
          <Pagination
            getDataTable={DataPagination}
            page={DataPagePagination}
            DataPerrent={monitoringKehadiranSatu}
          />
        </div> */}
        </div>
        {/* <form action="" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.downloadContainer}>
            <h3 className={styles.downloadTitle}>Periode Data :</h3>
            <div className={styles.downloadDateContainer}>
              <DatePicker
                className={styles.dateDownload}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any) => {
                  setDateRange(update);
                }}
              />
            </div>
            <div>
              <input type="date" name="start_date" onChange={onChange} />
              <input type="date" name="end_date" onChange={onChange} />
            </div>
            <button className={styles.downloadBox} type="submit">
              <div className={styles.btnDownload}>Download Data</div>
            </button>
          </div>
        </form> */}
        <div className="wrap_pagenation_filter">
          <Pagination
            getDataTable={DataPagination}
            page={DataPagePagination}
            DataPerrent={monitoringKehadiran}
          />
        </div>
        <DetailKehadiran
          title="Approve User"
          visible={showModal}
          onClose={() => toggleModal()}
          onSubmit={() => approve()}
        ></DetailKehadiran>
      </div>
    </DashLayoutAdmin>
  );
};

export default MonitoringKehadiran;
