import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BRI, rectangle } from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import { TransaksiService } from "../../../services";
import { TransaksiResponse } from "../../../types/transaksi";
import styles from "./paymentBri.module.css";
import { BsFillCaretUpFill } from "react-icons/bs";
import { GetStatusResponse } from "../../../types/orderId";

type idParams = {
  order_id: string;
};

interface va_numbers {
  bank: string;
  va_number: string;
}

interface meta {
  currency: string;
  fraud_status: string;
  gross_amount: string;
  merchant_id: string;
  order_id: string;
  payment_type: string;
  status_code: string;
  status_message: string;
  // status_message: string
  transaction_id: string;
  transaction_status: string;
  transaction_time: string;
  va_numbers: va_numbers[];
}

const PaymentBri: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [show2, setShow2] = useState<boolean>(false);
  const [show3, setShow3] = useState<boolean>(false);
  // const [getDataTransaksi, setGetDataTransaksi] = useState<GetStatusResponse>();
  const [getStatusTransaksi, setGetStatusTransaksi] =
    useState<GetStatusResponse>();
  const { order_id } = useParams<idParams>();

  const formatRupiah = (value: any) => {
    if (!value) return;
    return value
      .toString()
      .split("")
      .reverse()
      .join("")
      .match(/\d{1,3}/g)
      .join(".")
      .split("")
      .reverse()
      .join("");
  };

  const [meta, setMeta] = useState<meta>({
    currency: "",
    fraud_status: "",
    gross_amount: "",
    merchant_id: "",
    order_id: "",
    payment_type: "",
    status_code: "",
    status_message: "",
    transaction_id: "",
    transaction_status: "",
    transaction_time: "",
    va_numbers: [],
  });
  const history = useNavigate();
  const [copyText, setCopyText] = useState("");
  const [qParam, setQParam] = useSearchParams();

  const handleCopy = () => {
    navigator.clipboard.writeText(copyText);
    alert("Berhasil di copy");
  };

  useEffect(() => {
    const getTransaksi = async (params: string | null) => {
      if (params === null) return;
      try {
        const getStatusTransaksi = await TransaksiService.getTransaksiResponse(
          params
        );
        setGetStatusTransaksi(getStatusTransaksi.data);
        setMeta(
          JSON.parse(
            getStatusTransaksi.data.data.transaction.transaction_meta
              ? getStatusTransaksi.data.data.transaction.transaction_meta
              : ""
          )
        );
        console.log("sadasd", getTransaksi);
        console.log(
          JSON.parse(
            getStatusTransaksi.data.data.transaction.transaction_meta
              ? getStatusTransaksi.data.data.transaction.transaction_meta
              : ""
          )
        );
      } catch (error: any) {
        if (error.response.status == 401) {
          console.log("asdasd", error);
          history("/");
        }
      }
    };
    getTransaksi(qParam.get("id"));
  }, []);
  console.log("asdasd", getStatusTransaksi);
  //   console.log(meta)

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperTop}>
        <p className={styles.topTitle}>
          Selesaikan pembayaran sebelum
          <span className={styles.spanTitle}>
            {getStatusTransaksi?.data.transaction.transaction_expire}
          </span>
        </p>
      </div>

      <div className={styles.wrapperContent}>
        <div className={styles.leftWrapper}>
          <div className={styles.formLeftWrapper}>
            <div className={styles.leftTitleWrapper}>
              <img src={BRI} alt="" />
              <div className={styles.leftTitleTopWrapper}>
                <h4>{getStatusTransaksi?.data.transaction.company.name}</h4>
                {/* <h4>50 Karyawan</h4> */}
              </div>
            </div>
            <Gap height={10} width={undefined} />
            <div className={styles.leftcontentWrapperFirst}>
              <h4>Nomor Virtual Akun</h4>
              {/* <div className={styles.leftSubTitleTopWrapper}> */}
              <h4>Kode Perusahaan : 70012</h4>
              {/* </div> */}
            </div>
            <Gap height={10} width={undefined} />
            <div className={styles.leftContentWrapper}>
              <div className={styles.inputContentWrapperVA}>
                <p
                // onChange={(e) => setCopyText(e.target.value)}
                >
                  {meta.va_numbers.length > 0
                    ? meta.va_numbers[0].va_number
                    : "loading"}
                </p>
                <span onClick={handleCopy}>Salin</span>
              </div>
            </div>
            <Gap height={20} width={undefined} />
            <div className={styles.leftContentWrapper}>
              <p>Jumlah Bayar</p>
              <Gap height={10} width={undefined} />
              <div className={styles.inputContentWrapper}>
                <p>
                  Rp.
                  {formatRupiah(
                    getStatusTransaksi?.data.transaction.transaction_amount
                  )}
                </p>
                <span onClick={handleCopy}>Salin</span>
              </div>
              <div className={styles.danger}>
                {/* <div className={styles.segitigabiadap}>
                            </div> */}
                <img src={rectangle} alt="" />
                <div className={styles.dangerText}>
                  <p>
                    PENTING!{" "}
                    <span>
                      {" "}
                      Mohon melakukan transaksi sesuai jumlah yang tertera
                      (termasuk 3 digit terakhir)
                    </span>
                  </p>
                </div>
              </div>
              <Gap height={10} width={undefined} />
              <div className={styles.textTransaksi}>
                <h3>Sudah Menyelesaikan Transaksi?</h3>
                <Gap height={5} width={undefined} />
                <p>
                  Setelah pembayaran Anda dikonfirmasi, kami akan mengirim bukti
                  pembelian ke alamat email Anda.
                </p>
              </div>
              <Gap height={20} width={undefined} />
              <div className={styles.buttonTransaksi}>
                <button className={styles.buttonBlue}>Saya Sudah Bayar</button>
                <Gap height={10} width={undefined} />
                <button className={styles.buttonWhite}>Batalkan Pesanan</button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightWrapper}>
          <div>
            <div
              className={styles.inputPetunjukWrapper}
              onClick={() => {
                setShow((prev) => !prev);
              }}
            >
              <p>Cara pembayaran via ATM BRI</p>
              <BsFillCaretUpFill className={styles.iconDropdown} />
            </div>
            <Gap height={10} width={undefined} />
            {show ? (
              <div className={styles.list_child}>
                <div className={styles.listpetunjuk}>
                  <ol className={styles.list}>
                    <li>Pada menu utama, pilih Transaksi Lain</li>
                    <li>Pilih Pembayaran</li>
                    <li>Pilih Lainnya</li>
                    <li>
                      Masukkan Nomor BRIVA {meta.va_numbers[0].va_number} dan
                      pilih Benar
                    </li>
                    <li>
                      Jumlah pembayaran, nomor BRIVA dan nama merchant akan
                      muncul pada halaman konfirmasi pembayaran. Jika informasi
                      yang dicantumkan benar, pilih Ya
                    </li>
                    <li>
                      Pembayaran telah selesai. Simpan bukti pembayaran Anda.
                    </li>
                  </ol>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <Gap height={20} width={undefined} />
          <div>
            <div
              className={styles.inputPetunjukWrapper}
              onClick={() => {
                setShow2((prev) => !prev);
              }}
            >
              <p>Cara pembayaran via Internet Banking BRI</p>
              <BsFillCaretUpFill className={styles.iconDropdown2} />
            </div>
            <Gap height={10} width={undefined} />
            {show2 ? (
              <div className={styles.list_child}>
                <div className={styles.listpetunjuk}>
                  <ol className={styles.list}>
                    <li>Masuk pada Internet Banking BRI</li>
                    <li>Pilih menu Pembayaran dan Pembelian</li>
                    <li>Pilih sub menu BRIVA</li>
                    <li>Masukkan Nomor BRIVA {meta.va_numbers[0].va_number}</li>
                    <li>
                      Jumlah pembayaran, nomor pembayaran, dan nama merchant
                      akan muncul pada halaman konfirmasi pembayaran. Jika
                      informasi yang dicantumkan benar, pilih Kirim{" "}
                    </li>
                    <li>Masukkan password dan mToken, pilih Kirim</li>
                    <li>
                      Pembayaran telah selesai. Untuk mencetak bukti transaksi,
                      pilih Cetak
                    </li>
                  </ol>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <Gap height={20} width={undefined} />
          <div>
            <div
              className={styles.inputPetunjukWrapper}
              onClick={() => {
                setShow3((prev) => !prev);
              }}
            >
              <p>Cara pembayaran via BRI mobile</p>
              <BsFillCaretUpFill className={styles.iconDropdown3} />
            </div>
            <Gap height={10} width={undefined} />
            {show3 ? (
              <div className={styles.list_child}>
                <div className={styles.listpetunjuk}>
                  <ol className={styles.list}>
                    <li>
                      Masuk ke dalam aplikasi BRI Mobile, pilih Mobile Banking
                      BRI
                    </li>
                    <li>Pilih Pembayaran, lalu pilih BRIVA</li>
                    <li>Masukkan nomor BRIVA {meta.va_numbers[0].va_number}</li>
                    <li>
                      Jumlah pembayaran, nomor pembayaran, dan nama merchant
                      akan muncul pada halaman konfirmasi pembayaran. Jika
                      informasi yang dicantumkan benar, pilih Continue
                    </li>
                    <li>Masukkan Mobile Banking BRI PIN, pilih Ok </li>
                    <li>Masukkan password dan mToken, pilih Kirim</li>
                    <li>
                      Pembayaran telah selesai. Simpan notifikasi sebagai bukti
                      pembayaran.
                    </li>
                  </ol>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentBri;
