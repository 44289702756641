import { useLocation, useNavigate } from "react-router-dom";
import { arrowleft, logofix } from "../../assets";

const NavbarProfile: React.FC = () => {
  const history = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="md:hidden w-full flex flex-row absolute justify-around  bg-slate-">
        <div onClick={() => history("/absensi")}>
          <div className="flex flex-row">
            <img className={""} src={arrowleft} alt="" />
            <h6 className="text-sm">Kembali</h6>
          </div>
          <div>
            <img src={logofix} alt="logo ngabsign" className={"h-6"} />
          </div>
        </div>
        <div
          className="flex items-center px-1 ${}"
          style={
            location.pathname === "/profile/informasi"
              ? { color: "#004CBC", borderBottom: "2px solid #004CBC" }
              : {}
          }
          onClick={() => history("/profile/informasi")}
        >
          <h6 className="text-base">Informasi Pribadi</h6>
        </div>
        <div
          className="flex items-center"
          style={
            location.pathname === "/profile/kata-sandi"
              ? { color: "#004CBC", borderBottom: "2px solid #004CBC" }
              : {}
          }
          onClick={() => history("/profile/kata-sandi")}
        >
          <h6 className="text-base">Kata Sandi</h6>
        </div>
      </div>
    </>
  );
};

export default NavbarProfile;
