import React from "react";
import { Link } from "react-router-dom";
import Gap from "../../../component/atoms/Gap";
import DashLayoutAdmin from "../../../layouts/dashboard-layout-admin/DashLayoutAdmin";
import styles from  "./DashboardAdmin.module.css"

const DashboardAdmin : React.FC = () => {
    return (
        <DashLayoutAdmin>
     <div className="wrapper">
    <div className={styles.wrapper}>
        {/* <div className={styles.topWrapper}> */}
      <div className={styles.detailContainer}>
        <div className={styles.container}>
  
        <Gap height={30} width={undefined} />
          <h2 className={styles.pageTitle}>Maaf , untuk sementara fitur ini belum tersedia </h2>
        </div>
          </div>
          </div>
          {/* </div>  */}
    </div>
    </DashLayoutAdmin>
    )
}

export default DashboardAdmin