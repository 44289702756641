import {AxiosResponse} from "axios";
import { AdminPerizinanResponse } from "../types/adminPerizinan";
import httpRequestV2 from "./apiv2";

  export const getAdminPerizinan = async (): Promise<AxiosResponse<AdminPerizinanResponse>> => {
    return await httpRequestV2.get('sub-admin/permission?status=pending')
}
export const getAdminPerizinanAccept = async (): Promise<AxiosResponse<AdminPerizinanResponse>> => {
  return await httpRequestV2.get('sub-admin/permission?status=accept')
}
export const getAdminPerizinanReject = async (): Promise<AxiosResponse<AdminPerizinanResponse>> => {
  return await httpRequestV2.get('sub-admin/permission?status=reject')
}