import React, { useContext, useEffect, useState } from "react";
import { calendar, Face1Color, Face2Color, Face3Color, Face4Color, Face5Color } from "../../assets";
import Gap from "../../component/atoms/Gap";
import ModalDanger from "../../component/modal-danger/modaldanger";
import DashboardLayout from "../../layouts/dashboard-layout/DashboardLayout";
import { clockInService, userService } from "../../services";
import { clockInResponse } from "../../types/clockIn";
import { UserResponse } from "../../types/user";

import styles from "./user.module.css"

const User: React.FC = () => {

    const [profile, setProfile] = useState<UserResponse>()
    const [cekPresensi, setCekPresensi] = useState<boolean>()
  
    const [presensi, setPresensi] = useState<clockInResponse>()
    const [loading, setLoading] = useState<boolean>(false)
    
    useEffect(() => {
        const data = async () => {         
          try {
            const user = await userService.getProfile();
            setProfile(user.data);
            console.log(user.data)
            localStorage.getItem("@token"); 
          } catch (error: any) {
            if (error.response.status == 400) {
              console.log("kesalahan");
            }
            
          }
        };
        data();

        const absen = async () => {         
          try {
            const absen = await clockInService.getDataClockIn();
            setPresensi(absen.data);
            console.log(absen)
            console.log(absen.data)
            localStorage.getItem("@token"); 
          } catch (error: any) {
            if (error.response.status == 400) {
              console.log("kesalahan");
            }
            
          }
        }; absen();
      },[]);

    return (
        <DashboardLayout>
    
    <div className={styles.wrapper}>
     
     <div className={styles.detailContainer}>
       <div className={styles.container}>
       <div className={styles.leftContainer}>
       <Gap height={30} width={undefined} />

         <h2 className={styles.pageTitle}>Clock In</h2>
         <Gap height={10} width={undefined} />
      

         <div className={styles.perizinanFullname}>
           <label className={styles.label} htmlFor="fullname" >Kondisi Karyawan</label>
           <Gap height={5} width={undefined} />
           <div className={styles.face}>
            <div className="face1" > 
            {presensi?.data.attendance.smile_scale === '0.1'? <img src={Face1Color} className={styles.face}/> : <img src={Face1Color} className={styles.img_gray} /> } 
            </div>
            <div className="face2">
            {presensi?.data.attendance.smile_scale === '0.3'? <img src={Face2Color} className={styles.face}/> : <img src={Face2Color} className={styles.img_gray} /> }
            </div>
            <div className="face3">
            {presensi?.data.attendance.smile_scale === '0.5'? <img src={Face3Color} className={styles.face} /> :<img src={Face3Color} className={styles.img_gray} /> }
            </div>
            <div className="face4">
            {presensi?.data.attendance.smile_scale === '0.7'? <img src={Face4Color} className={styles.face} /> : <img src={Face4Color} className={styles.img_gray} /> }
            </div>
            <div className="face5">
            {presensi?.data.attendance.smile_scale === '0.9'? <img src={Face5Color} className={styles.face} /> : <img src={Face5Color} className={styles.img_gray} /> }
            </div>
          </div>

       
         </div>

         <Gap height={10} width={undefined} />
         <div className={styles.fullnames}>
           <label className={styles.label} htmlFor="fullname" >Nama Lengkap</label>
           <Gap height={1} width={undefined} />
           <span className={styles.text} > {`${profile?.data.employee.full_name}`} </span>
         </div>
       
         <Gap height={10} width={undefined} />
         <div className={styles.perizinanFullname}>
           <label className={styles.label} htmlFor="fullname" >Departement</label>
           <Gap height={1} width={undefined} />
           <span className={styles.text} > {`${profile?.data.employee.department}`} </span>
         </div>

         <Gap height={10} width={undefined} />
         <div className={styles.perizinanFullname}>
           <label className={styles.label} htmlFor="fullname" >Tanggal</label>
          
           <span className={styles.text} > {`${presensi?.data.attendance.createdAt}`} </span>
         </div>
         <Gap height={10} width={undefined} />
         <div className={styles.perizinanFullname}>
          <label className={styles.label} htmlFor="fullname" >Waktu Clock Out</label>
          <span className={styles.text} >{`${presensi?.data.attendance.createdAt}`} </span>
        </div>

        
        </div>
           
           <div className={styles.rightContainer}>
               <div>
        <Gap height={30} width={undefined} />

                 <h3 className={styles.rightTitle} >Riwayat Absensi</h3>
                 <Gap height={10} width={undefined} />
                 <img className={styles.calendar} src={calendar} alt="calendar" />
               </div>
           </div>
         </div>
         </div>
           </div>
        </DashboardLayout>
    )

    
}

export default User