import React, { FormEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Face1Color, Face2Color, Face3Color, Face4Color, Face5Color } from "../../assets";
import { clockInService, clockOutService } from "../../services";
import { clockInRequest } from "../../types/clockIn";
import Gap from "../atoms/Gap";
// import Button, { ButtonProps } from "../Button/Button";
import "./modalclockin.css";

interface ModalProps {
  title: string;
  visible: boolean;
  onClosed: () => void;
  onSubmit: () => void;
  children?: React.ReactNode;
  closeText?: string;
  submitText?: string;
  // closeBtnProps?: Omit<ButtonProps, "children">;
  // submitBtnProps?: Omit<ButtonProps, "children">;
}



const ModalFace2: React.FC<ModalProps> = (props) => {
  // const [smile, setSmile] = useState({
  //   smile_scale: ""
  // });
  const [smile, setSmile] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [smile_scale, setSmile_Scale] = useState<string>("")
  
  const {visible } = props;

  const history = useNavigate();
  
  // const handleSmile = async () => {
  
  //   setLoading(true);
  //   try {
  //     const clockIn = await clockInService.clockIn;
  //     console.log(clockIn)
  //   } catch (error: any) {
  //     if (error.response.status == 400) {
  //       console.log("kesalahan");
  //     } else if (error.response.status == 403){
  //       alert("Sudah melakukan clock in")
  //     }
  //   }

  //   setLoading(false);
  // };
  const handleSmile = (event: any) => {
    // setSmile(event)
    console.log(event)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e: FormEvent<HTMLFormElement>, smile_scale: string) => {   
      // console.log(form);
      e.preventDefault()
      setLoading(true);
      let data: clockInRequest =  {
        smile_scale: `${smile_scale}`,
        description: ""
      } 
      try {
        const check_smiles = await clockOutService.clockOut(data);
        // setSmile(check_smiles.data.data.attendance.smile_scale)
        console.log(check_smiles)
        localStorage.getItem("@token");
        history('/absensi/clock-out')
        props.onClosed()
      } catch (error: any){
        if (error.response.status == 400) {
          console.log("kesalahan");
          console.log(error)
        } else if (error.response.status == 403){
          alert("Sudah mengisi data") 
          {
            window.location.href = '/absensi/clock-out/detail-clock-out'
        }
        }  else if (error.response.status == 401){
          history('/')
          }
      }
      setLoading(false);
  
  }
// console.log(smile === '0.1', smile, 'tambahan')
console.log(smile)
  if (!visible) return <></>;
  return (
    <div className="modal-face-wrapper">
      <div className="modal-face-box">
        <div className="modal-face-box-header">
          <div className="modal-face-box-title">
            <Gap height={20} width={undefined} />
            <h3>Bagaimana Kondisi Anda Hari Ini?</h3>
          </div>
        </div>

        <form onSubmit={(e) => onSubmit(e, smile)}>    
        <div className="modal-face-content">
          <div className="face">
            <div className="face1" >
              <img src={Face1Color} alt=""  onClick={() => setSmile("0.1")} className={smile === '0.1' ? 'xyz' : "img-gray"}/>
            </div>
            <div className="face2">
              <img src={Face2Color} alt="" onClick={() => setSmile("0.3")} className={smile === '0.3' ? 'xyz' : "img-gray"}/>
            </div>
            <div className="face3">
              <img src={Face3Color} alt="" onClick={() => setSmile("0.5")}  className={smile === '0.5' ? 'xyz' : "img-gray"}/>
            </div>
            <div className="face4">
              <img src={Face4Color} alt="" onClick={() => setSmile("0.7")} className={smile === '0.7' ? 'xyz' : "img-gray"}/>
            </div>
            <div className="face5">
              <img src={Face5Color} alt="" onClick={() => setSmile("0.9")} className={smile === '0.9' ? 'xyz' : "img-gray"}
             />
            </div>
          </div>
        </div>

        <div className="modal-face-footer">
          <button className="button-face" type="submit">
            Simpan
          </button>
        </div>
        </form>
      </div>
    </div>
  );
};

export default ModalFace2;