import { Axios, AxiosResponse } from "axios";
import { LoginRequest, LoginResponse } from "../types/Login";
import {
  companyCodeRequest,
  companyCodeResponse,
  RegisterRequest,
  RegisterResponse,
} from "../types/Register";
import {
  RegisterAdminRequest,
  RegisterAdminResponse,
} from "../types/registeradmin";
import httpRequest from "./api";
import httpRequestV2 from "./apiv2";
import {
  ubahPasswordRequest,
  ubahPasswordResponse,
} from "../types/ubahpassword";

export const register = (
  request: RegisterRequest
): Promise<AxiosResponse<RegisterResponse>> => {
  return httpRequest.post("/employee/register", request);
};

export const company_code = (
  request: companyCodeRequest
): Promise<AxiosResponse<companyCodeResponse>> => {
  return httpRequest.post("/company/check-code", request);
};

export const login = (
  request: LoginRequest
): Promise<AxiosResponse<LoginResponse>> => {
  return httpRequestV2.post("auth/login", request);
};

export const register_admin = (
  request: RegisterAdminRequest
): Promise<AxiosResponse<RegisterAdminResponse>> => {
  return httpRequest.post("subadmin/register-prime", request);
};

export const getDetailPembayaran = async (): Promise<
  AxiosResponse<RegisterAdminResponse>
> => {
  return await httpRequestV2.get("subadmin/register-prime");
};

export const postChangePassword = async (
  request: ubahPasswordRequest
): Promise<AxiosResponse<ubahPasswordResponse>> => {
  return await httpRequestV2.post("auth/password/change", request);
};
