import { AxiosResponse } from "axios";
import { request } from "http";
import {
  AdminDetailPerizinanResponse,
  Permission,
} from "../types/detail-perizinan-admin";
import {
  FileRequest,
  PerizinanRequest,
  PerizinanResponse,
} from "../types/perizinan";
import httpRequestV2 from "./apiv2";
import {
  DataPerizinan,
  ListPerizinanResponse,
} from "../types/ListPerizinanEmployee";
import { PerizinanByIdResponse } from "../types/perizinanById";

export const perizinan = (
  request: PerizinanRequest
): Promise<AxiosResponse<PerizinanResponse>> => {
  return httpRequestV2.post("employee/permission", request);
};

export const file = (
  request: FileRequest
): Promise<AxiosResponse<PerizinanResponse>> => {
  return httpRequestV2.post("employee/permission", request);
};

export const getDetailPerizinanAdmin = async (): // params: Permission
Promise<AxiosResponse<AdminDetailPerizinanResponse>> => {
  return await httpRequestV2.get("/sub-admin/permission/");
};
export const getListPerizinanPending = async (): // params: Permission
Promise<AxiosResponse<ListPerizinanResponse>> => {
  return await httpRequestV2.get("/employee/permission?status=pending");
};
export const getListPerizinanAccept = async (): // params: Permission
Promise<AxiosResponse<ListPerizinanResponse>> => {
  return await httpRequestV2.get("/employee/permission?status=accept");
};
export const getListPerizinanReject = async (): // params: Permission
Promise<AxiosResponse<ListPerizinanResponse>> => {
  return await httpRequestV2.get("/employee/permission?status=reject");
};
export const getPerizinanById = async (
  id: string
): // params: Permission
Promise<AxiosResponse<PerizinanByIdResponse>> => {
  return await httpRequestV2.get(`/employee/permission/${id}`);
};
