import { AxiosResponse } from "axios";
import { CheckPresensiResponse } from "../types/checkpresensi";
import { clockOutRequest, clockOutResponse } from "../types/clockOut";
import httpRequestV2 from "./apiv2";

export const clockOut = (
    request: clockOutRequest
  ): Promise<AxiosResponse<clockOutResponse>> => {
    return httpRequestV2.post("employee/attendance/clock-out", request);
  };

  export const getClockOut = async (): Promise<AxiosResponse<clockOutResponse>> => {
    return await httpRequestV2.get('employee/attendance/clock-out')
  }

  export const getCheckClockOut = async (): Promise<AxiosResponse<CheckPresensiResponse>> => {
    return await httpRequestV2.get('employee/attendance-today')
  }