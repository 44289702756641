import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./EditLaporanKegiatan.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Gap from "../../../component/atoms/Gap";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import { calendar } from "../../../assets";
import { useForm } from "react-hook-form";
import { laporanKegiatanService } from "../../../services";
import { LaporanKegiatanResponse } from "../../../types/laporanKegiatan";

// 1
type idParams = {
  id: string;
};

const EditLaporanKegiatan: React.FC = () => {
  // 2
  const { id } = useParams<idParams>();

  const history = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState({
    description: "",
  });
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [laporanKegiatan, setLaporanKegiatan] =
    useState<LaporanKegiatanResponse>();

  // 3
  useEffect(() => {
    const laporan = async (params: any) => {
      try {
        const laporan = await laporanKegiatanService.getLaporanKegiatanbyID(
          params
        );
        setLaporanKegiatan(laporan.data);
        console.log("sss", laporan.data.data.description);

        localStorage.getItem("@token");
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          history("/");
        }
      }
    };
    laporan(id);
  }, []);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const btnStateFn = () => {
    if (data.description.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const state = btnStateFn();
    setBtnDisable(state);
  }, [data.description]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (form: any) => {
    console.log("masukk");

    setLoading(true);
    const laporan = async (params: any) => {
      try {
        const laporanKegiatan =
          await laporanKegiatanService.updateLaporanKegiatan(form, params);
        history("/laporan-kegiatan/detail-laporan-kegiatan");
        localStorage.getItem("@token");
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          history("/");
        } else if (error.response.status == 403) {
          alert("Sudah mengisi laporan harian");
        }
        {
          window.location.href = "/laporan-kegiatan/detail-laporan-kegiatan";
        }
      }
    };

    laporan(id);

    setLoading(false);
  };
  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        {/* <div className={styles.topWrapper}> */}
        <div className={styles.detailContainer}>
          <div className={styles.container}>
            <div className={styles.leftContainer}>
              <Gap height={30} width={undefined} />
              <h2 className={styles.pageTitle}>Laporan Kegiatan</h2>
              {/* <h3 className={styles.pageSubTitle}>Senin, 03 Oktober 2022</h3> */}

              <Gap height={10} width={undefined} />
              <h4>Apa saja kegiatan yang kamu lakukan hari ini?</h4>
              <Gap height={5} width={undefined} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <textarea
                  className={styles.inputKeterangan}
                  placeholder="Tips : Ceritakan kegiatanmu secara singkat"
                  {...register("description", { required: true })}
                  defaultValue={laporanKegiatan?.data.description}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <Gap height={10} width={undefined} />
                <button
                  type="submit"
                  className={`${styles.btnLapKeg} ${
                    btnDisable ? "" : `${styles.btnLapKeg_active2}`
                  }`}
                  disabled={btnDisable}
                >
                  Simpan
                </button>
              </form>
            </div>
            <div className={styles.rightContainer}>
              {/* <div>
                <Gap height={30} width={undefined} />
                  <img className={styles.calendar} src={calendar} alt="calendar" />
                </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* </div>  */}
    </DashboardLayout>
  );
};

export default EditLaporanKegiatan;
