import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Gap from "../../../component/atoms/Gap";
import { authService, userService } from "../../../services";
import { UserResponse } from "../../../types/user";
import styles from "./KataSandi.module.css";
import { ubahPasswordRequest } from "../../../types/ubahpassword";

const KataSandi: React.FC = () => {
  const [profile, setProfile] = useState<UserResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [unmatch, setUnmatch] = useState<boolean>(false);
  const [password, setPassword] = useState<ubahPasswordRequest>({
    old_password: "",
    new_password: "",
  });
  const [confirmation, setConfirmation] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const history = useNavigate();

  const disableButton =
    password.old_password === "" ||
    password.new_password === "" ||
    password.new_password.length < 6 ||
    password.new_password !== confirmation;

  const changePassword = async () => {
    try {
      const resp = await authService.postChangePassword(password);
      console.log("resp : ", resp);
      alert("Password Berhasil Diubah");
      history("/absensi");
    } catch (e: any) {
      console.log("Error : ", e);
      if (e.response.status === 400) {
        {
          alert("Kata sandi lama yang anda masukan salah!");
          setUnmatch(true);
        }
      }
    }
  };

  useEffect(() => {
    const data = async () => {
      try {
        const user = await userService.getProfile();
        setProfile(user.data);
        console.log(user.data);
        localStorage.getItem("@token");
        return false;
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        }
      }
    };
    data();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.topinformasiProfile}>
        <span>Kata Sandi</span>
      </div>
      <div className={styles.border}></div>
      <div className={styles.formProfile}>
        <span>Data Pribadi</span>

        <Gap height={15} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Kata Sandi Lama
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="password"
            placeholder="Masukkan kata sandi anda saat ini"
            onChange={(e) =>
              setPassword((prev) => ({
                ...prev,
                old_password: e.target.value,
              }))
            }
            onClick={() => setUnmatch(false)}
            style={unmatch ? { borderColor: "red" } : {}}
          />
          {unmatch ? (
            <p className="text-xs text-red-700">
              Kata sandi yang anda masukan belum benar
            </p>
          ) : null}
        </div>

        <Gap height={15} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Masukkan Kata Sandi Baru
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="password"
            placeholder="Masukkan kata sandi baru"
            onChange={(e) =>
              setPassword((prev) => ({ ...prev, new_password: e.target.value }))
            }
          />
          {password.new_password.length < 6 &&
          password.new_password.length !== 0 ? (
            <p className="text-xs text-red-700">
              Kata sandi yang anda masukan kurang dari 6 karakter
            </p>
          ) : null}
        </div>
        <Gap height={15} width={undefined} />
        <div className={styles.profileFullname}>
          <label
            className={styles.labelFullname}
            htmlFor="fullname"
            onClick={() => console.log("Disable: ", disableButton)}
          >
            Konfirmasi Kata Sandi
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="password"
            placeholder="Masukkan kata sandi yang sama"
            onChange={(e) => setConfirmation(e.target.value)}
            style={
              confirmation !== password.new_password && confirmation !== ""
                ? { borderColor: "red" }
                : {}
            }
          />
          {confirmation !== password.new_password && confirmation !== "" ? (
            <p className="text-xs text-red-700">
              Konfirmasi kata sandi anda kurang tepat!
            </p>
          ) : null}{" "}
        </div>

        <Gap height={35} width={undefined} />

        <button
          className={styles.buttonProfile}
          onClick={() => changePassword()}
          disabled={disableButton}
          style={disableButton ? {} : { background: "#004cbc" }}
        >
          Simpan Perubahan
        </button>
      </div>
    </div>
  );
};

export default KataSandi;
