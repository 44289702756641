import { AxiosResponse } from "axios";
import { CheckPresensiResponse } from "../types/checkpresensi";
import { clockInRequest, clockInResponse, smileScaleRequest, smileScaleResponse } from "../types/clockIn";
import httpRequestV2 from "./apiv2";

export const clockIn = (
    request: clockInRequest
  ): Promise<AxiosResponse<clockInResponse>> => {
    return httpRequestV2.post("employee/attendance/clock-in", request);
  };

  // export const smile_scale = (
  //   request: smileScaleRequest
  // ): Promise<AxiosResponse<smileScaleResponse>> => {
  //   return httpRequestV2.post("/company/check-code", request);
    
  // };

  // export const getClockIn = async (): Promise<AxiosResponse<clockInResponse>> => {
  //   return await httpRequestV2.get('employee/attendance/clock-in')
  // }

  export const getDataClockIn = async (): Promise<AxiosResponse<clockInResponse>> => {
    return await httpRequestV2.get('employee/attendance/clock-in')
  }

  export const getCheckClockIn = async (): Promise<AxiosResponse<CheckPresensiResponse>> => {
    return await httpRequestV2.get('employee/attendance-today')
  }