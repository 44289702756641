import React from "react";
import { gpBlack, jadwalKerja, laporanKegiatanLP } from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import style from "./PerizinanLP.module.css";

const PerizinanLP: React.FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.wrapperLeft}>
        <img src={jadwalKerja} alt="" />
      </div>
      {/* <Gap height={undefined} width={50} /> */}
      <div className={style.wrapperRight}>
        <div className={style.title}>
          Perizinan lebih Efisien dengan Ngabsign
        </div>
        <Gap height={14} width={undefined} />
        <div className={style.subTitle}>
          Pengajuan Izin karyawan hanya dengan mengisi format perizinan serta
          melampirkan dokumen pendukung tanpa perlu ke kantor lagi. Verifikasi
          perizinan oleh admin lebih cepat, aman dan langsung terintegrasi ke
          database perusahaan.
        </div>
        <Gap height={44} width={undefined} />
        <div>
          <button className={style.button}>Mulai Gratis</button>
        </div>
        <Gap height={40} width={undefined} />
        <div>
          <img src={gpBlack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default PerizinanLP;
