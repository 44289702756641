import React, { FormEvent, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ModalPermission } from "../../assets";

import styles from "./ModalLokasiPermission.module.css";
import Gap from "../atoms/Gap";

interface ModalProps {
  title: string;
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children?: React.ReactNode;
  closeText?: string;
  submitText?: string;
  onGrantPermission: () => void;
  // closeBtnProps?: Omit<ButtonProps, "children">;
  // submitBtnProps?: Omit<ButtonProps, "children">;
}
const ModalLocationPermission: React.FC<ModalProps> = ({
  onGrantPermission,
  onSubmit,
}) => {
  const handleAllowAccess = () => {
    onGrantPermission();
  };

  const handleBlockAccess = () => {
    onSubmit();
  };
  return (
    <div className={styles.wrapperPermission}>
      <div className={styles.wrapperBoxPermission}>
        <div className={styles.wrapperTitlePermisson}>
          <div className={styles.titlePermisson}>
            <Gap height={40} width={undefined} />
            <h3 className={styles.h3titlePermisson}>
              Opss!! Tidak dapat mengambil lokasi
            </h3>
          </div>
        </div>

        <div className={styles.imgPermission}>
          <img src={ModalPermission} alt="" />
        </div>
        <Gap height={30} width={undefined} />
        <div className={styles.botTitlePermission}>
          <p className={styles.ptitlePermisson}>
            Aktifkan izin lokasi untuk melanjutkan presensi
          </p>
        </div>
        <Gap height={25} width={undefined} />
        <div className={styles.wrapperButtonPermission}>
          <button
            className={styles.buttonPermission}
            onClick={handleAllowAccess}
          >
            Aktifkan Izin Lokasi
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalLocationPermission;
