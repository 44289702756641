import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  achsani,
  arla,
  arummi,
  asfa,
  dobith,
  Fachrul,
  fadil,
  fajri,
  fikri,
  garis,
  garis2,
  garislurus,
  GooglePlay,
  hasbi,
  instagram,
  irmawati,
  kevin,
  linkedin,
  LogoContentTen,
  logofix,
  LogoLP,
  nanda,
  PakIstia,
  satria,
  sephia,
  SitiNazarina,
  wildan,
  yohana,
} from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import style from "./TeamStructure.module.css";
import Login from "../../Auth/Login/Login";
import OptionRegister from "../../Auth/option-register/OptionRegister";
import { FaBars, FaTimes } from "react-icons/fa";

const TeamStructure: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModal2, setShowModal2] = useState<boolean>(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const toggleModal2 = () => {
    setShowModal2((prevState) => !prevState);
  };

  const approve = () => {
    console.log("approved");
  };
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.navbar}>
        <div className={style.leftNavbar}>
          <img src={logofix} alt="" className={style.logo} />
        </div>
        <div className={`${style.centerNavbar} ${isNavOpen && style.navOpen}`}>
          <Link className={style.LinkLI} to={"/"}>
            <li
              className={style.contentLI}
              // onClick={history("/landing-page/pricing")}
            >
              Halaman Utama
            </li>
          </Link>
          <li className={style.contentLI}>Tentang</li>
          <li className={style.contentLI}>Servis</li>
          <li className={style.contentLI}>Kontak</li>
          <li className={style.contentLI}>Harga</li>
        </div>
        <div className={style.rightNavbar}>
          <button
            className={style.bttnLoginNavbar}
            onClick={() => {
              toggleModal();
            }}
          >
            Masuk
          </button>
          <button
            className={style.bttnSignupNavbar}
            onClick={() => {
              toggleModal2();
            }}
          >
            Daftar
          </button>
          <button
            className={`${style.navToggle} ${isNavOpen && style.navOpen}`}
            onClick={handleNavToggle}
          >
            {isNavOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
      {/* komen */}
      <div className={style.wrapperTeamStructure}>
        <div className={style.wrapperTitleStructure}>
          <p className={style.wrapperPTitleStructure}>Struktur Organisasi</p>
          <span className={style.wrapperSpanTitleStructure}>Ngabsign</span>
        </div>
        <div className={style.column}>
          <div className={style.boxTeamStructure}>
            <div className={style.TeamStructureOne}>
              <div className={style.wrapperCardContentSevenGrey}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={PakIstia} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Istia Budi</h5>
                <h5 className={style.positionTeamStructure}>CEO</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/budiistia">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/istia-budi-5278831a">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <Gap height={40} width={undefined} />
            <div className={style.TeamStructureTwo}>
              <div className={style.wrapperCardContentSevenGrey}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={fadil} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Fadillah Mozhaiq</h5>
                <h5 className={style.positionTeamStructure}>
                  Head of Product Developer
                </h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/mozhaiqramadhani">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/fadillah-mozhaiq-ramadhani-45ab21197">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <img className={style.garis1} src={garis} alt="" />
            {/* <Gap height={40} width={undefined} /> */}
            <div className={style.TeamStructureThree}>
              <div className={style.wrapperCardContentSevenMobile}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={achsani} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Achsani Taqwim</h5>
                <h5 className={style.positionTeamStructure}>
                  Team Lead Android Dev
                </h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/achsanit">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/achsanit/">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
                <img className={style.garislurus1} src={garislurus} alt="" />
              </div>
              <Gap height={undefined} width={40} />
              <div className={style.wrapperCardContentSevenGBA}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={arla} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Arla Amalia</h5>
                <h5 className={style.positionTeamStructure}>
                  Team Lead Bisnis Analis
                </h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/arlamalia">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <img id={style.linkedin} src={linkedin} alt="" />
                </div>
                <img className={style.garislurus1} src={garislurus} alt="" />
              </div>
              <Gap height={undefined} width={40} />
              <div className={style.wrapperCardContentSevenUIUX}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={sephia} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Sephia Salsabila</h5>
                <h5 className={style.positionTeamStructure}>
                  Team Lead UI/UX Design
                </h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/sephiaslsblaa">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/sephiasalsabilaa">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
                <img className={style.garislurus1} src={garislurus} alt="" />
              </div>
              <Gap height={undefined} width={220} />
              <div className={style.wrapperCardContentSevenWeb}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={dobith} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>M. Dobith</h5>
                <h5 className={style.positionTeamStructure}>
                  Team Lead Web Developer
                </h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/bits.io">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/m-dobith-syadad-riyadi/">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
              </div>
              <Gap height={undefined} width={240} />
              <div className={style.wrapperCardContentSevenJA}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={hasbi} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Muh. Hasbi</h5>
                <h5 className={style.positionTeamStructure}>Human Resources</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/muhammdhsbi">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/muhammdhsbi">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
              </div>
            </div>
            {/* <Gap height={40} width={undefined} /> */}
            <img className={style.garis2} src={garis2} alt="" />

            <div className={style.TeamStructureFour}>
              {/* <Gap height={undefined} width={10} /> */}
              <div className={style.wrapperCardContentSevenMobile}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img
                    src={SitiNazarina}
                    alt=""
                    className={style.timNgabsign}
                  />
                </div>
                <h5 className={style.nameTeamStructure}>Siti Nazarina</h5>
                <h5 className={style.positionTeamStructure}>Android Dev</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/sitinazarina22_">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/siti-nazarina-61550a263">
                    <img
                      id={style.linkedin}
                      src={linkedin}
                      alt="LinkedIn"
                    ></img>
                  </a>
                </div>
                <img className={style.garislurus2} src={garislurus} alt="" />
              </div>
              <Gap height={undefined} width={40} />
              <div className={style.wrapperCardContentSevenGBA}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={asfa} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Fazat Asfa</h5>
                <h5 className={style.positionTeamStructure}>Bisnis Analis</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/asfaniswah">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/asfaniswah">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
                <img className={style.garislurus2} src={garislurus} alt="" />
              </div>
              <Gap height={undefined} width={40} />

              <div className={style.wrapperCardContentSevenUIUX}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={arummi} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Arummi M.</h5>
                <h5 className={style.positionTeamStructure}>UI/UX Design</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/amarummi_">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/arummi-muyasaroh-080301">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
                <img className={style.garislurus2} src={garislurus} alt="" />
              </div>
              <Gap height={undefined} width={40} />
              <div className={style.wrapperCardContentSevenWeb}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={Fachrul} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Fachrul Dwi A.</h5>
                <h5 className={style.positionTeamStructure}>Frontend</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/aprlfch">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/fachrul-dwi-aprilian-97543b230/">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
                <img className={style.garislurus2} src={garislurus} alt="" />
              </div>
              <Gap height={undefined} width={20} />
              <div className={style.wrapperCardContentSevenWeb}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={fajri} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Fajrizki Diputra</h5>
                <h5 className={style.positionTeamStructure}>Backend</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/fajri.zky">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/fajrizkydiputra">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
              </div>
              <Gap height={undefined} width={20} />

              <div className={style.wrapperCardContentSevenWeb}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={kevin} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Kevin Jeremia</h5>
                <h5 className={style.positionTeamStructure}>Fullstack</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/lilkejer">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/kevin-jeremia-b15442234/">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
                <img className={style.garislurus2} src={garislurus} alt="" />
              </div>
              {/* <Gap height={undefined} width={320} /> */}
            </div>
            <Gap height={68} width={undefined} />
            <div className={style.TeamStructureFive}>
              <Gap height={undefined} width={200} />
              <div className={style.wrapperCardContentSevenMobile}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={satria} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Satria Septa</h5>
                <h5 className={style.positionTeamStructure}>Android Dev</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/ss_arianto">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/satria-septa-arianto-567548217/">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
              </div>
              <Gap height={undefined} width={40} />
              <div className={style.wrapperCardContentSevenGBA}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={irmawati} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Irmawati</h5>
                <h5 className={style.positionTeamStructure}>Bisnis Analis</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/irmwtii__">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/irmawati-319867203/">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
              </div>
              <Gap height={undefined} width={40} />
              <div className={style.wrapperCardContentSevenUIUX}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={yohana} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Fransiska Y.</h5>
                <h5 className={style.positionTeamStructure}>UI/UX Design</h5>
                <div className={style.sosmedTeam}>
                  <img id={style.instagram} src={instagram} alt="" />
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/fransiskayohana">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
                <img className={style.garislurus3} src={garislurus} alt="" />
              </div>
              <Gap height={undefined} width={40} />
              <div className={style.wrapperCardContentSevenWeb}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={fikri} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Fikri Tafrihan</h5>
                <h5 className={style.positionTeamStructure}>Frontend</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/madboygg_">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/fikri-tafrihan-04316621b">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
              </div>
              <Gap height={undefined} width={200} />
              <div className={style.wrapperCardContentSevenWeb}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={wildan} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Wildan S.</h5>
                <h5 className={style.positionTeamStructure}>Fullstack</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/wildan.fariss">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/wildan-faris">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
              </div>
              {/* <Gap height={undefined} width={420} /> */}
            </div>
            <Gap height={68} width={undefined} />
            <div className={style.TeamStructureSix}>
              <div className={style.wrapperCardContentSevenUIUX}>
                <div className={style.cardContentSevenGrey}>
                  <div className={style.bgcard}></div>
                  <img src={nanda} alt="" className={style.timNgabsign} />
                </div>
                <h5 className={style.nameTeamStructure}>Nanda Riska</h5>
                <h5 className={style.positionTeamStructure}>UI/UX Design</h5>
                <div className={style.sosmedTeam}>
                  <a href="https://instagram.com/nandariskadewii">
                    <img id={style.instagram} src={instagram} alt="" />
                  </a>
                  <Gap height={undefined} width={5} />
                  <a href="https://www.linkedin.com/in/nanda-riska-dewi-22b2a0262">
                    <img id={style.linkedin} src={linkedin} alt="" />
                  </a>
                </div>
              </div>
              <Gap height={undefined} width={380} />
            </div>
          </div>
          <Gap height={40} width={undefined} />
        </div>
      </div>
      <Login
        title="Approve User"
        visible={showModal}
        onClose={() => toggleModal()}
        onSubmit={() => approve()}
      ></Login>
      <OptionRegister
        title="Approve User"
        visible={showModal2}
        onClose={() => toggleModal2()}
        onSubmit={() => approve()}
      ></OptionRegister>
    </div>
  );
};

export default TeamStructure;
