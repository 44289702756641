import { url } from "inspector";
import { useLocation, useNavigate } from "react-router-dom";

const NavbarPerizinan: React.FC = () => {
  const navItem = [
    {
      title: "Diajukan",
      url: "/perizinan",
    },
    {
      title: "Diterima",
      url: "/perizinan/penerimaan-izin",
    },
    {
      title: "Ditolak",
      url: "/perizinan/penolakan-izin",
    },
  ];

  const history = useNavigate();
  const location = useLocation();
  return (
    <div className="mt-16 flex flex-row w-full h-12 justify-around bg-white">
      {navItem.map((item, index) => (
        <div
          className="flex-1 flex justify-center items-center "
          onClick={() => {
            history(item.url);
          }}
          key={index}
        >
          <p
            style={
              location.pathname === item.url
                ? { color: "#004CBC", borderBottom: "2px solid #004CBC" }
                : {}
            }
            className="h-full flex justify-center items-center text-sm"
          >
            {item.title}
          </p>
        </div>
      ))}
    </div>
  );
};

export default NavbarPerizinan;
