import { AxiosResponse } from "axios";
import { checkLaporanResponse } from "../types/checklaporankegiatan";
import { DailyReport, LaporanKegiatanRequest, LaporanKegiatanResponse } from "../types/laporanKegiatan";
import httpRequestV2 from "./apiv2";

export const laporanKegiatan = (
    request: LaporanKegiatanRequest
  ): Promise<AxiosResponse<LaporanKegiatanResponse>> => {
    return httpRequestV2.post("employee/report", request);
  };

  export const getLaporanKegiatan = async (): Promise<AxiosResponse<LaporanKegiatanResponse>> => {
    return await httpRequestV2.get('employee/report')
  }

  export const checkLaporanKegiatan = async (): Promise<AxiosResponse<checkLaporanResponse>> => {
    return await httpRequestV2.get('employee/check-report')
  }

  export const updateLaporanKegiatan = async (
    request: LaporanKegiatanRequest,
    params: DailyReport
  ): Promise<AxiosResponse<LaporanKegiatanResponse>> => {
    return await httpRequestV2.put('employee/report/' + params, request)
  } 

  export const getLaporanKegiatanbyID = async (
    params: DailyReport
  ): Promise<AxiosResponse<LaporanKegiatanResponse>> => {
    return await httpRequestV2.get('employee/report/' + params)
  } 

  