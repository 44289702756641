import { useEffect, useState } from "react";
import VerifedPerizinanList from "../../../component/VerifedPerizinanList/VerifedPerizinanList";
import { ListPerizinanResponse } from "../../../types/ListPerizinanEmployee";
import { perizinanService } from "../../../services";

const PenerimaanIzin: React.FC = () => {
  const [permission, setPermission] = useState<ListPerizinanResponse>();

  const getPermission = async () => {
    try {
      const res = await perizinanService.getListPerizinanAccept();
      setPermission(res.data);
    } catch (e) {
      console.log("error : ", e);
    }
  };

  useEffect(() => {
    getPermission();
    console.log("Test : ", permission);
  }, []);

  return (
    <div>
      <VerifedPerizinanList permission={permission?.data.permission} />
    </div>
  );
};

export default PenerimaanIzin;
