import React from "react";
import {
  gpBlack,
  jadwalKerja,
  laporanKegiatanLP,
  presensidigital,
  riwayatPresensi,
} from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import style from "./PerformaKerja.module.css";

import video from "../../../assets/videos/performaKerja.mp4";

const PerformaKerja: React.FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.wrapperLeft}>
        <video src={jadwalKerja} width="244" height="528" />
      </div>
      {/* <Gap height={undefined} width={50} /> */}
      <div className={style.wrapperRight}>
        <div className={style.title}>
          Pantau Performa Kerja jadi lebih mudah
        </div>
        <Gap height={14} width={undefined} />
        <div className={style.subTitle}>
          Apakah Anda termasuk Karyawan termalas? Biasa saja atau si Paling
          Rajin? Dengan fitur performa ini Anda bisa melihat posisi dan istilah
          tepat yang menggambarkan diri Anda selama bekerja.
        </div>
        <Gap height={44} width={undefined} />
        <div>
          <button className={style.button}>Mulai Gratis</button>
        </div>
        <Gap height={40} width={undefined} />
        <div>
          <img src={gpBlack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default PerformaKerja;
