import React, { useEffect } from "react";
import Router from "./Router";
import LogRocket from "logrocket";


//comment 
const App: React.FC = () => {
  useEffect(() => {
    LogRocket.init('v8myyq/ngabsign-id');
  }, []);
  return(
  <>
    <Router/>
  </>
  )
}

export default App