import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  calendar,
  Face1Color,
  Face2Color,
  Face3Color,
  Face4Color,
  Face5Color,
  imgnotfound,
} from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import { clockInService, userService } from "../../../services";
import { clockInResponse } from "../../../types/clockIn";
import { UserResponse } from "../../../types/user";

import styles from "./KonfirmasiClockOut.module.css";
// import MiniMap from "../../../component/MapWithAMarker/MiniMap";
import UserLocation from "../ClockIn/user-location/UserLocation";
import { CheckPresensiResponse } from "../../../types/checkpresensi";
import MapWithAMarker from "../../../component/MiniMap/MiniMap";
import axios from "axios";

const KonfirmasiClockOut: React.FC = () => {
  const [profile, setProfile] = useState<UserResponse>();
  const [presensi, setPresensi] = useState<CheckPresensiResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());
  const [picture, setPicture] = useState<string | null>(null);
  const [Alamat, setAlamat] = useState<string>("");
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setlongitude] = useState<string>("");
  const capturedImage = localStorage.getItem("image");

  useEffect(() => {
    const Alamat = localStorage.getItem("alamat");
    if (Alamat) {
      setAlamat(Alamat);
    }
  }, []);

  useEffect(() => {
    const Latitude = localStorage.getItem("lat");
    if (Latitude) {
      setLatitude(Latitude);
    }
  }, []);

  useEffect(() => {
    const Longitude = localStorage.getItem("lng");
    if (Longitude) {
      setlongitude(Longitude);
    }
  }, []);

  localStorage.getItem("lat");
  localStorage.getItem("lng");

  const formatDate = (date: Date) => {
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    const pictureData = localStorage.getItem("picture");
    setPicture(pictureData);
  }, []);

  const history = useNavigate();

  useEffect(() => {
    const data = async () => {
      try {
        const user = await userService.getProfile();
        setProfile(user.data);
        console.log(user.data);
        localStorage.getItem("@token");
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          history("/");
        }
      }
    };
    data();

    const present = async () => {
      try {
        const present = await clockInService.getCheckClockIn();
        setPresensi(present.data);
        // console.log(present.data);
        console.log(present.data.data.clock_in, "isinyadetail");

        localStorage.getItem("@token");
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          localStorage.removeItem("@token");
          history("/");
          alert("Sesi anda berakhir, silahkan login ulang!");
        }
      }
    };
    present();
  }, []);

  const stateMap = {
    lat: latitude || "undefined",
    lng: longitude || "undefined",
  };

  const apiKey = "AIzaSyAn_Dndb3iqrnn3z05ML9WtyqhdGhJjJRg";

  const handlePost = () => {
    const capturedImage = localStorage.getItem("image");

    function dataURLtoFile(dataurl: any, filename: any) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    const formData = new FormData();
    const file = dataURLtoFile(capturedImage, "image.png");
    formData.append("image", file);

    const address = localStorage.getItem("address");
    if (address) {
      formData.append("address", address);
    }

    const lat = localStorage.getItem("lat");
    if (lat) {
      formData.append("latitude", lat);
    }

    const lng = localStorage.getItem("lng");
    if (lng) {
      formData.append("longitude", lng);
    }

    const smile_scale = localStorage.getItem("smile_scale");
    if (smile_scale) {
      formData.append("smile_scale", smile_scale);
    }

    const state = localStorage.getItem("state");
    if (state) {
      formData.append("state", state);
    }

    const token = localStorage.getItem("@token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(
        "https://www.apis.ngabsign.id/api/v2/employee/attendance",
        formData,
        config
      )
      .then((response) => {
        if (response.data.data.attendance.status === "late") {
          localStorage.setItem("status1", "true");
          console.log("terlambat");
        }
        if (response.data.data.attendance.state === "Clock In") {
          history("/absensi");
        }
        if (response.data.data.attendance.state === "Clock Out") {
          history("/absensi");
        }
        console.log(
          "Foto berhasil disimpan ke database",
          response.data.data.attendance.state
        );
      })
      .catch((error) => {
        console.log("Error saat menyimpan foto ke database", error);
        if (error.response.status == 403) {
          alert("Can not clock out, please wait at 17:00:00");
          // setShowModal3((prev) => !prev);
          // history("/absensi/clock-out");
          console.log("403", error);
        }
      });
  };

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        {/* <div className={styles.topWrapper}> */}
        <div className={styles.detailContainer}>
          <div className={styles.container}>
            <div className={styles.leftContainerWrapper}>
              <div className={styles.leftContainer}>
                <Gap height={30} width={undefined} />
                <div className={styles.rectangleProfileWrapper}>
                  <img
                    className={styles.rectangleProfile}
                    // src={picture ?? imgnotfound}
                    src={capturedImage ? capturedImage : imgnotfound}
                    alt=""
                  />
                </div>
                <div className={styles.wrapperInputClockIn}>
                  <h2 className={styles.pageTitle}>Clock Out</h2>
                  <Gap height={10} width={undefined} />
                  <form>
                    <div className={styles.perizinanFullname}>
                      <label
                        className={styles.labelFullname}
                        htmlFor="fullname"
                      >
                        Nama Lengkap
                      </label>
                      <Gap height={5} width={undefined} />
                      <p
                        className={styles.inputFullname}
                      >{`${profile?.data.employee.full_name}`}</p>
                    </div>

                    <Gap height={10} width={undefined} />

                    <div className={styles.perizinanDepartemen}>
                      <label
                        className={styles.labelDepartemen}
                        htmlFor="departemen"
                      >
                        Departemen
                      </label>
                      <Gap height={5} width={undefined} />
                      <p
                        className={styles.inputDepartemen}
                      >{`${profile?.data.employee.department}`}</p>
                    </div>

                    <Gap height={10} width={undefined} />

                    <div className={styles.tanggalClockIn}>
                      <label
                        className={styles.labelTanggalClockIn}
                        htmlFor="date"
                      >
                        Tanggal Clock Out
                      </label>
                      <Gap height={5} width={undefined} />

                      {/* <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  /> */}
                      {/* <DatePicker
                        className={styles.inputTanggalClockIn}
                        selected={startDate}
                        onChange={(date) => date && setStartDate(date)}
                        showDisabledMonthNavigation
                        readOnly
                      /> */}
                      <DatePicker
                        className={styles.inputTanggalClockIn}
                        selected={startDate}
                        onChange={(date) => date && setStartDate(date)}
                        showDisabledMonthNavigation
                        readOnly
                        dateFormat="dd MMMM yyyy" // Format tanggal yang diinginkan
                      />
                      {/* <p>-</p> */}
                    </div>

                    <Gap height={10} width={undefined} />

                    <div className={styles.jamMasuk}>
                      <label
                        className={styles.labelJamMasuk}
                        htmlFor="fclockin"
                      >
                        Jam Clock Out
                      </label>
                      <Gap height={5} width={undefined} />

                      <DatePicker
                        className={styles.inputJamMasuk}
                        selected={startDate}
                        onChange={(date) => date && setStartDate(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        showDisabledMonthNavigation
                        readOnly
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </div>

                    <Gap height={15} width={undefined} />
                  </form>
                </div>
              </div>
              <Gap height={40} width={undefined} />

              <div className={styles.wrapperLokasi}>
                <h3 className={styles.titleLokasi}> Lokasi Terkini </h3>
                <p className={styles.subTitleLocakasi}>{Alamat}</p>
                <MapWithAMarker state={stateMap} apiKey={apiKey} />
              </div>
              {/* <Gap height={80} width={undefined} /> */}

              <div className={styles.wrapperbtnClockIn}>
                <button
                  className={styles.btnBackClockIn}
                  // onClick={() =>
                  //   history("/absensi/clock-in/user-location/camera")
                  // }
                  onClick={() => window.history.back()}
                >
                  Kembali
                </button>
                <Gap height={undefined} width={10} />
                <button
                  className={styles.btnClockIn}
                  onClick={() => handlePost()}
                >
                  Konfirmasi
                </button>
              </div>
            </div>

            <div className={styles.rightContainer}>
              {/* <div>
                <Gap height={30} width={undefined} />

                <h3 className={styles.rightTitle}>Riwayat Absensi</h3>
                <Gap height={10} width={undefined} />
                <img
                  className={styles.calendar}
                  src={calendar}
                  alt="calendar"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* </div>  */}
    </DashboardLayout>
  );
};

export default KonfirmasiClockOut;
