import React, { ChangeEvent, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import "./RegisterKaryawan.css";
import { logofix, bgfix } from "../../../assets/index";
import Gap from "../../../component/atoms/Gap";
import { authService } from "../../../services";
import { RegisterRequest } from "../../../types/Register";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Login from "../Login/Login";

const RegisterKaryawan: React.FC = () => {
  const history = useNavigate();
  const [sending, setSending] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [rePasswordShow, setRePasswordShow] = useState(false);
  const [repeatedPassword, setRepeatedPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [kode_company, setCode_Company] = useState<string>("");
  const [listDepartement, setListDepartement] = useState<any>();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [agree, setAgree] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const approve = () => {
    console.log("approved");
  };

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };
  const btnStateFn = () => {
    if (agree) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const state = btnStateFn();
    setBtnDisable(state);
  }, [agree]);

  const togglePassword = () => {
    setPasswordShow(!passwordShow);
  };
  const togglePassword2 = () => {
    setRePasswordShow(!rePasswordShow);
  };

  let isPasswordMatch: undefined | boolean = undefined;
  if (password !== repeatedPassword) {
    isPasswordMatch = false;
  } else {
    isPasswordMatch = true;
  }
  useEffect(() => {
    const reload_code = async () => {
      try {
        const check_code = await authService.company_code({
          company_code: kode_company,
        });
        setListDepartement(check_code.data.data.list_department);
      } catch (error: any) {
        setListDepartement([]);
      }
    };

    if (kode_company.length >= 6) {
      reload_code();
    } else {
      setListDepartement([]);
    }
  }, [kode_company]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const onSubmit = async (form: any) => {
  //   const formData: any = new FormData();
  //   formData.append("employee_full_name", form.employee_full_name);
  //   formData.append("employee_email", form.employee_email);
  //   formData.append("password", form.password);
  //   formData.append("phone_number", form.phone_number);
  //   formData.append("company_code", kode_company);
  //   formData.append("department", form.department);

  //   console.log(formData);
  //   setLoading(true);
  //   try {
  //     const register = await authService.register(formData);
  //     history("/");
  //   } catch (error: any) {
  //     if (error.response.status == 400) {
  //       console.log("kesalahan");
  //       console.log(error);
  //     }
  //   }

  //   setLoading(false);
  // };

  const onSubmit = async (form: any) => {
    setLoading(true);

    const requestData = {
      employee_full_name: form.employee_full_name,
      employee_email: form.employee_email,
      password: form.password,
      phone_number: form.phone_number,
      company_code: kode_company,
      department: form.department,
    };

    console.log(requestData);

    try {
      const response = await authService.register(requestData);
      history("/");
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        console.log("kesalahan");
        console.log(error);
      } else if (error.response && error.response.status === 403) {
        alert("Karyawan perusahaan sudah mencapai limit!");
      } else {
        if (error.response.data.meta.message) {
          alert(error.response.data.meta.message);
        } else {
          alert("Terjadi masalah!");
        }
      }
    }

    setLoading(false);
  };

  return (
    <div className="reg-karyawan-wrapper">
      <div className="reg-karyawan-box">
        <div className="reg-karyawan-wrapper-left">
          <img src={bgfix} alt="" />
          <div className="reg-desc">
            <h2>Kelola absensi dan kegiatan lebih mudah dengan Ngabsign</h2>
            <p>The best choice for your company</p>
          </div>
        </div>
        <div className="reg-karyawan-wrapper-right">
          <div className="form-reg-karyawan-wrapper">
            <img src={logofix} alt="logo ngabsign" />
            <Gap height={20} width={undefined} />
            <h1>Daftar Sebagai Karyawan</h1>
            <Gap height={5} width={undefined} />
            <p className="reg-karyawan-desc">
              Lengkapi form di bawah dengan menggunakan data yang Valid{" "}
            </p>
            <Gap height={20} width={undefined} />

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="input-fullname-karyawan">
                <input
                  required
                  disabled={sending}
                  id="fullname"
                  type="text"
                  placeholder="Masukkan Nama Lengkap"
                  {...register("employee_full_name", { required: true })}
                />
                {errors.employee_full_name && (
                  <span className="message-error">
                    Wajib Mengisi Nama Lengkap
                  </span>
                )}
                <i className="fa-solid fa-user"></i>
              </div>
              <Gap height={20} width={undefined} />
              <div className="input-email-karyawan">
                <input
                  required
                  disabled={sending}
                  id="email"
                  type="text"
                  placeholder="Masukkan Email Anda"
                  {...register("employee_email", {
                    required: true,
                    pattern:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                {errors.employee_email && (
                  <span className="message-error">
                    Email yang anda masukkan tidak valid
                  </span>
                )}
                <i className="fa-solid fa-envelope"></i>
              </div>
              <Gap height={20} width={undefined} />
              <div className="input-telepon-karyawan">
                <input
                  required
                  disabled={sending}
                  id="text"
                  type="text"
                  placeholder="Masukkan Nomor Telepon Anda"
                  {...register("phone_number", { required: true })}
                />
                {errors.phone_number && (
                  <span className="message-error">
                    Nomor Telepon yang anda masukkan tidak valid
                  </span>
                )}
                <i className="fa-sharp fa-solid fa-phone"></i>
              </div>
              <Gap height={20} width={undefined} />
              <div className="input-password-karyawan">
                <input
                  required
                  disabled={sending}
                  type={passwordShow ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  {...register("password", { required: true })}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i className="fa-sharp fa-solid fa-lock"></i>
                <i
                  className={
                    passwordShow ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                  }
                  onClick={togglePassword}
                  id="mata"
                ></i>
              </div>
              <Gap height={20} width={undefined} />
              <div className="input-konfirmasi-password-karyawan">
                <input
                  required
                  disabled={sending}
                  onChange={(e) => setRepeatedPassword(e.target.value)}
                  type={rePasswordShow ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                />
                <i className="fa-sharp fa-solid fa-lock"></i>
                <i
                  className={
                    rePasswordShow ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                  }
                  onClick={togglePassword2}
                  id="mata"
                ></i>
                <div className="match-password">
                  {isPasswordMatch === false ? (
                    <span>*Pastikan password anda sama</span>
                  ) : null}
                </div>
              </div>
              <Gap height={20} width={undefined} />
              <div className="input-kode-perusahaan">
                <input
                  required
                  onChange={(e) => setCode_Company(e.target.value)}
                  maxLength={6}
                  value={kode_company}
                  disabled={sending}
                  id="string"
                  type="text"
                  placeholder="Masukkan Kode Perusahaan Anda"
                />
                {errors.kode_company && (
                  <span className="message-error">
                    Kode Perusahaan yang anda masukkan tidak valid
                  </span>
                )}
                <i className="fa-solid fa-building"></i>
              </div>

              <Gap height={20} width={undefined} />

              <div className="inputDepartemen">
                {/* <label className={styles.labelJenisPerizinan} htmlFor="fullname">Jenis Perizinan</label>
            <Gap height={5} width={undefined} />
            <input className={styles.inputJenisPerizinan} type="text" placeholder="Pilih Jenis Perizinan" /> */}
                <select
                  required
                  className="selectInputDepartemen"
                  placeholder="Pilih Departemen Anda"
                  {...register("department", { required: true })}
                >
                  {listDepartement?.map((item: any) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <Gap height={20} width={undefined} />
              <div className="register-checkbox">
                <input
                  type="checkbox"
                  onChange={() => setAgree(!agree)}
                  autoComplete="off"
                />
                <p>
                  I agree to the
                  <span>Terms of Service and Privacy Policy</span>
                </p>
              </div>

              <Gap height={20} width={undefined} />
              <div className="reg-lupa-password">
                <p>Lupa Password?</p>
              </div>
              <Gap height={25} width={undefined} />
              <button
                className={`button-reg-karyawans ${btnDisable ? "" : "button-reg-karyawan-active"
                  }`}
                disabled={btnDisable}
                type="submit"
              >
                Daftar
              </button>

              <Gap height={20} width={undefined} />
              <p>
                {" "}
                Sudah punya akun{" "}
                <span
                  className="masuk"
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  {" "}
                  Masuk{" "}
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
      <Login
        title="Approve User"
        visible={showModal}
        onClose={() => toggleModal()}
        onSubmit={() => approve()}
      ></Login>
    </div>
  );
};

export default RegisterKaryawan;
