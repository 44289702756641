import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { perizinanService } from "../../services";
import { AdminDetailPerizinanResponse } from "../../types/detail-perizinan-admin";
import Gap from "../atoms/Gap";
import styles from "./DetailPerizinan.module.css";

interface ModalProps {
  title: string;
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children?: React.ReactNode;
  closeText?: string;
  submitText?: string;
  // closeBtnProps?: Omit<ButtonProps, "children">;
  // submitBtnProps?: Omit<ButtonProps, "children">;
}

type idParams = {
  id: string;
};

const DetailPerizinan: React.FC<ModalProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [detailAdminPerizinan, setDetailAdminPerizinan] =
    useState<AdminDetailPerizinanResponse>();
  const history = useNavigate();
  const { id } = useParams<idParams>();

  useEffect(() => {
    const adminPerizinan = async () => {
      try {
        const adminPerizinan = await perizinanService.getDetailPerizinanAdmin();
        setDetailAdminPerizinan(adminPerizinan.data);
        localStorage.getItem("@token");
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          history("/");
        }
      }
    };
    // adminPerizinan(id);
  }, []);

  console.log("adasdasd", setDetailAdminPerizinan);
  const { children, onClose, visible } = props;

  if (!visible) return <></>;

  return (
    <div className={styles.wrapper}>
      <div className={styles.formWrapper}>
        <div className={styles.topWrapper}>
          <span onClick={onClose}>X</span>
        </div>
        <div className={styles.topFormWrapper}>
          <h3>Verifikasi Pengajuan Perizinan</h3>
          <div className={styles.topFormProfileWrapper}>
            <img src="" alt="" />
            <div className={styles.topcontentProfileWrapper}>
              <span className={styles.name}>
                {" "}
                {detailAdminPerizinan?.data.permission.employee.full_name}
              </span>
              <span className={styles.department}> Frontend </span>
            </div>
          </div>
        </div>
        <div className={styles.contentFormWrapper}>
          <div className={styles.contentPerizinan}>
            <label htmlFor="">Tanggal Izin </label>
            <span>1 November 2022 - 15 Desember 2022</span>
          </div>
          <div className={styles.contentPerizinan}>
            <label htmlFor="">Jenis Perizinan</label>
            <span>Cuti</span>
          </div>
          <div className={styles.contentPerizinan}>
            <label htmlFor="">Keterangan</label>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </span>
          </div>
          <div className={styles.contentPerizinan}>
            <label htmlFor="">Lampiran</label>
            {/* <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</span> */}
          </div>
        </div>
        <div className={styles.bottomFormWrapper}>
          <button className={styles.buttonAccept}>Terima</button>
          <Gap height={undefined} width={5} />
          <button className={styles.buttonDecline}> Tolak</button>
        </div>
      </div>
    </div>
  );
};

export default DetailPerizinan;
