import React, { FormEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { TransaksiRequest, TransaksiResponse } from "../../../types/transaksi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Alfamart,
  BCA,
  BNI,
  BRI,
  Gopay,
  Indomart,
  logofix,
  Mandiri,
  Permata,
  Qris,
  Shopee,
} from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import { authService, TransaksiService } from "../../../services";
import { BsFillCaretUpFill } from "react-icons/bs";
import styles from "./DetailPembayaran.module.css";
import { SubscriptionResponse } from "../../../types/subs-type";
import { RegisterAdminResponse } from "../../../types/registeradmin";
import { transaksi } from "../../../services/transaksi";

type idParams = {
  order_id: string;
};

const DetailPembayaran: React.FC = () => {
  const formatRupiah = (value: any) => {
    return value
      .toString()
      .split("")
      .reverse()
      .join("")
      .match(/\d{1,3}/g)
      .join(".")
      .split("")
      .reverse()
      .join("");
  };

  const { order_id } = useParams<idParams>();
  const [getDataTransaksi, setGetDataTransaksi] = useState<TransaksiResponse>();
  const [show, setShow] = useState<boolean>(false);
  const [show2, setShow2] = useState<boolean>(false);
  const [show3, setShow3] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<string>("");
  const history = useNavigate();
  const [getSubscriptionType, setGetSubscriptionType] =
    useState<SubscriptionResponse>();
  const [getDetailPembayaran, setGetDetailPembayaran] =
    useState<RegisterAdminResponse>();
  const [queryParams, setQueryParams] = useSearchParams();

  console.log(queryParams.get("ae"));
  console.log(queryParams.get("pq"));
  console.log(queryParams.get("sid"));
  console.log(queryParams.get("ce"));

  const [data, setData] = useState<TransaksiRequest>({
    payment_type: "",
    admin_full_name: "Gmma",
    admin_email: `${queryParams.get("ae") ? queryParams.get("ae") : ""}`,
    admin_phone: "67964",
    company_name: "dghg",
    company_email: `${queryParams.get("ce") ? queryParams.get("ce") : ""}`,
    personnel_quota: queryParams.get("pq")
      ? parseInt(`${queryParams.get("pq")}`)
      : 0,
    subscription_id: queryParams.get("sid")
      ? parseInt(`${queryParams.get("sid")}`)
      : 0,
  });

  useEffect(() => {
    const getSubscriptionType = async () => {
      try {
        const getSubscriptionType =
          await TransaksiService.getSubscriptionType();
        setGetSubscriptionType(getSubscriptionType.data);
        console.log("sadasd", getSubscriptionType);
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          history("/");
        }
      }
    };
  }, []);

  useEffect(() => {
    const getDataTransaksi = async () => {
      try {
        const getDataTransaksi = await TransaksiService.getTransaksi2();
        setGetDataTransaksi(getDataTransaksi.data);
        console.log("sadasd", getDataTransaksi);
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          history("/");
        }
      }
    };
  }, []);

  useEffect(() => {
    const getSubscriptionType = async () => {
      try {
        const getDetailPembayaran = await authService.getDetailPembayaran();
        setGetDetailPembayaran(getDetailPembayaran.data);
        console.log("sadasd", getSubscriptionType);
        localStorage.setItem("@token", getDetailPembayaran.data.token);
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          history("/");
        }
      }
    };
  }, []);

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();

  const onSubmit = async () => {
    //   console.log(form)
    setLoading(true);
    try {
      console.log("jalan");
      const transaksi = await TransaksiService.transaksi(data);
      // alert("error");

      // console.log("1236561", transaksi.data.data.transaction.order_id);
      // history(
      //   `/auth/register-perusahaan/detail-pembayaran/${data.payment_type}?oid=${transaksi.data.data.transaction.order_id}`
      // );
      history(
        `/auth/register-perusahaan/detail-pembayaran/${data.payment_type}?id=${transaksi.data.transaction.order_id}`
      );
      // alert("berhasil");
    } catch (error: any) {
      if (error.response.status == 400) {
        console.log("kesalahan", error);
        alert("salah");
      } else if (error.response.status == 401) {
        history("/");
      } else {
        if (error.response.data.meta.message) {
          alert(error.response.data.meta.message);
        } else {
          alert("Terjadi masalah!");
        }
      }
    }
    setLoading(false);
  };

  const onChangePayment = (type: string) => {
    setData((prev) => ({
      ...prev,
      payment_type: type,
    }));
  };

  const onClick = async (
    e: FormEvent<HTMLFormElement>,
    data: TransaksiRequest
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      const check_payment_type = await TransaksiService.transaksi(data);
      console.log(check_payment_type);
    } catch (error: any) {
      if (error.response.status == 400) {
        console.log("kesalahan");
        console.log(error);
      } else {
        if (error.response.data.meta.message) {
          alert(error.response.data.meta.message);
        } else {
          alert("Terjadi masalah!");
        }
      }
    }
    setLoading(false);
  };
  console.log(onChangePayment);
  return (
    <div className={styles.wrapper}>
      <div className={styles.leftContainer}>
        <img className={styles.logofix} src={logofix} alt="" />
        <Gap height={20} width={undefined} />
        <h2>Tinjau Detail Pembayaran Anda</h2>
        <Gap height={20} width={undefined} />
        <div className={styles.detailPayment}>
          <div className={styles.eclipesleft}></div>
          <div className={styles.eclipesright}></div>
          <Gap height={20} width={undefined} />
          <div className={styles.formTopDetailPayment}>
            <h3>Silver</h3>
            <h2></h2>
          </div>
          <div className={styles.topDetailPayment}></div>
          <div className={styles.bottomDetailPayment}>
            <h4>Benefit :</h4>
            <li>Maksimal 50 karyawan</li>
            <li>Periode 1 bulan</li>
            <li>
              Bebas Akses fitur absensi, manajemen jadwal, reminder, laporan
              kegiatan, dan riwayat kehadiran
            </li>
          </div>
        </div>
        <Gap height={20} width={undefined} />
        <form onSubmit={(e) => onClick(e, data)}>
          <div className={styles.paymentMethod}>
            <div className={styles.titlePayment}>
              <h3>Pilih Metode Pembayaran:</h3>
            </div>
            <Gap height={20} width={undefined} />
            <div
              className={styles.dropdownPayment}
              onClick={() => {
                setShow((prev) => !prev);
              }}
            >
              <p className={styles.dropdownNamePayment}>
                {" "}
                Transfer Bank (Virtual Akun){" "}
              </p>
              {/* <i className="fa-solid fa-caret-up" id='iconDropdown'></i> */}
              <BsFillCaretUpFill className={styles.iconDropdown} />
            </div>
            <Gap height={20} width={undefined} />
            {show ? (
              <div className={styles.list_child}>
                <div
                  className={styles.payment}
                  onClick={() => onChangePayment("mandiri_va")}
                >
                  <img className={styles.imgPayment} src={Mandiri} alt="" />
                  <Gap height={undefined} width={10} />
                  <p className={styles.namePayment}> Mandiri </p>
                </div>
                <Gap height={20} width={undefined} />
                <div
                  className={styles.payment}
                  onClick={() => onChangePayment("bni_va")}
                >
                  <img className={styles.imgPayment} src={BNI} alt="" />
                  <Gap height={undefined} width={10} />
                  <p className={styles.namePayment}> BNI </p>
                </div>
                <Gap height={20} width={undefined} />
                <div
                  className={styles.payment}
                  onClick={() => onChangePayment("bri_va")}
                >
                  <img className={styles.imgPayment} src={BRI} alt="" />
                  <Gap height={undefined} width={10} />
                  <p className={styles.namePayment}> BRI </p>
                </div>
                <Gap height={20} width={undefined} />
                <div
                  className={styles.payment}
                  onClick={() => onChangePayment("permata_va")}
                >
                  <img className={styles.imgPayment} src={Permata} alt="" />
                  <Gap height={undefined} width={10} />
                  <p className={styles.namePayment}> Permata </p>
                </div>
                <Gap height={20} width={undefined} />
                <div
                  className={styles.payment}
                  onClick={() => onChangePayment("bca_va")}
                >
                  <img className={styles.imgPayment} src={BCA} alt="" />
                  <Gap height={undefined} width={10} />
                  <p className={styles.namePayment}> BCA </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            <Gap height={20} width={undefined} />
            <div
              className={styles.dropdownPayment}
              onClick={() => {
                setShow2((prev) => !prev);
              }}
            >
              <p className={styles.dropdownNamePayment}> E-Wallet </p>
              {/* <i className="fa-solid fa-caret-up" id='iconDropdown'></i> */}
              <BsFillCaretUpFill className={styles.iconDropdownWallet} />
            </div>
            <Gap height={20} width={undefined} />
            {show2 ? (
              <div className={styles.list_child}>
                <div
                  className={styles.payment}
                  onClick={() => onChangePayment("gopay_online")}
                >
                  <img className={styles.imgPayment} src={Gopay} alt="" />
                  <Gap height={undefined} width={10} />
                  <p className={styles.namePayment}> GOPAY </p>
                </div>
                <Gap height={20} width={undefined} />
                <div
                  className={styles.payment}
                  // onClick={() => onChangePayment("mandiri_va")}
                >
                  <img className={styles.imgPayment} src={Qris} alt="" />
                  <Gap height={undefined} width={10} />
                  <p className={styles.namePayment}> QRIS </p>
                </div>
                <Gap height={20} width={undefined} />
                <div
                  className={styles.payment}
                  // onClick={() => onChangePayment("shopeepay")}
                >
                  <img className={styles.imgPayment} src={Shopee} alt="" />
                  <Gap height={undefined} width={10} />
                  <p className={styles.namePayment}> Shopee </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            <Gap height={20} width={undefined} />
            <div
              className={styles.dropdownPayment}
              onClick={() => {
                setShow3((prev) => !prev);
              }}
            >
              <p className={styles.dropdownNamePayment}>
                {" "}
                Indomaret & Alfa Grup{" "}
              </p>
              {/* <i className="fa-solid fa-caret-up" id='iconDropdown'></i> */}
              <BsFillCaretUpFill className={styles.iconDropdownAlfa} />
            </div>

            <Gap height={20} width={undefined} />
            {show3 ? (
              <div className={styles.list_child}>
                <div
                  className={styles.payment}
                  // onClick={() => onChangePayment("alfamart")}
                >
                  <img className={styles.imgPayment} src={Alfamart} alt="" />
                  <Gap height={undefined} width={10} />
                  <p className={styles.namePayment}> Alfamart </p>
                </div>
                <Gap height={20} width={undefined} />
                <div
                  className={styles.payment}
                  // onClick={() => onChangePayment("indomaret")}
                >
                  <img className={styles.imgPayment} src={Indomart} alt="" />
                  <Gap height={undefined} width={10} />
                  <p className={styles.namePayment}> Indomaret </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            <Gap height={50} width={undefined} />
          </div>
        </form>
      </div>

      <div className={styles.rightContainer}>
        <div className={styles.DetailPayment}>
          <div className={styles.formPayment}>
            <div className={styles.formPaymentSatu}>
              <div className={styles.mainDetailTitle}>
                <h3 className={styles.title}>Detail Pembayaran :</h3>
              </div>
              <Gap height={20} width={undefined} />
              <div className={styles.mainDetailData}>
                <div className={styles.mainLeftData}>
                  <p className={styles.titleDetail}>Harga Paket</p>
                  <p className={styles.titleDetail}>Jumlah Karyawan</p>
                  {/* <p className={styles.titleDetail}>Langganan Tahunan</p> */}
                </div>
                <div className={styles.mainRightData}>
                  <p className={styles.textDetail}>
                    {getDataTransaksi?.transaction.subscription_item.price}{" "}
                    /karyawan{" "}
                  </p>
                  <p className={styles.textDetail}>
                    {" "}
                    {data.personnel_quota} Karyawan
                  </p>
                  {/* <p className={styles.textDetail}> Disc 10%/bln</p> */}
                </div>
              </div>
            </div>
            <div className={styles.formPaymentDua}>
              <div className={styles.mainDetailTitle}>
                <h3 className={styles.titleDua}>Sub Total</h3>
              </div>
              <Gap height={20} width={undefined} />
              <div className={styles.mainDetailDataDua}>
                <div className={styles.mainLeftData}>
                  {/* <p className={styles.titleDetail}>Total Harga</p>
                            <p className={styles.titleDetail}>Diskon 10%</p> */}
                  <p className={styles.titleDetail}>Biaya Admin</p>
                </div>
                <div className={styles.mainRightData}>
                  {/* <p className={styles.textDetail}> Rp. 6.000.000/thn </p>
                            <p className={styles.textDetail}> - Rp. 600.000 </p> */}
                  <p className={styles.textDetail}> </p>
                </div>
              </div>
              <div className={styles.mainDetailDataTiga}>
                <div className={styles.mainLeftData}>
                  <p className={styles.titleDetail}>Total </p>
                </div>
                <div className={styles.mainRightData}>
                  <p className={styles.textDetail}> {} </p>
                </div>
              </div>
            </div>
            <Gap height={20} width={undefined} />
            <div>
              <button
                type="submit"
                className={styles.btnPayment}
                onClick={() => onSubmit()}
              >
                Bayar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPembayaran;
