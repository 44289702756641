import React, { useState, useRef } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  achsani,
  kevin,
  satria,
  yohana,
  arla,
  arummi,
  bgSectionOne,
  dilo,
  dobith,
  Fachrul,
  fadil,
  fajri,
  fikri,
  GooglePlay,
  hp,
  kampusmerdeka,
  KaryawanImage,
  laptop,
  LogoContentTen,
  logofix,
  LogoLP,
  Lokasi,
  mka,
  PerusahaanImage,
  petik,
  Phone,
  sephia,
  nanda,
  wildan,
  asfa,
  irmawati,
  Surat,
  imageGroup,
  SitiNazarina,
  hasbi,
} from "../../assets";
import Gap from "../../component/atoms/Gap";
import Login from "../Auth/Login/Login";
import OptionRegister from "../Auth/option-register/OptionRegister";
import style from "./LandingPage.module.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { IoMdArrowForward } from "react-icons/io";

const LandingPage: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModal2, setShowModal2] = useState<boolean>(false);
  const [showFaq1, setShowFaq1] = useState<boolean>(false);
  const [showFaq2, setShowFaq2] = useState<boolean>(false);
  const [showFaq3, setShowFaq3] = useState<boolean>(false);
  const [showFaq4, setShowFaq4] = useState<boolean>(false);
  const [showNavbar, setShowNavbar] = useState<boolean>(false);
  const [count, setCount] = useState(1);
  const [nav, setNav] = useState(false);

  const navRef = useRef<HTMLDivElement>(null);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };
  // // useEffect(() => {
  // const showToggle = () => {
  //   const showNavbar = navRef.current;
  //   showNavbar?.classList.toggle("responsive_nav");
  // };
  // // });

  // // const showNavbar = () => {
  // //   navRef.current;
  // //   showNavbar?.classList.toggle("responsive_nav");
  // // };

  const history = useNavigate();

  const approve = () => {
    console.log("approved");
  };

  const toggleNav = () => {
    setNav(!nav);
  };

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const toggleModal2 = () => {
    setShowModal2((prevState) => !prevState);
  };

  const toggleFaq1 = (state: boolean) => {
    setShowFaq1(!state);
  };
  const toggleFaq2 = (state: boolean) => {
    setShowFaq2(!state);
  };
  const toggleFaq3 = (state: boolean) => {
    setShowFaq3(!state);
  };
  const toggleFaq4 = (state: boolean) => {
    setShowFaq4(!state);
  };

  // const showFaq1Active = `${showFaq1 ? "faqActive" : "faqPassive"}`;
  // const showFaq2Active = `${showFaq2 ? "faqActive" : "faqPassive"}`;
  // const showFaq3Active = `${showFaq3 ? "faqActive" : "faqPassive"}`;
  // const showFaq4Active = `${showFaq4 ? "faqActive" : "faqPassive"}`;

  // interface Select {
  //   readonly wrapperElement: HTMLDivElement;
  // }

  // const select: Select = {
  //   wrapperElement: document.querySelectort("div"),
  //   inputElement: document.createElement("input"),
  // };

  // const text = document.querySelector("titleBergerak") as HTMLDivElement | null;

  // useEffect(() => {
  //   let timeout;
  //   const textLoad = () => {
  //     timeout = setTimeout(() => {
  //       text?.textContent == "Kehadiran";
  //     }, 0);
  //     setTimeout(() => {
  //       text?.textContent != "Jadwal";
  //     }, 4000);
  //   };
  //   textLoad();
  // });

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div className={style.wrapper}>
      <div className={style.navbar}>
        <div className={style.leftNavbar}>
          <img src={logofix} alt="" className={style.logo} />
        </div>

        <div className={`${style.centerNavbar} ${isNavOpen && style.navOpen}`}>
          <li className={style.contentLI}>Halaman Utama</li>
          <li className={style.contentLI}>Tentang</li>
          <li className={style.contentLI}>Servis</li>
          <li className={style.contentLI}>Kontak</li>
          <Link className={style.LinkLI} to={"/pricing"}>
            <li className={style.contentLI}>Harga</li>
          </Link>
        </div>

        <div className={style.rightNavbar}>
          <button
            className={style.bttnLoginNavbar}
            onClick={() => {
              toggleModal();
            }}
          >
            Masuk
          </button>
          <button
            className={style.bttnSignupNavbar}
            onClick={() => {
              toggleModal2();
            }}
          >
            Daftar
          </button>

          <button
            className={`${style.navToggle} ${isNavOpen && style.navOpen}`}
            onClick={handleNavToggle}
          >
            {isNavOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
      <div className={style.contentOne}>
        <div className={style.leftContentOne}>
          <div className={style.leftTitleContentOne}>
            <span className={style.titleContentOne}>Kelola</span>
            <span className={style.titleBergerak}> Presensi</span>
            {/* <span className={style.titleBergerak}>Kegiatan</span> */}
            {/* <span className={style.titleBergerak}> Perizinan</span> */}
            {/* <ul className={style.ulContentOne}>
              <li className={style.listContentOne}>
                <span className={style.spanContentOne}>Kehadiran</span>
              </li>
              <li className={style.listContentOne}>
                <span className={style.spanContentOne}>Laporan Kegiatan</span>
              </li>
              <li className={style.listContentOne}>
                <span className={style.spanContentOne}> Perizinan </span>
              </li>
              <li className={style.listContentOne}>
                <span className={style.spanContentOne}> Performa Kerja </span>
              </li>
              <li className={style.listContentOne}>
                <span className={style.spanContentOne}> Gaji Karyawan </span>
              </li>
            </ul> */}
          </div>
          <h3 className={style.leftSubTitleContentOne}>
            Lebih Mudah dan Terjadwal dengan Ngabsign
          </h3>
          <button
            className={style.buttonContentOne}
            // className={style.bttnLoginNavbar}
            // onClick={() => history("/auth/login")}
            onClick={() => {
              toggleModal();
            }}
          >
            Mulai Gratis
          </button>
        </div>
        <div className={style.wrapperKolaborasi}>
          <span className={style.textKolaborasi}>Kolaborasi Bersama : </span>
          <div className={style.kolaborasi}>
            <img src={mka} alt="" id={style.mkalogo} />
            <Gap height={undefined} width={10} />
            <img src={kampusmerdeka} alt="" id={style.kmlogo} />
            <Gap height={undefined} width={30} />
            <img src={dilo} alt="" id={style.dilologo} />
          </div>
        </div>
        <div className={style.rightContentOneIMG}>
          {/* <img className={style.bgpage1} src={bgpage1} alt="" />
          <img className={style.bgpage2} src={bgpage2} alt="" />
          <img className={style.bgpage3} src={bgpage3} alt="" />
          <img className={style.bgpage6} src={bgpage6} alt="" />
          <img className={style.bgpage5} src={bgpage5} alt="" />
          <img className={style.bgpage4} src={bgpage4} alt="" /> */}
          <img src={bgSectionOne} alt="" />
        </div>

        <div className={style.blush}></div>
      </div>
      <div className={style.contentTwo}>
        <div className={style.leftContentTwo}>
          {/* <img className={style.laptop} src={laptop} alt="" />
          <img className={style.imgHP} src={hp} alt="" /> */}
          <img className={style.imageGroup} src={imageGroup} alt="" />
        </div>
        <div className={style.rightContentTwo}>
          <h3 className={style.rightContentTwoH3}>
            Kemudahan Presensi diberbagai Perangkat
          </h3>
          <p className={style.rightContentTwoP}>
            Ngabsign hadir merubah presensi manual menjadi lebih mudah hanya
            dengan face recognition melalui smartphone ataupun presensi online
            melalui website, dilengkapi dengan fitur-fitur tambahan yang bisa
            membantu perusahaan Anda dalam manajemen pekerjaan.
          </p>
        </div>
      </div>
      <div className={style.contentThree}>
        {/* <Gap height={63} width={undefined} /> */}
        <div className={style.titleContentThree}>
          <h3 className={style.titleCT3}>Fitur Ngabsign</h3>
          <h3 className={style.subTitleCT3}>
            Manajeman Kinerja Karyawan Dalam Satu Aplikasi
          </h3>
        </div>
        <Gap height={45} width={undefined} />
        <div className={style.contentPage3}>
          <Link className={style.link} to="/presensi-digital">
            <li className={style.licontentPage3}>Presensi Digital</li>
          </Link>
          <Link className={style.link} to="/riwayat-presensi">
            <li className={style.licontentPage3}>Riwayat Presensi</li>
          </Link>
          <Link className={style.link} to="/laporan-kegiatan">
            <li className={style.licontentPage3}>Laporan Kegiatan</li>
          </Link>
          <Link className={style.link} to="/performa-kerja">
            <li className={style.licontentPage3}>Performa Kerja</li>
          </Link>
          <Link className={style.link} to="/jadwal-kerja">
            <li className={style.licontentPage3}>Atur Jadwal Kerja</li>
          </Link>
          <Link className={style.link} to="/perizinanLP">
            <li className={style.licontentPage3}>Perizinan</li>
          </Link>
          {/* <li className={style.licontentPage3}>Perizinan</li> */}
          {/* <li className={style.licontentPage3}>Gaji</li> */}
        </div>

        <div className={style.outlet}>
          <Outlet />
        </div>
        <div className={style.blushTwo}></div>
      </div>
      <div className={style.contentFive}>
        <div className={style.contentFour}>
          <p className={style.contentPage4}>
            Ngabsign merupakan Pilihan yang tepat untuk :
          </p>
          <Gap height={undefined} width={80} />
          <div className={style.ImageFour}>
            <div className={style.wrapperImagePerusahaan}>
              <img
                className={style.ImagePerusahaan}
                src={PerusahaanImage}
                alt=""
              />
              <span className={style.textContent4}>Perusahaan</span>
            </div>
            <div className={style.wrapperImageKaryawan}>
              <img className={style.ImageKaryawan} src={KaryawanImage} alt="" />
              <span className={style.textContent4}>Karyawan</span>
            </div>
          </div>
        </div>
        {/* <img className={style.imgLP} src={bgContent4} alt="" /> */}
        <p className={style.contentPage5}>
          Kemudahan dan kepraktisan dalam menggunakan sistem presensi secara
          digital ini akan membuat para karyawan mampu menggunakan jam kerjanya
          secara maksimal demi meningkatkan produktivitasnya.
        </p>
      </div>
      <div className={style.contentSix}>
        <div className={style.formContentSix}>
          <div className={style.titleContentSix}>TESTIMONI</div>
          <Gap height={32} width={undefined} />
          <div className={style.subTitleContentSix}>
            {" "}
            Kata mereka para pengguna Ngabsign
          </div>
          <Gap height={32} width={undefined} />
          {count === 1 ? (
            <div className={style.carousel}>
              <i
                className="fa-solid fa-chevron-left"
                id={style.arrowLeft}
                // onClick={() => setCount(count - 4)}
              ></i>
              <i
                className="fa-solid fa-chevron-right"
                id={style.arrowRight}
                onClick={() => setCount((count) => count + 1)}
              ></i>
              <img className={style.petikCarousel} src={petik} alt="" />
              <div className={style.contentCarousel}>
                <span className={style.contentTestiCarousel}>
                  Aplikasi yang sangat bagus, kini kita bisa mengetahui
                  aktivitas yang dilakukan karyawan hanya dalam sebuah aplikasi
                </span>
                <Gap height={16} width={undefined} />
                <div>
                  <span className={style.contentNameCarousel}>Sabil</span>
                </div>
              </div>
            </div>
          ) : null}
          {count === 2 ? (
            <div className={style.carousel}>
              <i
                className="fa-solid fa-chevron-left"
                id={style.arrowLeft}
                onClick={() => setCount(count - 1)}
              ></i>
              <i
                className="fa-solid fa-chevron-right"
                id={style.arrowRight}
                onClick={() => setCount((count) => count + 1)}
              ></i>
              <img className={style.petikCarousel} src={petik} alt="" />
              <div className={style.contentCarousel}>
                <span className={style.contentTestiCarousel}>
                  Selama menggunakan aplikasi ini sangat nyaman user
                  interfacenya dan mudah dimengerti penggunaan fitur-fiturnya
                </span>
                <Gap height={16} width={undefined} />
                <div>
                  <span className={style.contentNameCarousel}>
                    Isnaini Saraswati
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          {count === 3 ? (
            <div className={style.carousel}>
              <i
                className="fa-solid fa-chevron-left"
                id={style.arrowLeft}
                onClick={() => setCount(count - 1)}
              ></i>
              <i
                className="fa-solid fa-chevron-right"
                id={style.arrowRight}
                onClick={() => setCount((count) => count + 1)}
              ></i>
              <img className={style.petikCarousel} src={petik} alt="" />
              <div className={style.contentCarousel}>
                <span className={style.contentTestiCarousel}>
                  Terimakasih telah memberikan aplikasi yang simple seperti
                  ngabsign
                </span>
                <Gap height={16} width={undefined} />
                <div>
                  <span className={style.contentNameCarousel}>
                    Ayub agustin
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          {count === 4 ? (
            <div className={style.carousel}>
              <i
                className="fa-solid fa-chevron-left"
                id={style.arrowLeft}
                onClick={() => setCount(count - 1)}
              ></i>
              <i
                className="fa-solid fa-chevron-right"
                id={style.arrowRight}
                // onClick={() => setCount((count) => count + 2)}
              ></i>
              <img className={style.petikCarousel} src={petik} alt="" />
              <div className={style.contentCarousel1}>
                <span className={style.contentTestiCarousel1}>
                  " Ngabsign memberikan pengalaman absensi yang cukup baru dan
                  menarik bagi saya. Tampilan yang user friendly memudahkan saya
                  dalam memahami berbagai fitur yang ada pada aplikasi ini.
                  Apalagi dengan adanya fitur laporan harian menjadikan
                  aktivitas sehari-hari saya semakin produktif. "
                </span>
                <Gap height={16} width={undefined} />
                <div>
                  <span className={style.contentNameCarousel1}>
                    Mushonnef Anwar
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* komen */}
      <div className={style.contentSeven}>
        <div className={style.leftContentSeven}>
          <div className={style.titleContentSeven}>JOHN C. MAXWELL</div>
          <div className={style.subTitle1ContentSeven}>
            <span>TEAMWORK</span>
          </div>
          <div className={style.subTitle2ContentSeven}>
            <span> MAKES THE DREAM WORK</span>
          </div>
          <Gap height={40} width={undefined} />
          <div className={style.botTitleContentSeven}>
            <Link className={style.LinkLI} to={"/team-structure"}>
              <p>Berkenalan dengan Tim</p>
              <Gap height={undefined} width={5} />
              <IoMdArrowForward></IoMdArrowForward>
            </Link>
          </div>
        </div>
        <Gap height={undefined} width={90} />
        <div className={style.rightContentSeven}>
          <div className={style.teamContentSeven}>
            <div className={style.teamOneContentSeven}>
              <div className={style.cardContentSevenGrey}>
                <div className={style.bgcard}></div>
                <img src={fadil} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenAbuMuda}>
                <div className={style.bgcard}></div>
                <img src={SitiNazarina} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenGrey}>
                <div className={style.bgcard}></div>
                <img src={achsani} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenAbuMuda}>
                <div className={style.bgcard}></div>
                <img src={sephia} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenGrey}>
                <div className={style.bgcard}></div>
                <img src={Fachrul} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenAbuMuda}>
                <div className={style.bgcard}></div>
                <img src={yohana} alt="" className={style.timNgabsign} />
              </div>
            </div>
            <div className={style.teamOneContentSeven}>
              <div className={style.cardContentSevenAbuMuda}>
                <div className={style.bgcard}></div>
                <img src={nanda} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenGrey}>
                <div className={style.bgcard}></div>
                <img src={dobith} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenAbuMuda}>
                <div className={style.bgcard}></div>
                <img src={irmawati} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenGrey}>
                <div className={style.bgcard}></div>
                <img src={fajri} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenAbuMuda}>
                <div className={style.bgcard}></div>
                <img src={asfa} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenGrey}>
                <div className={style.bgcard}></div>
                <img src={satria} alt="" className={style.timNgabsign} />
              </div>
            </div>
            <div className={style.teamOneContentSeven}>
              <div className={style.cardContentSevenGrey}>
                <div className={style.bgcard}></div>
                <img src={hasbi} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenAbuMuda}>
                <div className={style.bgcard}></div>
                <img src={arla} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenGrey}>
                <div className={style.bgcard}></div>
                <img src={wildan} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenAbuMuda}>
                <div className={style.bgcard}></div>
                <img src={arummi} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenGrey}>
                <div className={style.bgcard}></div>
                <img src={fikri} alt="" className={style.timNgabsign} />
              </div>
              <div className={style.cardContentSevenAbuMuda}>
                <div className={style.bgcard}></div>
                <img src={kevin} alt="" className={style.timNgabsign} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.contentEight}>
        <div className={style.formContentEight}>
          <div className={style.titleContentEight}>FAQ</div>
          <div className={style.subTitleContentEight}>
            {" "}
            Berikut pertanyaan umum tentang Ngabsign
          </div>
          <Gap height={32} width={undefined} />
          <div className={style.faqContentEight}>
            <div className={style.faq}>
              <div className={style.question}>
                <span className={style.spanfaq}>
                  Apakah sistem Absensi Ngabsign dapat digunakan secara offline?
                </span>
                {showFaq1 ? (
                  <i
                    className="fa-solid fa-minus"
                    id={style.iconminus}
                    onClick={(e) => toggleFaq1(showFaq1)}
                  ></i>
                ) : (
                  <i
                    className="fa-solid fa-plus"
                    id={style.iconplus}
                    onClick={(e) => toggleFaq1(showFaq1)}
                  ></i>
                )}
              </div>
              {showFaq1 && (
                <p className={style.answer}>
                  Tidak, sistem Absensi Ngabsign ini berbasis online
                </p>
              )}
            </div>{" "}
            <Gap height={21} width={undefined} />
            <div className={style.faq}>
              <div className={style.question}>
                <span className={style.spanfaq}>
                  Apa keuntungan yang ditawarkan Ngabsign?
                </span>
                {showFaq2 ? (
                  <i
                    className="fa-solid fa-minus"
                    id={style.iconminus}
                    onClick={(e) => toggleFaq2(showFaq2)}
                  ></i>
                ) : (
                  <i
                    className="fa-solid fa-plus"
                    id={style.iconplus}
                    onClick={(e) => toggleFaq2(showFaq2)}
                  ></i>
                )}
              </div>
              {showFaq2 && (
                <p className={style.answer}>
                  Anda akan mendapatkan berbagai kemudahan dalam manajemen
                  kinerja karyawan, sistem kami juga terintegrasi dengan payroll
                  yang akan memudahkan proses penggajian. Selain itu, kami juga
                  memiliki fitur pendeteksi wajah yang digunakan untuk presensi
                  dan juga banyak kemudahan yang akan anda dapatkan saat
                  menggunakan sistem kami.
                </p>
              )}
            </div>{" "}
            <Gap height={21} width={undefined} />
            <div className={style.faq}>
              <div className={style.question}>
                <span className={style.spanfaq}>
                  Bagaimana jika saya ingin berlangganan sistem Ngabsign ini?
                </span>
                {showFaq3 ? (
                  <i
                    className="fa-solid fa-minus"
                    id={style.iconminus}
                    onClick={(e) => toggleFaq3(showFaq3)}
                  ></i>
                ) : (
                  <i
                    className="fa-solid fa-plus"
                    id={style.iconplus}
                    onClick={(e) => toggleFaq3(showFaq3)}
                  ></i>
                )}
              </div>
              {showFaq3 && (
                <p className={style.answer}>
                  Untuk berlangganan anda cukup mendaftarkan perusahaan anda
                  pada menu registrasi dan memilih paket langganan untuk
                  mendapatkan fitur lengkap yang dapat menunjang kebutuhan
                  perusahaan anda
                </p>
              )}
            </div>{" "}
            <Gap height={21} width={undefined} />
            <div className={style.faq}>
              <div className={style.question}>
                <span className={style.spanfaq}>
                  Berapa biaya yang harus dikeluarkan untuk berlangganan
                  Ngabsign?
                </span>
                {showFaq4 ? (
                  <i
                    className="fa-solid fa-minus"
                    id={style.iconminus}
                    onClick={(e) => toggleFaq4(showFaq4)}
                  ></i>
                ) : (
                  <i
                    className="fa-solid fa-plus"
                    id={style.iconplus}
                    onClick={(e) => toggleFaq4(showFaq4)}
                  ></i>
                )}
              </div>
              {showFaq4 && (
                <p className={style.answer}>
                  Perhitungan biaya tergantung dengan jumlah karyawan dalam
                  perusahaan dan paket langganan yang anda pilih
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={style.contentNine}>
        <div className={style.containerContentNine}>
          <div className={style.leftContainerNine}>
            <div className={style.topLeftContainerNine}>
              <h3 className={style.h3containerNine}>Jangan ragu</h3>
              <span className={style.spancontainerNine}>
                {" "}
                menghubungi kami{" "}
              </span>
            </div>
            <Gap height={40} width={undefined} />
            <div className={style.contentLeftContainerNine}>
              <div className={style.contentContainerNine}>
                <div className={style.leftIMG}>
                  <img className={style.imgContainerNine} src={Lokasi} alt="" />
                </div>
                <div className={style.textContainerNine}>
                  <span className={style.judul}>Lokasi</span>
                  <span className={style.span}>
                    Komplek Ruko, Jl. Borneo Paradiso No.3, Sepinggan,
                    Balikpapan Selatan, Kota Balikpapan, Kalimantan Timur, 76116
                  </span>
                </div>
              </div>
              <Gap height={30} width={undefined} />
              <div className={style.contentContainerNine}>
                <div className={style.leftIMG}>
                  <img className={style.imgContainerNine} src={Surat} alt="" />
                </div>
                <div className={style.textContainerNine}>
                  <span className={style.judul}>Email</span>
                  <span className={style.span}>ngabsignid@gmail.com</span>
                </div>
              </div>
              <Gap height={30} width={undefined} />
              <div className={style.contentContainerNine}>
                <div className={style.leftIMG}>
                  <img className={style.imgContainerNine} src={Phone} alt="" />
                </div>
                <div className={style.textContainerNine}>
                  <span className={style.judul}>Phone</span>
                  <span className={style.span}>+6281-1539-0600</span>
                </div>
              </div>
            </div>
          </div>
          <Gap height={undefined} width={90} />
          <div className={style.rightContainerNine}>
            <div className={style.formContainerNine}>
              <div className={style.formTopNine}>
                <div className={style.formNENine}>
                  <label className={style.labelContentNine} htmlFor="">
                    {" "}
                    Nama Anda
                  </label>
                  <Gap height={8} width={undefined} />
                  <input className={style.inputNE} type="text" />
                </div>
                <Gap height={undefined} width={20} />
                <div className={style.formNENine}>
                  <label className={style.labelContentNine} htmlFor="">
                    Email Anda
                  </label>
                  <Gap height={8} width={undefined} />
                  <input className={style.inputNE} type="text" name="" id="" />
                </div>
              </div>
              <Gap height={26} width={undefined} />
              <div className={style.formSubjek}>
                <label className={style.labelContentNine} htmlFor="">
                  Subjek
                </label>
                <Gap height={8} width={undefined} />
                <input className={style.inputSubjek} type="text" />
              </div>
              <Gap height={26} width={undefined} />
              <div className={style.formPesan}>
                <label className={style.labelContentNine} htmlFor="">
                  Pesan
                </label>
                <Gap height={8} width={undefined} />
                <textarea className={style.inputPesan} />
              </div>
              <Gap height={25} width={undefined} />
              <div className={style.buttonNine}>
                <button className={style.btnNine}>Kirim Pesan</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.contentTen}>
        <div className={style.contentSelingan}>
          <div className={style.isiContentSelingan}>
            <div className={style.logoSelingan}>
              <img src={LogoLP} alt="" />
            </div>
            <div className={style.textSelingan}>
              <h3 className={style.h3Selingan}>
                Satu Aplikasi untuk mempermudah seluruh kegiatan Perusahaan Anda
              </h3>
            </div>
            <div className={style.buttonSelingan}>
              <button
                className={style.btnSelingan}
                onClick={() => {
                  toggleModal();
                }}
              >
                Masuk
              </button>
            </div>
          </div>
        </div>
        <div className={style.contentWrapperTen}>
          <div className={style.contentFirst}>
            <div className={style.wrapperIMGTen}>
              <img
                className={style.logoContentTen}
                src={LogoContentTen}
                alt=""
              />
            </div>
            <Gap height={8} width={undefined} />
            <div className={style.firstTextContent10}>
              <span className={style.firstSpanContent10}>
                Komplek Ruko, Jl. Borneo Paradiso No.3, Sepinggan, Balikpapan
                Selatan, Kota Balikpapan, Kalimantan Timur, 76116
              </span>
            </div>
            <Gap height={14} width={undefined} />
            <div className={style.twoTextContent10}>
              <span className={style.SpanContent10}>
                {" "}
                <span className={style.boldSpanContent10}>
                  {" "}
                  Telepon :{" "}
                </span>{" "}
                +6281-1539-0600
              </span>
            </div>
            <div className={style.threeTextContent10}>
              <span className={style.SpanContent10}>
                <span className={style.boldSpanContent10}>Email :</span>{" "}
                ngabsignid@gmail.com
              </span>
            </div>
          </div>
          <Gap height={undefined} width={94} />
          <div className={style.contentSecond}>
            <div className={style.titleContentSecond}>
              <span className={style.spanTitleContentSecond}>Tautan Cepat</span>
            </div>
            <div className={style.listContentSecond}>
              <ul>
                <li className={style.liContentSecond}>Home</li>
                <li className={style.liContentSecond}>About Us</li>
                <li className={style.liContentSecond}>Services</li>
                <Link
                  className={style.linkContentSecond}
                  to="/syarat-ketentuan"
                >
                  <li className={style.liContentSecond}>Terms of service</li>
                </Link>
                <Link className={style.linkContentSecond} to="/privacy-policy">
                  <li className={style.liContentSecond}>Privacy Policy</li>
                </Link>
              </ul>
            </div>
          </div>
          <Gap height={undefined} width={120} />
          <div className={style.contentThird}>
            <div className={style.titleContentThird}>
              <span className={style.spanTitleContentThird}>Our Services</span>
            </div>
            <div className={style.listContentThird}>
              <ul>
                <li className={style.liContentThird}>Presensi Digital</li>
                <li className={style.liContentThird}>Jadwal Kerja</li>
                <li className={style.liContentThird}>Laporan Harian</li>
                <li className={style.liContentThird}>Performa Kerja</li>
                <li className={style.liContentThird}>Perizinan</li>
                <li className={style.liContentThird}>Pengumuman</li>
                <li className={style.liContentThird}>Gaji</li>
              </ul>
            </div>
          </div>
          <Gap height={undefined} width={120} />
          <div className={style.contentFourth}>
            <div className={style.titleContentFourth}>
              <span className={style.spanTitleContentFourth}>
                Berlangganan Sekarang
              </span>
            </div>
            <Gap height={12} width={undefined} />
            <div className={style.subTitleContentFourth}>
              <span className={style.spanSubTitleContentFourth}>
                Berlangganan buletin untuk mendapatkan info baru dan promosi
                dari Ngabsign
              </span>
            </div>
            <Gap height={24} width={undefined} />
            <div className={style.inputContentFourth}>
              <div>
                <input
                  className={style.ipContentFourth}
                  type="text"
                  placeholder="Masukkan Email Anda"
                />
              </div>
              <div>
                <button className={style.buttonContentFourth}>Subcribe</button>{" "}
              </div>
            </div>
            <Gap height={55.43} width={undefined} />
            <div className={style.imgContentFifth}>
              <div>
                <img
                  className={style.imageContentFifth}
                  src={GooglePlay}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.footer}>
        <span className={style.footerAtas}>
          © Copyright<span className={style.footerBold}> Ngabsign. </span> All
          Rights Reserved
        </span>
        <span className={style.footerBawah}>
          Designed by<span className={style.footerBold}> Ngabsign.</span>
        </span>
      </div>
      <Login
        title="Approve User"
        visible={showModal}
        onClose={() => toggleModal()}
        onSubmit={() => approve()}
      ></Login>
      <OptionRegister
        title="Approve User"
        visible={showModal2}
        onClose={() => toggleModal2()}
        onSubmit={() => approve()}
      ></OptionRegister>
    </div>
  );
};

export default LandingPage;
