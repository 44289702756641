import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./Perizinan.module.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Gap from "../../component/atoms/Gap";
import DashboardLayout from "../../layouts/dashboard-layout/DashboardLayout";
import { calendar, download } from "../../assets";
import ModalDanger from "../../component/modal-danger/modaldanger";
import { perizinanService, userService } from "../../services";
import { UserResponse } from "../../types/user";
import DatePicker from "react-datepicker";
import { BiPaperclip } from "react-icons/bi";
import { useForm } from "react-hook-form";
import NavbarPerizinan from "../../component/NavbarPerizinan/NavbarPerizinan";

const Perizinan: React.FC = () => {
  const [profile, setProfile] = useState<UserResponse>();
  const [modalForm, setModalForm] = useState<boolean>(false);
  const history = useNavigate();
  useEffect(() => {
    const data = async () => {
      try {
        const user = await userService.getProfile();
        setProfile(user.data);
        console.log(user.data);
        localStorage.getItem("@token");
        return false;
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        }
      }
    };
    data();
  }, []);

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        {modalForm ? null : <NavbarPerizinan />}

        {/* <div className={styles.topWrapper}> */}
        <div className={styles.detailContainer}>
          <Outlet />
          <div
            className="toogle-modal w-10 h-10 fixed top-auto bottom-28 left-auto right-8 bg-blue-700 text-3xl flex justify-center items-center rounded-full text-white"
            onClick={() => history("/form-perizinan")}
          >
            +
          </div>
        </div>
      </div>
      {/* </div>  */}
    </DashboardLayout>
  );
};

export default Perizinan;
