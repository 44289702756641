import { spawn } from "child_process";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { profileblue } from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import { profileService, userService } from "../../../services";
import { ProfileResponse, UserProfile } from "../../../types/profile";
import { UserResponse } from "../../../types/user";
import styles from "./Informasi.module.css";
import { ChangeProfileRequest } from "../../../types/changeProfileRequest";
import ReactDatePicker from "react-datepicker";

const Informasi: React.FC = () => {
  const [profile, setProfile] = useState<UserProfile>();
  const [profileChange, setProfileChange] = useState<ChangeProfileRequest>({
    full_name: "",
    phone: "",
    address: "",
    avatar: "",
    birth_date: Date(),
    birth_place: "",
  });
  //
  const [profile2, setProfile2] = useState<UserResponse>();

  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const history = useNavigate();

  const handleChange = (key: string, value: any) => {
    if (value === "") {
      return setProfileChange({ ...profileChange, [key]: null });
    }
    setProfileChange({ ...profileChange, [key]: value });
    console.log("Change: ", profileChange);
  };
  const handleChangeDate = (value: any) => {
    if (isNaN(new Date(value).getTime())) {
      setProfileChange({
        ...profileChange,
        birth_date: null,
      });
      console.log("Change: ", profileChange);
    } else {
      setProfileChange({ ...profileChange, birth_date: value });
      console.log("Change: ", profileChange);
    }
  };

  const onClickChangeProfile = async () => {
    try {
      const resp = await profileService.putProfile(profileChange);
      console.log("Response : ", resp);
      alert("Data diri berhasil diperbarui!");
      history("/absensi");
    } catch (e: any) {
      console.log("error :", e.response.data.meta.errors);
      if (e.response.data.meta.errors[0].param === "birth_date") {
        return alert("Silahkan ubah tanggal lahir anda telebih dahulu!");
      }
      alert("Maaf, Terjadi Kesalahan");
    }
  };

  useEffect(() => {
    const data = async () => {
      try {
        const user = await profileService.getProfile();
        const data = user.data.data.user_profile;
        setProfile(data);
        setProfileChange(data);
        console.log("Profile : ", data);
        localStorage.getItem("@token");
        return false;
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        }
      }
    };
    data();
  }, []);

  useEffect(() => {
    const data = async () => {
      try {
        const user = await userService.getProfile();
        setProfile2(user.data);
        console.log(user.data);
        localStorage.getItem("@token");
        return false;
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        }
      }
    };
    data();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.topinformasiProfile}>
        <span>Informasi Pribadi</span>
      </div>
      <div className={styles.border}></div>
      <div className={styles.formProfile}>
        <span>Data Pribadi</span>

        <Gap height={15} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Nama Lengkap
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="text"
            value={`${profileChange?.full_name}`}
            onChange={(e) => handleChange("full_name", e.target.value)}
            placeholder="Masukan Nama Anda"
          ></input>
          {/* <img className={styles.iconProfile} src={profileblue} alt="" /> */}
        </div>

        <Gap height={15} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Tempat Lahir
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="text"
            value={
              profileChange?.birth_place === null
                ? ""
                : profileChange.birth_place
            }
            onChange={(e) => handleChange("birth_place", e.target.value)}
            placeholder="Masukan Tempat Lahir Anda"
          />
        </div>

        <Gap height={15} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Tanggal Lahir
          </label>
          <Gap height={5} width={undefined} />
          <ReactDatePicker
            className={
              profileChange.birth_date === null &&
              JSON.stringify(profile) !== JSON.stringify(profileChange)
                ? styles.inputWrong
                : styles.inputFullname
            }
            selected={new Date(profileChange.birth_date)}
            onChange={(date) =>
              handleChangeDate(
                date
                  ?.toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                  .split("/")
                  .reverse()
                  .join("-")
              )
            }
            onCalendarClose={() =>
              isNaN(new Date(profileChange.birth_date).getTime())
                ? setProfileChange({
                    ...profileChange,
                    birth_date: profile?.birth_date,
                  })
                : console.log("Bener")
            }
            dateFormat="yyyy-MM-dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            yearDropdownItemNumber={15}
          />
          {profileChange.birth_date === null &&
          JSON.stringify(profile) !== JSON.stringify(profileChange) ? (
            <p className="text-red-600">
              Silahkan perbarui tanggal lahir anda!
            </p>
          ) : null}
          {/* <input
            className={styles.inputFullname}
            type="text"
            value={`${profileChange?.birth_date}`}
            onChange={(e) => handleChange("birth_date", e.target.value)}
          /> */}
        </div>

        <Gap height={35} width={undefined} />

        <span> Kontak Pribadi</span>

        <Gap height={15} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Email
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="text"
            value={`${profile?.email}`}
            onChange={(e) => handleChange("email", e.target.value)}
            disabled={true}
            style={{ background: "gray" }}
          />
        </div>

        <Gap height={15} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Nomor Handphone
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="text"
            value={`${profileChange?.phone}`}
            onChange={(e) => handleChange("phone", e.target.value)}
            placeholder="Masukan Nomor Handphone Anda"
          />
        </div>

        <Gap height={10} width={undefined} />

        <div className={styles.profileFullname}>
          <label className={styles.labelFullname} htmlFor="fullname">
            Alamat Domisili
          </label>
          <Gap height={5} width={undefined} />
          <input
            className={styles.inputFullname}
            type="text"
            value={`${profileChange?.address}`}
            placeholder="Masukan Alamat Domisili Anda"
          />
        </div>

        <Gap height={35} width={undefined} />

        <button
          className={styles.buttonProfile}
          onClick={() => onClickChangeProfile()}
          disabled={JSON.stringify(profile) === JSON.stringify(profileChange)}
          style={
            JSON.stringify(profile) === JSON.stringify(profileChange)
              ? { background: "gray" }
              : {}
          }
        >
          Simpan Perubahan
        </button>
      </div>
    </div>
  );
};

export default Informasi;
