import React, { ChangeEvent, useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import "./RegisterPerusahaan.css";
import { logofix, bgfix } from "../../../assets/index";
import Gap from "../../../component/atoms/Gap";
import { useForm } from "react-hook-form";
import { authService, TransaksiService } from "../../../services";
import { RegisterAdminRequest } from "../../../types/registeradmin";
import { TransaksiResponse } from "../../../types/transaksi";
import { GetStatusResponse } from "../../../types/orderId";
import Login from "../Login/Login";

type idParams = {
  order_id: string;
};

const RegisterPerusahaan: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [rePasswordShow, setRePasswordShow] = useState(false);
  const [repeatedPassword, setRepeatedPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState(1);
  const history = useNavigate();
  const [subscriptionID, setSubscriptionID] = useState(0);
  const [getDataTransaksi, setGetDataTransaksi] = useState<GetStatusResponse>();
  const { order_id } = useParams<idParams>();
  console.log(order_id);
  const [showModal, setShowModal] = useState<boolean>(false);
  const approve = () => {
    console.log("approved");
  };

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };
  const [data, setData] = useState<RegisterAdminRequest>({
    admin_full_name: "",
    admin_email: "",
    admin_phone: "",
    password: "",
    admin_position: "",
    company_name: "",
    company_email: "",
    business_field: "",
    company_country: "",
    company_time_zone: 8,
    company_postal_code: 0,
    personnel_quota: 0,
    company_department: "",
    subscription_id: 0,
    subscription_period: 30,
    payment_period: 30,
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    let number = 0;
    if (
      e.target.name === "company_time_zone" ||
      e.target.name === "company_postal_code" ||
      e.target.name === "personnel_quota"
    ) {
      number = parseInt(e.target.value);
      setData((prev) => ({
        ...prev,
        [e.target.name]: number,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        [e.target.name]: value,
      }));
    }
  };

  const onChangeRadio = (n: number) => {
    setData((prev) => ({
      ...prev,
      subscription_id: n,
    }));
  };

  const togglePassword = () => {
    setPasswordShow(!passwordShow);
  };
  const togglePassword2 = () => {
    setRePasswordShow(!rePasswordShow);
  };

  let isPasswordMatch: undefined | boolean = undefined;
  if (password !== repeatedPassword) {
    isPasswordMatch = false;
  } else {
    isPasswordMatch = true;
  }

  useEffect(() => {
    const getTransaksi = async (params: any) => {
      try {
        const getTransaksi = await TransaksiService.getTransaksiResponse(
          params
        );
        setGetDataTransaksi(getTransaksi.data);
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          history("/");
        }
      }
    };
    getTransaksi(order_id);
  }, []);
  console.log("sadasd", getDataTransaksi);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (form: any) => {
    console.log(form);
    setLoading(true);
    try {
      const register = await authService.register_admin(data);
      history(
        `/auth/register-perusahaan/detail-pembayaran?ae=${register.data.data.user.email}&pq=${data.personnel_quota}&sid=${data.subscription_id}&ce=${data.company_email} `
      );
      console.log(register);
      alert("berhasil");
    } catch (error: any) {
      if (error.response.status == 400) {
        console.log("kesalahan");
        console.log(error);
      } else if (error.response.status == 302) {
        console.log("jhgjhghj", error);
      } else {
        if (error.response.data.meta.message) {
          alert(error.response.data.meta.message);
        } else {
          alert("Terjadi masalah!");
        }
      }
    }

    setLoading(false);
  };
  // console.log(count <= 2 && btnDisable, count <= 2, btnDisable)
  // console.log(btnDisable)
  // console.log(data)

  console.log(data);
  // console.log(getDataTransaksi?.order_id)

  return (
    <div className="reg-karyawan-wrapper">
      <div className="reg-karyawan-box">
        <div className="reg-karyawan-wrapper-left">
          <img src={bgfix} alt="" />
          <div className="reg-desc">
            <h2>Kelola absensi dan kegiatan lebih mudah dengan Ngabsign</h2>
            <p>The best choice for your company</p>
          </div>
        </div>

        <div className="reg-karyawan-wrapper-right">
          <div className="form-reg-karyawan-wrapper">
            <img src={logofix} alt="" />
            <Gap height={20} width={undefined} />
            <h1>Daftar Sebagai Admin</h1>
            <Gap height={5} width={undefined} />
            <p className="reg-karyawan-desc">
              Lengkapi form di bawah dengan menggunakan data yang Valid{" "}
            </p>
            <Gap height={20} width={undefined} />
            <form onSubmit={handleSubmit(onSubmit)}>
              {count === 1 ? (
                <>
                  <div className="input-fullname-karyawan">
                    <input
                      id="fullname"
                      name="admin_full_name"
                      type="text"
                      placeholder="Masukkan Nama Lengkap"
                      // {...register("admin_full_name", { required: true })}
                      onChange={onChange}
                    />
                    {errors.admin_full_name && (
                      <span className="message-error">
                        Wajib Mengisi Nama Lengkap
                      </span>
                    )}
                    <i className="fa-solid fa-user"></i>
                  </div>
                  <Gap height={20} width={undefined} />
                  <div className="input-email-karyawan">
                    <input
                      id="email"
                      name="admin_email"
                      type="text"
                      placeholder="Masukkan Email Anda"
                      // {...register("admin_email", {
                      //   required: true,
                      //   pattern:
                      //   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      // })}
                      onChange={onChange}
                    />
                    {errors.admin_email && (
                      <span className="message-error">
                        Wajib Mengisi Nama Lengkap
                      </span>
                    )}
                    <i className="fa-solid fa-envelope"></i>
                  </div>

                  <Gap height={20} width={undefined} />

                  <div className="input-telepon-karyawan">
                    <input
                      id="text"
                      type="text"
                      name="admin_phone"
                      placeholder="Masukkan Nomor Telepon Anda"
                      // {...register("admin_phone", { required: true })}
                      onChange={onChange}
                    />
                    <i className="fa-sharp fa-solid fa-phone"></i>
                  </div>

                  <Gap height={20} width={undefined} />

                  <div className="input-password-karyawan">
                    <input
                      type={passwordShow ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Password"
                      // {...register("password", { required: true })}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        onChange(e);
                      }}
                    />
                    <i className="fa-sharp fa-solid fa-lock"></i>
                    <i
                      className={
                        passwordShow
                          ? "fa-solid fa-eye-slash"
                          : "fa-solid fa-eye"
                      }
                      onClick={togglePassword}
                      id="mata"
                    ></i>
                  </div>

                  <Gap height={20} width={undefined} />

                  <div className="input-konfirmasi-password-karyawan">
                    <input
                      onChange={(e) => setRepeatedPassword(e.target.value)}
                      type={rePasswordShow ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Konfirmasi Password"
                    />
                    <i className="fa-sharp fa-solid fa-lock"></i>
                    <i
                      className={
                        rePasswordShow
                          ? "fa-solid fa-eye-slash"
                          : "fa-solid fa-eye"
                      }
                      onClick={togglePassword2}
                      id="mata"
                    ></i>
                    <div className="match-password">
                      {isPasswordMatch === false ? (
                        <span>*Pastikan password anda sama</span>
                      ) : null}
                    </div>
                  </div>

                  <Gap height={20} width={undefined} />

                  <div className="input-kode-perusahaan">
                    <input
                      id="string"
                      type="text"
                      placeholder="Posisi Pekerjaan Anda"
                      name="admin_position"
                      // {...register("admin_position", { required: true })}
                      onChange={onChange}
                    />
                    <i className="fa-solid fa-users"></i>
                  </div>

                  <Gap height={20} width={undefined} />
                  <Gap height={25} width={undefined} />
                  <button
                    className="btnNext"
                    onClick={() => setCount((count) => count + 1)}
                    disabled={count > 2}
                  // disabled={btnDisable}
                  >
                    Lanjut
                  </button>
                  <Gap height={20} width={undefined} />
                  <p>
                    {" "}
                    Sudah punya akun?
                    <span
                      className="masuk"
                      onClick={() => {
                        toggleModal();
                      }}
                    >
                      {" "}
                      Masuk{" "}
                    </span>
                  </p>
                </>
              ) : null}
              {count === 2 ? (
                <>
                  {/* <Gap height={20} width={undefined} /> */}
                  <div className="input-fullname-karyawan">
                    <input
                      id="fullname"
                      type="text"
                      placeholder="Nama Perusahaan"
                      name="company_name"
                      // {...register("company_name", { required: true })}
                      onChange={onChange}
                    />
                    <i className="fa-solid fa-building"></i>
                  </div>

                  <Gap height={20} width={undefined} />

                  <div className="input-email-karyawan">
                    <input
                      id="email"
                      type="text"
                      placeholder="Email Perusahaan"
                      name="company_email"
                      // {...register("company_email", { required: true })}
                      onChange={onChange}
                    />
                    <i className="fa-solid fa-envelope"></i>
                  </div>

                  <Gap height={20} width={undefined} />

                  <div className="input-password-karyawan">
                    <input
                      id="text"
                      type="text"
                      placeholder="Bidang Bisnis"
                      name="business_field"
                      // {...register("business_field", { required: true })}
                      onChange={onChange}
                    />
                    <i className="fa-solid fa-earth-americas"></i>
                  </div>

                  <Gap height={20} width={undefined} />

                  <div className="input-fullname-karyawan">
                    <input
                      id="fullname"
                      type="text"
                      placeholder="Kode Perusahaan"
                      name="company_postal_code"
                      // {...register("company_postal_code", { required: true })}
                      onChange={onChange}
                    />
                    <i className="fa-solid fa-building"></i>
                  </div>

                  <Gap height={20} width={undefined} />

                  <div className="input-telepon-karyawan">
                    <input
                      id="text"
                      type="text"
                      placeholder="Jumlah Karyawan"
                      name="personnel_quota"
                      // {...register("personnel_quota", { required: true })}
                      onChange={onChange}
                    />
                    <i className="fa-solid fa-users"></i>
                  </div>

                  <Gap height={20} width={undefined} />

                  <div className="input-password-karyawan">
                    <input
                      id="text"
                      type="text"
                      placeholder="Negara"
                      // {...register("company_country", { required: true })}
                      name="company_country"
                      onChange={onChange}
                    />
                    <i className="fa-solid fa-earth-americas"></i>
                  </div>

                  <Gap height={20} width={undefined} />

                  <div className="input-password-karyawan">
                    <select
                      className=""
                      id=""
                      {...register("company_time_zone", { required: true })}
                    >
                      {" "}
                      <option value={7}>WIB</option>
                      <option value={8}>WITA</option>
                      <option value={9}>WIT</option>
                    </select>
                    {/* <input
                      id="text"
                      type="text"
                      placeholder="Zona Waktu"
                      name="company_time_zone"
                      // {...register("company_time_zone", { required: true })}
                      onChange={onChange}
                    />
                    <i className="fa-solid fa-earth-americas"></i> */}
                  </div>

                  <Gap height={20} width={undefined} />
                  <Gap height={25} width={undefined} />

                  <div className="untuk-button-two">
                    <button
                      className="backtwo"
                      onClick={() => setCount(count - 1)}
                      disabled={count < 2}
                    >
                      Kembali
                    </button>

                    <Gap height={undefined} width={10} />

                    <button
                      className="nextthree"
                      onClick={() => setCount((count) => count + 1)}
                      disabled={count > 2}
                    >
                      Lanjut
                    </button>
                  </div>
                </>
              ) : null}
              {count === 3 ? (
                <>
                  <Gap height={20} width={undefined} />

                  <div className="input-password-karyawan">
                    <input
                      id="password"
                      placeholder="Masukkan Departemen"
                      name="company_department"
                      // {...register("company_department", { required: true })}
                      onChange={onChange}
                    />
                    <i className="fa-solid fa-users"></i>
                    <i className="fa-solid fa-plus" id="mata"></i>
                  </div>

                  <Gap height={20} width={undefined} />

                  <div className="radioPriceBtn">
                    <label>
                      <input
                        type="radio"
                        value={1}
                        name="subscription_id"
                        // {...register("subscription_id", { required: true })}
                        onChange={onChange}
                      />
                      {/* <img className="freeprice" src={freeprice} alt="freeprice" /> */}
                      <div className="bluepayment">
                        <div className="bluepaymentform">
                          <div className="bluepaymenttitle">
                            <h3>Free</h3>
                            <h3>
                              Rp. 0,<span>-</span>
                            </h3>
                          </div>
                          <Gap height={20} width={undefined} />
                          <div className="bluepaymentcontent">
                            <li>Maksimal 10 karyawan</li>
                            <li>Periode 1 bulan</li>
                            <li>
                              Akses fitur absensi dan manajemen jadwal kerja
                            </li>
                          </div>
                        </div>
                      </div>
                    </label>
                    <Gap height={10} width={undefined} />
                    <label>
                      <input
                        type="radio"
                        value={2}
                        name="subscription_id"
                        // {...register("subscription_id", { required: true })}
                        onChange={onChange}
                      />
                      {/* <img className="silverprice" src={silverprice} alt="silverprice" /> */}
                      <div className="silverpayment">
                        <div className="silverpaymentform">
                          <div className="silverpaymenttitle">
                            <h3>Silver</h3>
                            <h3>
                              Rp. 10.000/<span>org</span>
                            </h3>
                          </div>
                          <Gap height={20} width={undefined} />
                          <div className="silverpaymentcontent">
                            <li>Maksimal 50 karyawan</li>
                            <li>Periode 1 bulan</li>
                            <li>
                              Akses fitur absensi, jadwal, reminder, laporan
                              kegiatan, riwayat kehadiran
                            </li>
                          </div>
                        </div>
                      </div>
                    </label>
                    <Gap height={10} width={undefined} />
                    <label>
                      <input
                        type="radio"
                        value={3}
                        name="subscription_id"
                        // {...register("subscription_id", { required: true })}
                        // onChange={onChange}
                        onClick={() => {
                          onChangeRadio(3);
                        }}
                      />
                      {/* <Gap height={undefined} width={10} /> */}
                      <div className="goldpayment">
                        <div className="goldpaymentform">
                          <div className="goldpaymenttitle">
                            <h3>Gold</h3>
                            <h3>
                              Rp. 15.000/<span>org</span>
                            </h3>
                          </div>
                          <Gap height={20} width={undefined} />
                          <div className="goldpaymentcontent">
                            <li>Maksimal 100 karyawan</li>
                            <li
                              value={30}

                            // {...register("subscription_period")}
                            >
                              Periode 1 bulan
                            </li>
                            <li value={60} {...register("payment_period")}>
                              Bebas akses semua fitur Ngabsign
                            </li>
                          </div>
                        </div>
                      </div>
                      {/* <img className="goldprice" src={goldprice} alt="goldprize" /> */}
                    </label>
                  </div>

                  <Gap height={40} width={undefined} />

                  <div className="untuk-button-three">
                    <button
                      className="backtwo"
                      onClick={() => setCount(count - 1)}
                      disabled={count < 2}
                    >
                      Kembali
                    </button>
                    <Gap height={undefined} width={10} />
                    <button type="submit" className="btnDaftar">
                      Daftar
                    </button>
                  </div>
                </>
              ) : null}
              {/* {count === 3 ? (
           <div className="untuk-button-three">
           <button className="btnDaftarAdmin"
           type="submit"
           >
            Daftar
            </button>
           </div>
        ) : null} */}
            </form>
          </div>
        </div>
      </div>
      <Login
        title="Approve User"
        visible={showModal}
        onClose={() => toggleModal()}
        onSubmit={() => approve()}
      ></Login>
    </div>
  );
};

export default RegisterPerusahaan;
