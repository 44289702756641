import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { admin, bgfix, closeButton, karyawan } from "../../../assets/index";
import Gap from "../../../component/atoms/Gap";
import RegisterKaryawan from "../register-karyawan/RegisterKaryawan";
import RegisterPerusahaan from "../register-perusahaan/RegisterPerusahaan";
import "./OptionRegister.css";

interface ModalProps {
  title: string;
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children?: React.ReactNode;
  closeText?: string;
  submitText?: string;
  // closeBtnProps?: Omit<ButtonProps, "children">;
  // submitBtnProps?: Omit<ButtonProps, "children">;
}

const OptionRegister: React.FC<ModalProps> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModal2, setShowModal2] = useState<boolean>(false);

  const approve = () => {
    console.log("approved");
  };

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };
  const toggleModal2 = () => {
    setShowModal2((prevState) => !prevState);
  };
  const history = useNavigate();
  const { children, onClose, visible } = props;

  if (!visible) return <></>;

  return (
    <div className="option-register-wrapper">
      <div className="option-register-box">
        <div className="option-register-wrapper-left">
          <img src={bgfix} alt="" />
          <div className="option-register-desc">
            <h2>Kelola absensi dan kegiatan lebih mudah dengan Ngabsign</h2>
            <p>The best choice for your company</p>
          </div>
        </div>
        <div className="option-register-wrapper-right">
          <div className="wrapper-close">
            <span className="buttonCloseRegister" onClick={onClose}>
              <img src={closeButton} alt="" />
            </span>
          </div>
          {/* <img src={logo} alt="" /> */}
          <Gap height={20} width={undefined} />
          <h1>Daftar Sebagai</h1>
          <Gap height={5} width={undefined} />
          <div className="option-register-img-wrapper">
            {/* <img src={admin} alt="" /> */}
            <div>
              <img
                className="imageAdmin"
                src={karyawan}
                alt=""
                onClick={() => history("/auth/register-karyawan")}
              />
              <p className="text">Karyawan</p>
            </div>
            <div>
              <img
                className="imageAdmin"
                src={admin}
                alt=""
                onClick={() => history("/auth/register-perusahaan")}
              />
              <p className="text">Admin</p>
            </div>
          </div>
          {/* <button className="button-option">Masuk</button> */}
        </div>
      </div>
    </div>
  );
};

export default OptionRegister;
