import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ProfileCircle,
  ProfileCircleBlue,
  arrowleft,
  fotoprofile,
  logofix,
  unlock,
  unlockblue
} from "../../../assets";
import NavbarProfile from "../../../component/NavbarProfile/NavbarProfile";
import Gap from "../../../component/atoms/Gap";
import { profileService, userService } from "../../../services";
import { ProfileResponse } from "../../../types/profile";
import { UserResponse } from "../../../types/user";
import styles from "./profile.module.css";

const Profile: React.FC = () => {
  const [profile, setProfile] = useState<ProfileResponse>();
  const [profile2, setProfile2] = useState<UserResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useNavigate();

  useEffect(() => {
    const data = async () => {
      try {
        const user = await profileService.getProfile();
        setProfile(user.data);
        localStorage.getItem("@token");

        return false;
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        }
      }
    };
    data();
  }, []);

  useEffect(() => {
    const data = async () => {
      try {
        const user = await userService.getProfile();
        setProfile2(user.data);
        localStorage.getItem("@token");

        return false;
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        }
      }
    };
    data();
  }, []);

  let location = useLocation();

  return (
    <div className={styles.wrapper}>
      {/* Dropdown 768px */}
      <NavbarProfile />

      <div className={styles.leftContainer}>
        <div
          className={styles.topLeftContainer}
          onClick={() => history("/absensi")}
        >
          <img className={styles.arrowleft} src={arrowleft} alt="" />
          <Gap height={undefined} width={10} />
          <span>Kembali</span>
          <img src={logofix} alt="logo ngabsign" className={styles.logo} />
        </div>
        <div className={styles.topSubLeftContainer}>
          {profile?.data.user_profile.avatar === null ? (
            <img src={fotoprofile} className={styles.navbarAvatarProfile} />
          ) : (
            <img
              className={styles.navbarAvatarProfile}
              src={profile?.data.user_profile.avatar}
              alt="avatar"
            />
          )}
          <span className={styles.userNameProfile}>
            {profile?.data.user_profile.full_name}
          </span>
          <Gap height={5} width={undefined} />
          <span className={styles.perusahaanNameProfile}>
            {profile2?.data.employee.company_name}
          </span>
          <span className={styles.departmenNameProfile}>
            {profile2?.data.employee.department}
          </span>
        </div>
        <div className={styles.midLeftContainer}>
          <Gap height={30} width={undefined} />
          <span>Konfigurasi Akun</span>
          <Gap height={5} width={undefined} />
          <div className={styles.midContentLeftContainer}>
            {location.pathname === "/profile/informasi" ? (
              <Link
                to="/profile/informasi"
                className={styles.linkSidebarActive}
              >
                <img
                  src={ProfileCircleBlue}
                  alt="icon sidebar"
                  className={styles.iconSidebar}
                />
                <h5 className={styles.textColor}>Informasi Pribadi</h5>
              </Link>
            ) : (
              <Link to="/profile/informasi" className={styles.linkSidebar}>
                <img
                  src={ProfileCircle}
                  alt="icon sidebar"
                  className={styles.iconSidebar}
                />
                <h5 className={styles.text}>Informasi Pribadi</h5>
              </Link>
            )}

            {location.pathname === "/profile/kata-sandi" ? (
              <Link
                to="/profile/kata-sandi"
                className={styles.linkSidebarActive}
              >
                <img
                  src={unlockblue}
                  alt="icon sidebar"
                  className={styles.iconSidebar}
                />
                <h5 className={styles.textColor}>Ubah Kata Sandi</h5>
              </Link>
            ) : (
              <Link to="/profile/kata-sandi" className={styles.linkSidebar}>
                <img
                  src={unlock}
                  alt="icon sidebar"
                  className={styles.iconSidebar}
                />
                <h5 className={styles.text}>Ubah Kata Sandi</h5>
              </Link>
            )}
          </div>
        </div>
        <Gap height={18} width={undefined} />
        <div className={styles.bottomLeftContainer}>
          {/* <span>Lainnya</span>

            {location.pathname  === "/profile/gaji" ? (
              <Link to="/profile/gaji" className={styles.linkSidebarActive}>
                <img src={gaji} alt="icon sidebar" className={styles.iconSidebar} />
                <h5 className={styles.textColor}>Gaji</h5>
              </Link>
            ) : (
              <Link to="/profile/gaji" className={styles.linkSidebar}>
                <img src={gaji} alt="icon sidebar" className={styles.iconSidebar} />
                <h5 className={styles.text}>Gaji</h5>
              </Link>
            )}

            {location.pathname  === "/profile/profile-perusahaan" ? (
              <Link to="/profile/profile-perusahaan" className={styles.linkSidebarActive}>
                <img src={perusahaan} alt="icon sidebar" className={styles.iconSidebar} />
                <h5 className={styles.textColor}>Profil Perusahaan</h5>
              </Link>
            ) : (
              <Link to="/profile/profile-perusahaan" className={styles.linkSidebar}>
                <img src={perusahaan} alt="icon sidebar" className={styles.iconSidebar} />
                <h5 className={styles.text}>Profil Perusahaan</h5>
              </Link>
            )} */}
        </div>
      </div>

      <div className={styles.rightWrapper}>
        <Outlet />
      </div>
    </div>
  );
};

export default Profile;
