import {AxiosResponse} from "axios";
import { DownloadRequest } from "../types/download";
import { MonitoringKehadiranResponse } from "../types/kehadiran";
import httpRequestV2 from "./apiv2";

  export const getKehadiran = async (): Promise<AxiosResponse<MonitoringKehadiranResponse>> => {
    return await httpRequestV2.get('sub-admin/attendance')
}

export const Download = (
  request: DownloadRequest
): Promise<AxiosResponse> => {
  return httpRequestV2.post("sub-admin/download/attendance/excel", request);
};
