import React from "react";
import { calendar } from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import styles from "./ManualAbsensi.module.css";

const ManualAbsensi: React.FC = () => {
    return (
        <DashboardLayout>
        <div className={styles.wrapper}>
             {/* <div className={styles.topWrapper}> */}
           <div className={styles.detailContainer}>
             <div className={styles.container}>
             <div className={styles.leftContainer}>
        <Gap height={30} width={undefined} />

               <h2 className={styles.pageTitle}>Manual Absensi</h2>
               <Gap height={10} width={undefined} />
      
               <div className={styles.perizinanFullname}>
                 <label className={styles.labelFullname} htmlFor="fullname">Nama Lengkap</label>
                 <Gap height={5} width={undefined} />
                 <input className={styles.inputFullname} type="text" value="Fachrul Dwi Aprilian"/>
               </div>
      
               <Gap height={10} width={undefined} />
      
               <div className={styles.perizinanDepartemen}>
                 <label className={styles.labelDepartemen} htmlFor="departemen">Departemen</label>
                 <Gap height={5} width={undefined} />
                 <input className={styles.inputDepartemen} type="text" value="Frontend Developer"/>
               </div>
               
               <Gap height={10} width={undefined} />
      
               <div className={styles.tanggalClockIn}>
                 <label className={styles.labelTanggalClockIn} htmlFor="date">Tanggal</label>
                 <Gap height={5} width={undefined} />
                 <input className={styles.inputTanggalClockIn} type="date"/>
               </div>
      
               <Gap height={10} width={undefined} />
      
             <div className={styles.jamMasuk}>
                 <label className={styles.labelJamMasuk} htmlFor="clockout">Jam Pulang</label>
                 <Gap height={5} width={undefined} />
                 <input className={styles.inputJamMasuk} type="time"/>
             </div>

             <Gap height={10} width={undefined} />

            <div className={styles.keterangan}>
                <label className={styles.labelKeterangan} htmlFor="keterangan">Keterangan</label>
                <Gap height={5} width={undefined} />
                <textarea className={styles.inputKeterangan}  placeholder="Tambahkan Keterangan"/>
            </div>
      
               <Gap height={15} width={undefined} />
                     <button className={styles.btnPerizinan}>Ajukan</button>
                     <Gap height={30} width={undefined} />
                 </div>
                 <div className={styles.rightContainer}>
                     <div>
        <Gap height={30} width={undefined} />

                       <h3 className={styles.rightTitle} >Riwayat Absensi</h3>
                       <Gap height={10} width={undefined} />
                       <img className={styles.calendar} src={calendar} alt="calendar" />
                     </div>
                 </div>
               </div>
               </div>
               </div>
               {/* </div>  */}
         
         </DashboardLayout>
    )
}

export default ManualAbsensi