import { useEffect, useState } from "react";
import { ListPerizinanResponse } from "../../types/ListPerizinanEmployee";
import { perizinanService } from "../../services";
import { MdEditNote } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PerizinanNotFound from "../PerzinanNotFound/PerizinanNotFound";

const DaftarPerizinan: React.FC = () => {
  const [perizinan, setPerizinan] = useState<ListPerizinanResponse>();
  const history = useNavigate();
  const getPerizinan = async () => {
    try {
      const list = await perizinanService.getListPerizinanPending();
      setPerizinan(list.data);
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPerizinan();
  }, []);

  return (
    <div className="px-9 flex flex-col gap-1 mb-28">
      {perizinan?.data.permission.length === 0 ? (
        <PerizinanNotFound />
      ) : (
        perizinan?.data.permission.map((item, index) => (
          <div
            key={index}
            className="flex flex-col w-full px-5 border-solid border-2 border-gray-300 rounded-lg "
            onClick={() => history(`/detail-perizinan/${item.id}`)}
          >
            <div className="flex flex-row justify-between border-solid border-b-2 border-gray-300 py-4">
              <div className="flex flex-col gap-px">
                <div className="font-semibold">
                  Jenis Perizinan : {item.type === "permit" ? "Cuti" : "Sakit"}
                </div>
                <div className="text-xs text-gray-400">
                  Diajukan : {item.created_at}
                </div>
              </div>
              {/* <div className="text-3xl text-gray-400">
                <MdEditNote />
              </div> */}
            </div>
            <div className="py-4 text-xs flex flex-row gap-2 items-center overflow-hidden">
              <div className="w-10 h-10 flex items-center flex-shrink-0 text-4xl text-red-600">
                {item.attachment.slice(-3) === "pdf" ? (
                  <FaFilePdf />
                ) : (
                  <img src={item.attachment} alt="" />
                )}
              </div>
              <div className="flex-shrink-0">{item.attachment.slice(-16)}</div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default DaftarPerizinan;
