import React, { ChangeEvent, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import "./Login.css";
import { logofix, bgfix, closeButton } from "../../../assets/index";
import Gap from "../../../component/atoms/Gap";
import { authService } from "../../../services";
import { LoginRequest } from "../../../types/Login";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import LogRocket from "logrocket";

interface ModalProps {
  title: string;
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children?: React.ReactNode;
  closeText?: string;
  submitText?: string;
  // closeBtnProps?: Omit<ButtonProps, "children">;
  // submitBtnProps?: Omit<ButtonProps, "children">;
}

const Login: React.FC<ModalProps> = (props) => {
  const navigate = useNavigate();
  const [sending, setSending] = useState<boolean>(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const { children, onClose, visible } = props;
  const [btnDisable, setBtnDisable] = useState<boolean>(false);

  const togglePassword = () => {
    setPasswordShow(!passwordShow);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const btnStateFn = () => {
    if (data.email.length > 0 && data.password.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("@token");
    if (token) {
      navigate("/absensi");
    }
  }, []);

  useEffect(() => {
    const state = btnStateFn();
    setBtnDisable(state);
  }, [data.email, data.password]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (form: any) => {
    setLoading(true);
    
    try {
      const login = await authService.login(form);
      localStorage.setItem("@token", login.data.token);

      if (login.data.role == "admin") {
        window.location.href = "http://admin.ngabsign.id/";
      } else if (login.data.role == "employee") {
        LogRocket.identify(login.data.data.user.email, {
          name: login.data.data.user.full_name,
          email: login.data.data.user.email,
          role: login.data.role,
          company: login.data.data.company.name
        });

        navigate("/absensi");
      }
    } catch (error: any) {
      if (error.response.status == 401) {
        alert("Email atau pasword yang anda masukkan belum sesuai");
      } else if (error.response.status == 400) {
        alert("Akun anda belum terdaftar");
      } else if (error.response.status == 422) {
        alert("Password atau email yang anda masukkan tidak sesuai");
      } else {
        if (error.response.data.meta.message) {
          alert(error.response.data.meta.message);
        } else {
          alert("Terjadi masalah!");
        }
      }
    }

    setLoading(false);
  };
  if (!visible) return <></>;

  return (
    <div className="login-pwa-wrapper">
      <div className="login-pwa-box">
        <div className="login-pwa-wrapper-left">
          <img src={bgfix} alt="" />
          <div className="lpwl-desc">
            <h2>Kelola absensi dan kegiatan lebih mudah dengan Ngabsign</h2>
            <p>The best choice for your company</p>
          </div>
        </div>
        <div className="login-pwa-wrapper-right">
          <div className="wrapper-close-login">
            {/* <span className="buttonCloseLogin" onClick={onClose}> */}
            <img
              className="buttonCloseLogin"
              onClick={onClose}
              src={closeButton}
              alt=""
            />
            {/* </span> */}
          </div>
          <div className="form-login-pwa-wrapper">
            <img src={logofix} alt="" />
            <Gap height={20} width={undefined} />
            <h1>Selamat Datang!</h1>
            <Gap height={5} width={undefined} />
            <p className="login-pwa-desc">Masuk ke akun email yang terdaftar</p>
            <Gap height={20} width={undefined} />

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="input-email">
                <input
                  placeholder="Email"
                  {...register("email", {
                    required: true,
                    pattern:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <i className="fa-solid fa-envelope"></i>
              </div>
              <Gap height={20} width={undefined} />
              <div className="input-password">
                <input
                  type={passwordShow ? "text" : "password"}
                  placeholder="Password"
                  {...register("password", { required: true })}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <i className="fa-sharp fa-solid fa-lock"></i>
                <i
                  className={
                    passwordShow ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                  }
                  onClick={togglePassword}
                  id="mata"
                ></i>
              </div>
              <Gap height={20} width={undefined} />
              <div className="login-pwa-lupa-password">
                <p>Lupa Password?</p>
              </div>
              <Gap height={25} width={undefined} />
              <button
                className={`button-login-pwa ${
                  btnDisable ? "" : "button-login-pwa-active"
                }`}
                disabled={btnDisable}
                type="submit"
              >
                {sending ? "Masuk..." : "Masuk"}
              </button>
              <Gap height={20} width={undefined} />
              <p> Belum punya akun ? Daftar</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
