import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GooglePlay, LogoContentTen, logofix, LogoLP } from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import style from "./Pricing.module.css";
import Login from "../../Auth/Login/Login";
import OptionRegister from "../../Auth/option-register/OptionRegister";
import { FaBars, FaTimes } from "react-icons/fa";

const Pricing: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModal2, setShowModal2] = useState<boolean>(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const toggleModal2 = () => {
    setShowModal2((prevState) => !prevState);
  };

  const approve = () => {
    console.log("approved");
  };
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.navbar}>
        <div className={style.leftNavbar}>
          <img src={logofix} alt="" className={style.logo} />
        </div>
        <div className={`${style.centerNavbar} ${isNavOpen && style.navOpen}`}>
          <Link className={style.LinkLI} to={"/"}>
            <li
              className={style.contentLI}
              // onClick={history("/landing-page/pricing")}
            >
              Halaman Utama
            </li>
          </Link>
          <li className={style.contentLI}>Tentang</li>
          <li className={style.contentLI}>Servis</li>
          <li className={style.contentLI}>Kontak</li>
          <li className={style.contentLI}>Harga</li>
        </div>
        <div className={style.rightNavbar}>
          <button
            className={style.bttnLoginNavbar}
            onClick={() => {
              toggleModal();
            }}
          >
            Masuk
          </button>
          <button
            className={style.bttnSignupNavbar}
            onClick={() => {
              toggleModal2();
            }}
          >
            Daftar
          </button>
          <button
            className={`${style.navToggle} ${isNavOpen && style.navOpen}`}
            onClick={handleNavToggle}
          >
            {isNavOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
      {/* komen */}
      <div className={style.contentPricing}>
        <div className={style.formContentPrice}>
          <div className={style.titlePricing}>
            <p className={style.pTitlePricing}>
              Pilihan{" "}
              <span className={style.spanTitlePricing}> Harga Terbaik </span>{" "}
              untuk Anda
            </p>
          </div>

          <Gap height={64} width={undefined} />

          <div className={style.listPricing}>
            <div className={style.itemPricing}>
              <div className={style.topItemPricing}>
                <h3 className={style.h3ItemPricing}>Free</h3>
                <p className={style.pItemPricing}>
                  <span className={style.spanItemPricing}>Rp. 0</span>/karyawan
                </p>
              </div>
              <Gap height={25} width={undefined} />
              <div className={style.border}></div>
              <Gap height={25} width={undefined} />
              <div className={style.contentItemPricing}>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Maksimal 10 karyawan</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Periode 1 bulan</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur absensi</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur jadwal</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-xmark" id={style.silang}></i>
                  <Gap height={undefined} width={15} />
                  <p className={style.liItemPricing}>Akses fitur reminder</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-xmark" id={style.silang}></i>
                  <Gap height={undefined} width={15} />
                  <p className={style.liItemPricing}>
                    Akses fitur laporan kegiatan
                  </p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-xmark" id={style.silang}></i>
                  <Gap height={undefined} width={15} />
                  <p className={style.liItemPricing}>
                    Akses fitur riwayat kehadiran
                  </p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-xmark" id={style.silang}></i>
                  <Gap height={undefined} width={15} />
                  <p className={style.liItemPricing}>Akses fitur perizinan</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-xmark" id={style.silang}></i>
                  <Gap height={undefined} width={15} />
                  <p className={style.liItemPricing}>
                    Akses fitur gaji karyawan
                  </p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-xmark" id={style.silang}></i>
                  <Gap height={undefined} width={15} />
                  <p className={style.liItemPricing}>Akses fitur performa</p>
                </div>
              </div>
              <Gap height={24} width={undefined} />
              <div className={style.bottomItemPricing}>
                <button className={style.btnPricing}>Coba Sekarang</button>
              </div>
            </div>

            <Gap height={undefined} width={12} />

            <div className={style.itemPricing}>
              <div className={style.topItemPricing}>
                <h3 className={style.h3ItemPricing}>Silver</h3>
                <p className={style.pItemPricing}>
                  <span className={style.spanItemPricing}>Rp. 10.000</span>
                  /karyawan
                </p>
              </div>
              <Gap height={25} width={undefined} />
              <div className={style.border}></div>
              <Gap height={25} width={undefined} />
              <div className={style.contentItemPricing}>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Maksimal 50 karyawan</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Periode 1 bulan</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur absensi</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur jadwal</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur reminder</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>
                    Akses fitur laporan kegiatan
                  </p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>
                    Akses fitur riwayat kehadiran
                  </p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-xmark" id={style.silang}></i>
                  <Gap height={undefined} width={15} />
                  <p className={style.liItemPricing}>Akses fitur perizinan</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-xmark" id={style.silang}></i>
                  <Gap height={undefined} width={15} />
                  <p className={style.liItemPricing}>
                    Akses fitur gaji karyawan
                  </p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-xmark" id={style.silang}></i>
                  <Gap height={undefined} width={15} />
                  <p className={style.liItemPricing}>Akses fitur performa</p>
                </div>
              </div>
              <Gap height={24} width={undefined} />
              <div className={style.bottomItemPricing}>
                <button className={style.btnPricing}>Coba Sekarang</button>
              </div>
            </div>

            <Gap height={undefined} width={12} />

            <div className={style.itemPricing}>
              <div className={style.topItemPricing}>
                <h3 className={style.h3ItemPricing}>Gold</h3>
                <p className={style.pItemPricing}>
                  <span className={style.spanItemPricing}>Rp. 15.000</span>
                  /karyawan
                </p>
              </div>
              <Gap height={25} width={undefined} />
              <div className={style.border}></div>
              <Gap height={25} width={undefined} />
              <div className={style.contentItemPricing}>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Maksimal 100 karyawan</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Periode 1 bulan</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur absensi</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur jadwal</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur reminder</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>
                    Akses fitur laporan kegiatan
                  </p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>
                    Akses fitur riwayat kehadiran
                  </p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur perizinan</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>
                    Akses fitur gaji karyawan
                  </p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur performa</p>
                </div>
              </div>
              <Gap height={24} width={undefined} />
              <div className={style.bottomItemPricing}>
                <button className={style.btnPricing}>Coba Sekarang</button>
              </div>
            </div>

            <Gap height={undefined} width={12} />

            {/* Unlimited */}
            <div className={style.itemPricing}>
              <div className={style.topItemPricing}>
                <h3 className={style.h3ItemPricing}>Unlimited</h3>
                <p className={style.pItem2Pricing}>
                  Dapatkan akses fitur lengkap dengan harga yang lebih murah
                </p>
              </div>
              <Gap height={25} width={undefined} />
              <div className={style.borderUnlimited}></div>
              <Gap height={25} width={undefined} />
              <div className={style.contentItemPricing}>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>100+ karyawan</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Periode 1 bulan</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur absensi</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur jadwal</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur reminder</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>
                    Akses fitur laporan kegiatan
                  </p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>
                    Akses fitur riwayat kehadiran
                  </p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur perizinan</p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>
                    Akses fitur gaji karyawan
                  </p>
                </div>
                <div className={style.divItemPricing}>
                  <i className="fa-solid fa-check" id={style.checklist}></i>
                  <Gap height={undefined} width={10} />
                  <p className={style.liItemPricing}>Akses fitur performa</p>
                </div>
              </div>
              <Gap height={24} width={undefined} />
              <div className={style.bottomItemPricing}>
                <button className={style.btnPricing}>Hubungi Admin</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.contentTen}>
        {/* <div className={style.contentSelingan}>
          <div className={style.isiContentSelingan}>
            <div className={style.logoSelingan}>
              <img src={LogoLP} alt="" />
            </div>
            <div className={style.textSelingan}>
              <h3 className={style.h3Selingan}>
                Satu Aplikasi untuk mempermudah seluruh kegiatan Perusahaan Anda
              </h3>
            </div>
            <div className={style.buttonSelingan}>
              <button className={style.btnSelingan}>Masuk</button>
            </div>
          </div>
        </div> */}
        <div className={style.contentWrapperTen}>
          <div className={style.contentFirst}>
            <div className={style.wrapperIMGTen}>
              <img
                className={style.logoContentTen}
                src={LogoContentTen}
                alt=""
              />
            </div>
            <Gap height={8} width={undefined} />
            <div className={style.firstTextContent10}>
              <span className={style.firstSpanContent10}>
                Komplek Ruko, Jl. Borneo Paradiso No.3, Sepinggan, Balikpapan
                Selatan, Kota Balikpapan, Kalimantan Timur, 76116
              </span>
            </div>
            <Gap height={14} width={undefined} />
            <div className={style.twoTextContent10}>
              <span className={style.SpanContent10}>
                {" "}
                <span className={style.boldSpanContent10}>
                  {" "}
                  Telepon :{" "}
                </span>{" "}
                +6281-1539-0600
              </span>
            </div>
            <div className={style.threeTextContent10}>
              <span className={style.SpanContent10}>
                <span className={style.boldSpanContent10}>Email :</span>{" "}
                ngabsignid@gmail.com
              </span>
            </div>
          </div>
          <Gap height={undefined} width={94} />
          <div className={style.contentSecond}>
            <div className={style.titleContentSecond}>
              <span className={style.spanTitleContentSecond}>Tautan Cepat</span>
            </div>
            <div className={style.listContentSecond}>
              <ul>
                <li className={style.liContentSecond}>Home</li>
                <li className={style.liContentSecond}>About Us</li>
                <li className={style.liContentSecond}>Services</li>
                <li className={style.liContentSecond}>Terms of service</li>
                <li className={style.liContentSecond}>Privacy Policy</li>
              </ul>
            </div>
          </div>
          <Gap height={undefined} width={120} />
          <div className={style.contentThird}>
            <div className={style.titleContentThird}>
              <span className={style.spanTitleContentThird}>Our Services</span>
            </div>
            <div className={style.listContentThird}>
              <ul>
                <li className={style.liContentThird}>Presensi Digital</li>
                <li className={style.liContentThird}>Jadwal Kerja</li>
                <li className={style.liContentThird}>Laporan Harian</li>
                <li className={style.liContentThird}>Performa Kerja</li>
                <li className={style.liContentThird}>Perizinan</li>
                <li className={style.liContentThird}>Pengumuman</li>
                <li className={style.liContentThird}>Gaji</li>
                {/* <li className={style.liContentThird}>Perizinan</li> */}
              </ul>
            </div>
          </div>
          <Gap height={undefined} width={120} />
          <div className={style.contentFourth}>
            <div className={style.titleContentFourth}>
              <span className={style.spanTitleContentFourth}>
                Berlangganan Sekarang
              </span>
            </div>
            <Gap height={12} width={undefined} />
            <div className={style.subTitleContentFourth}>
              <span className={style.spanSubTitleContentFourth}>
                Berlangganan buletin untuk mendapatkan info baru dan promosi
                dari Ngabsign
              </span>
            </div>
            <Gap height={24} width={undefined} />
            <div className={style.inputContentFourth}>
              <div>
                <input
                  className={style.ipContentFourth}
                  type="text"
                  placeholder="Masukkan Email Anda"
                />
              </div>
              <div>
                <button className={style.buttonContentFourth}>Subcribe</button>{" "}
              </div>
            </div>
            <Gap height={55.43} width={undefined} />
            <div className={style.imgContentFifth}>
              <div>
                <img
                  className={style.imageContentFifth}
                  src={GooglePlay}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.footer}>
        <span className={style.footerAtas}>
          © Copyright <span className={style.footerBold}> Ngabsign. </span> All
          Rights Reserved
        </span>
        <span className={style.footerBawah}>
          Designed by <span className={style.footerBold}> Ngabsign.</span>
        </span>
      </div>
      <Login
        title="Approve User"
        visible={showModal}
        onClose={() => toggleModal()}
        onSubmit={() => approve()}
      ></Login>
      <OptionRegister
        title="Approve User"
        visible={showModal2}
        onClose={() => toggleModal2()}
        onSubmit={() => approve()}
      ></OptionRegister>
    </div>
  );
};

export default Pricing;
