import { useMemo, useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  Circle,
  LoadScript,
  MarkerF,
  CircleF,
  InfoWindow,
} from "@react-google-maps/api";

import styles from "./Map.module.css";
import Gap from "../atoms/Gap";
import { Link, LinkProps, useNavigate } from "react-router-dom";
import { arrowleft, logofix } from "../../assets";
import axios from "axios";
import ModalLocationPermission from "../Modal Lokasi Permission/ModalLokasiPermission";
import { error } from "console";

const Map = () => {
  const center = useMemo<google.maps.LatLngLiteral>(
    () => ({
      lat: -1.2390667208133268,
      lng: 116.8727078269888,
    }),
    []
  );
  const [distance, setDistance] = useState<number>(0);
  const [map, setMap] = useState<google.maps.Map>();
  const [marker, setMarker] = useState<google.maps.Marker | undefined>();
  const [circle, setCircle] = useState<google.maps.Circle | undefined>();
  const [location, setLocation] = useState<any>({});
  const [infoWindowOpen, setInfoWindowOpen] = useState<boolean>(false);
  const [address, setAddress] = useState("");
  const state = localStorage.getItem("state");
  const history = useNavigate();
  const [locationPermissionStatus, setLocationPermissionStatus] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPermissionDialog, setShowPermissionDialog] = useState(true);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const [userLocation, setUserLocation] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });

  console.log(`ini user loc ${userLocation.lat} ini lang ${userLocation.lng}`);

  const approve = () => {
    console.log("approved");
  };

  const handleMarkerClick = () => {
    setInfoWindowOpen(true);
  };

  const handleLoad = (map: google.maps.Map) => {
    setMap(map);
  };

  const options = useMemo<google.maps.MapOptions>(
    () => ({
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    []
  );

  const handleUserLocation = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    setUserLocation({ lat: latitude, lng: longitude });

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        location: { lat: latitude, lng: longitude },
      },
      (results, status) => {
        if (status === "OK") {
          if (results?.[0]) {
            const address = results[0].formatted_address;
            localStorage.setItem("address", address);
            localStorage.setItem("lat", latitude.toString());
            localStorage.setItem("lng", longitude.toString());
            localStorage.setItem("smile_scale", "0.9");
            // localStorage.setItem("state", "Clock In");
            setAddress(address);

            const token = localStorage.getItem("@token");
          }
        } else {
          console.log("Geocoder failed due to: " + status);
        }
      }
    );
  };

  const handleGeolocationError = (error: any) => {
    console.log(error);
  };

  const checkPermission = async () => {
    const permissionStatus = await navigator.permissions.query({
      name: "geolocation",
    });

    if (permissionStatus.state === "denied") {
      setShowPermissionDialog(true);
    }
  };

  const handleAllowAccess = () => {
    navigator.geolocation.getCurrentPosition(
      handleUserLocation,
      handleGeolocationError
    );
    setShowPermissionDialog(false);
  };

  const handleBlockAccess = () => {
    console.log("Akses lokasi diblokir oleh pengguna");
    setShowPermissionDialog(true);
  };

  useEffect(() => {
    checkPermission();
  }, []);

  useEffect(() => {
    if (map && userLocation) {
      const userMarker = new google.maps.Marker({
        position: userLocation,
        map,
      });
      const circle = new google.maps.Circle({
        center,
        // radius: 100,
        ...closeOptions,
        map,
      });
      setMarker(userMarker);
      setCircle(circle);

      const bounds = new google.maps.LatLngBounds();
      bounds.extend(userLocation);
      bounds.extend(center);

      map.fitBounds(bounds);

      const distanceInMeters =
        google.maps.geometry.spherical.computeDistanceBetween(
          userLocation,
          center
        );
      setDistance(distanceInMeters);
      console.log(`ini distance ${distanceInMeters}`);

      // const withinRadius = distanceInMeters <= circle.getRadius()!;

      // if (!withinRadius) {
      //   alert("Anda diluar radius!");
      // }
    }
  }, [map, userLocation, center]);

  const mapContainerStyle = {
    width: "100%",
    height: "100vh",
  };

  const handleButtonNext = async () => {
    try {
      const result = await navigator.permissions.query({ name: "geolocation" });
      console.log(result);
      if (result.state === "granted" || result.state === "prompt") {
        if (state === "Clock In" || state === "Clock Out") {
          history("/absensi/clock-in/user-location/camera");
        }
      } else {
        alert("Wajib akses lokasi");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyAn_Dndb3iqrnn3z05ML9WtyqhdGhJjJRg"
      libraries={["geometry", "places"]}
    >
      <div className={styles.container}>
        <div className={styles.map}>
          <GoogleMap
            zoom={20}
            center={center}
            mapContainerStyle={mapContainerStyle}
            options={options}
            onLoad={handleLoad}
          >
            {userLocation.lat !== 0 && (
              <>
                <MarkerF position={userLocation} onClick={handleMarkerClick} />
                <CircleF
                  center={center}
                  //  radius={100}
                  options={closeOptions}
                />
              </>
            )}
          </GoogleMap>
        </div>
        <div className={styles.controls}>
          <div
            className={styles.topLeftContainer}
            onClick={() => window.history.back()}
          >
            <img className={styles.arrowleft} src={arrowleft} alt="" />
            <Gap height={undefined} width={10} />
            <span>Kembali</span>
            <img src={logofix} alt="logo ngabsign" className={styles.logo} />
          </div>
          <Gap height={25} width={undefined} />

          <div>
            <p className={styles.titleLocation}>
              Apakah Lokasi Anda Sudah Benar ?
            </p>
            <p className={styles.subTitleLocation}>{address}</p>
          </div>
          <Gap height={30} width={undefined} />
          <div className={styles.wrapperbtnClockIn}>
            <button className={styles.btnClockIn} onClick={handleButtonNext}>
              Selanjutnya
            </button>
          </div>
        </div>
        {showPermissionDialog && (
          <ModalLocationPermission
            onGrantPermission={handleAllowAccess}
            title="Approve User"
            visible={showPermissionDialog}
            onClose={() => setShowPermissionDialog(false)}
            onSubmit={handleBlockAccess}
          />
        )}
      </div>
    </LoadScript>
  );
};

const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
};
const closeOptions = {
  ...defaultOptions,
  zIndex: 3,
  fillOpacity: 0.4,
  strokeColor: "#8BC34A",
  fillColor: "#8BC34A",
};

export default Map;
