import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  calendar,
  Face1Color,
  Face2Color,
  Face3Color,
  Face4Color,
  Face5Color,
  imgnotfound,
} from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import { clockInService, userService } from "../../../services";
import { clockInResponse } from "../../../types/clockIn";
import { UserResponse } from "../../../types/user";

import styles from "./DetailClockOut.module.css";
import UserLocation from "../ClockIn/user-location/UserLocation";
import { CheckPresensiResponse } from "../../../types/checkpresensi";

const DetailClockOut: React.FC = () => {
  const [profile, setProfile] = useState<UserResponse>();
  const [presensi, setPresensi] = useState<CheckPresensiResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());
  const [picture, setPicture] = useState<string | null>(null);
  // const [address, setAddress] = useState<string>("");
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setlongitude] = useState<string>("");

  // useEffect(() => {
  //   const Address = localStorage.getItem("address");
  //   if (Address) {
  //     setAddress(Address);
  //   }
  // }, []);

  useEffect(() => {
    const Latitude = localStorage.getItem("lat");
    if (Latitude) {
      setLatitude(Latitude);
    }
  }, []);

  useEffect(() => {
    const Longitude = localStorage.getItem("lng");
    if (Longitude) {
      setlongitude(Longitude);
    }
  }, []);

  localStorage.getItem("lat");
  localStorage.getItem("lng");

  const formatDate = (date: Date) => {
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    const pictureData = localStorage.getItem("picture");
    setPicture(pictureData);
  }, []);

  const history = useNavigate();

  useEffect(() => {
    const data = async () => {
      try {
        const user = await userService.getProfile();
        setProfile(user.data);
        console.log(user.data);
        localStorage.getItem("@token");
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          history("/");
        }
      }
    };
    data();

    const present = async () => {
      try {
        const present = await clockInService.getCheckClockIn();
        setPresensi(present.data);
        // console.log(present.data);
        console.log(present.data.data.clock_in, "isinyadetail");

        localStorage.getItem("@token");
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          localStorage.removeItem("@token");
          history("/");
          alert("Sesi anda berakhir, silahkan login ulang!");
        }
      }
    };
    present();
  }, []);

  console.log(presensi, "absen");

  return (
    <DashboardLayout>
      <div>test</div>
      <div className={styles.wrapper}>
        {/* <div className={styles.topWrapper}> */}
        <div className={styles.detailContainer}>
          <div className={styles.container}>
            <div className={styles.leftContainerWrapper}>
              <div className={styles.leftContainer}>
                <Gap height={30} width={undefined} />
                <div className={styles.rectangleProfileWrapper}>
                  <img
                    className={styles.rectangleProfile}
                    // src={image ?? imgnotfound}
                    src={`${presensi?.data.attendance[1].image}`}
                    alt=""
                  />
                </div>
                <div className={styles.wrapperInputClockIn}>
                  <h2 className={styles.pageTitle}>Clock Out</h2>
                  <Gap height={10} width={undefined} />
                  <form>
                    <div className={styles.perizinanFullname}>
                      <label
                        className={styles.labelFullname}
                        htmlFor="fullname"
                      >
                        Nama Lengkap
                      </label>
                      <Gap height={5} width={undefined} />
                      <p
                        className={styles.inputFullname}
                      >{`${profile?.data.employee.full_name}`}</p>
                    </div>

                    <Gap height={10} width={undefined} />

                    <div className={styles.perizinanDepartemen}>
                      <label
                        className={styles.labelDepartemen}
                        htmlFor="departemen"
                      >
                        Departemen
                      </label>
                      <Gap height={5} width={undefined} />
                      <p
                        className={styles.inputDepartemen}
                      >{`${profile?.data.employee.department}`}</p>
                    </div>

                    <Gap height={10} width={undefined} />

                    <div className={styles.tanggalClockIn}>
                      <label
                        className={styles.labelTanggalClockIn}
                        htmlFor="date"
                      >
                        Tanggal Clock Out
                      </label>
                      <Gap height={5} width={undefined} />
                      <DatePicker
                        className={styles.inputTanggalClockIn}
                        selected={startDate}
                        onChange={(date) => date && setStartDate(date)}
                        showDisabledMonthNavigation
                        readOnly
                        dateFormat="dd MMMM yyyy"
                      />
                    </div>

                    <Gap height={10} width={undefined} />

                    <div className={styles.jamMasuk}>
                      <label
                        className={styles.labelJamMasuk}
                        htmlFor="fclockin"
                      >
                        Waktu Clock Out
                      </label>
                      <Gap height={5} width={undefined} />

                      <DatePicker
                        className={styles.inputJamMasuk}
                        selected={startDate}
                        onChange={(date) => date && setStartDate(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        showDisabledMonthNavigation
                        readOnly
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        value={`${presensi?.data.attendance[1].createdAt.slice(
                          11,
                          16
                        )}`}
                      />
                      {/* <p
                        className={styles.inputJamMasuk}
                      >{`${presensi?.data.attendance[1].createdAt.slice(
                        11,
                        16
                      )}`}</p> */}
                    </div>

                    <Gap height={15} width={undefined} />
                  </form>
                </div>
              </div>
              <Gap height={40} width={undefined} />

              <div className={styles.wrapperLokasi}>
                <h3 className={styles.titleLokasi}> Lokasi Terkini </h3>
                <p
                  className={styles.subTitleLocakasi}
                >{`${presensi?.data.attendance[0].address}`}</p>
                {/* <p className={styles.subTitleLocakasi}>{address}</p> */}
              </div>
              <Gap height={80} width={undefined} />
            </div>

            <div className={styles.rightContainer}>
              {/* <div>
                <Gap height={30} width={undefined} />

                <h3 className={styles.rightTitle}>Riwayat Absensi</h3>
                <Gap height={10} width={undefined} />
                <img
                  className={styles.calendar}
                  src={calendar}
                  alt="calendar"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* </div>  */}
    </DashboardLayout>
  );
};

export default DetailClockOut;
