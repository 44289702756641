import Compressor from 'compressorjs';

export const compressFile = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6, // Adjust the compression quality (0.8 is a reasonable value)
      success: (result: File) => {
        console.log('Success doing compression: ', result.size);
        resolve(result);
      },
      error: (error: Error) => {
        console.log('Failed doing compression:', error);
        reject(error);
      },
    });
  });
};
