import React, { useEffect, useState } from "react";
import styles from "./PerizinanAll.module.css";

import Gap from "../../../../../component/atoms/Gap";
import DashLayoutAdmin from "../../../../../layouts/dashboard-layout-admin/DashLayoutAdmin";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  adminPerizinanService,
  kehadiranService,
} from "../../../../../services";
// import Pagination from "../../../component/pagination/Pagination";
// import { MonitoringKehadiranResponse } from "../../../types/kehadiran";
import DetailPerizinan from "../../../../../component/DetailPerizinan/DetailPerizinan";
import Pagination from "../../../../../component/pagination/Pagination";

const PerizinanAll: React.FC = () => {
  const navigate = useNavigate();
  const [monitoringKehadiran, setMonitoringKehadiran] = useState<any[]>([]);
  const [monitoringKehadiranPagination, setMonitoringKehadiranPagination] =
    useState<any>([]);
  const [pagePagination, setPagePagination] = useState<number>();
  const [search, setSearch] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [zeroData, setZeroData] = useState(false);

  const approve = () => {
    console.log("approved");
  };

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const inputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value;
    setSearch(data);
  };

  useEffect(() => {
    const data = async () => {
      try {
        const user = await adminPerizinanService.getAdminPerizinan();
        setMonitoringKehadiran(user.data.data.permission);
        console.log(user);
        if (user.data.data.permission.length === 0) {
          setZeroData(true);
        }
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log(error.data);
        }
      }
    };
    data();
  }, []);

  const DataPagination = (data: string): void => {
    setMonitoringKehadiranPagination(data);
  };
  const DataPagePagination = (data: number): void => {
    setPagePagination(data);
  };

  return (
    <>
      <Gap height={30} width={undefined} />
      <div className={styles.detailContainer}>
        <div className={styles.container}>
          <div className={styles.input}>
            <div className={styles.search}>
              <input
                className={styles.inputSearch}
                onChange={inputSearch}
                id="search"
                type="text"
                name="search"
                placeholder="Cari berdasarkan nama"
              />
            </div>
            <Gap height={undefined} width={30} />

            <div className={styles.pilihDepartemen}>
              <input
                className={styles.inputPilihDepartemen}
                id="search"
                type="text"
                name="search"
                placeholder="Pilih Departemen"
              />
            </div>
            <Gap height={undefined} width={30} />

            <div className={styles.absensiHarian}>
              <input
                className={styles.inputAbsensiHarian}
                id="search"
                type="text"
                name="search"
                placeholder="Absensi Harian"
              />
            </div>
            <Gap height={undefined} width={30} />
            {/* <div className={styles.filter}>
                <img className={styles.imgFilter} src={filter} alt="Filter" />
                </div> */}
          </div>
          <div className={styles.tablecontainer}>
            <table className={styles.table}>
              <thead className={styles.head}>
                <tr className={styles.tr}>
                  <th className={styles.th}>Nama</th>
                  <th className={styles.th}>Departement</th>
                  <th className={styles.th}>Tanggal</th>
                  <th className={styles.th}>Jenis</th>
                  <th className={styles.th}>Verifikasi</th>
                  <th className={styles.th}>Lihat Detail</th>
                </tr>
              </thead>
              <tbody className={styles.body}>
                {monitoringKehadiranPagination && search.length >= 1
                  ? monitoringKehadiran
                      .filter((response: any) => {
                        if (search === "") {
                          return response;
                        } else if (
                          response.employee.full_name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return response;
                        }
                      })
                      .map((item, index) => {
                        return (
                          <tr key={index} className={styles.trBody}>
                            <td className={styles.td}>
                              <p className={styles.isi}>
                                {item.employee.full_name}
                              </p>
                            </td>
                            <td className={styles.td}>
                              <p className={styles.isi}>
                                {item.employee.department}
                              </p>
                            </td>
                            <td className={styles.td}>
                              <p className={styles.isi}>{item.start_date}</p>
                            </td>
                            <td className={styles.td}>
                              <p className={styles.isi}> {item.type}</p>
                            </td>
                            <td className={styles.td}>
                              <p className={styles.isi}> {item.status}</p>
                            </td>
                            <td className={styles.td}>
                              <Link
                                to={""}
                                className={styles.isi}
                                onClick={() => {
                                  toggleModal();
                                }}
                              >
                                {" "}
                                Lihat Detail
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                  : monitoringKehadiranPagination
                      .filter((response: any) => {
                        if (search === "") {
                          return response;
                        } else if (
                          response.full_name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return response;
                        }
                      })
                      .map((item: any, index: any) => (
                        <tr>
                          <td className={styles.td}>
                            <p className={styles.isi}>
                              {item.employee.full_name}
                            </p>
                          </td>
                          <td className={styles.td}>
                            <p className={styles.isi}>
                              {item.employee.department}
                            </p>
                          </td>
                          <td className={styles.td}>
                            <p className={styles.isi}>{item.start_date}</p>
                          </td>
                          <td className={styles.td}>
                            <p className={styles.isi}> {item.type}</p>
                          </td>
                          <td className={styles.td}>
                            <p className={styles.isi}> {item.status}</p>
                          </td>
                          <td className={styles.td}>
                            <Link
                              to={""}
                              className={styles.isi}
                              onClick={() => {
                                toggleModal();
                              }}
                            >
                              {" "}
                              Lihat Detail
                            </Link>
                          </td>
                        </tr>
                      ))}
              </tbody>
            </table>
            <Gap height={50} width={undefined} />
            {zeroData && <div className={styles.zeroData}>Belum ada data</div>}
          </div>
        </div>
        <div className="wrap_pagenation_filter">
          <Pagination
            getDataTable={DataPagination}
            page={DataPagePagination}
            DataPerrent={monitoringKehadiran}
          />
        </div>
      </div>
      <DetailPerizinan
        title="Approve User"
        visible={showModal}
        onClose={() => toggleModal()}
        onSubmit={() => approve()}
      ></DetailPerizinan>
    </>
  );
};

export default PerizinanAll;
