import React from "react";
import { gpBlack, presensidigital } from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import style from "./PresensiDigital.module.css";
const PresensiDigital: React.FC = () => {
  return (
    //commentt
    <div className={style.wrapper}>
      <div className={style.wrapperLeft}>
        <img src={presensidigital} alt="" />
      </div>
      <Gap height={undefined} width={270} />
      <div className={style.wrapperRight}>
        <div className={style.title}>
          Presensi digital dilengkapi fitur pengingat
        </div>
        <Gap height={14} width={undefined} />
        <div className={style.subTitle}>
          Hadir tanpa ribet ke kantor dan mengisi manual menggunakan kertas.
          Dengan Ngabsign presensi jadi lebih fleksibel baik bekerja dari
          kantor, dari rumah atau dari manapun dengan pengenalan wajah dan
          geolokasi yang akurat dilengkapi fitur pengingat agar tak khawatir
          terlambat.
        </div>
        <Gap height={44} width={undefined} />
        <div>
          <button className={style.button}>Mulai Gratis</button>
        </div>
        <Gap height={40} width={undefined} />
        <div>
          <img src={gpBlack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default PresensiDigital;
