import searching from "../../assets/images/Searching.png";

const PerizinanNotFound: React.FC = () => {
  return (
    <div className="flex flex-col w-full items-center mt-28">
      <img className="w-48 h-48" src={searching} alt="searching" />
      <p className="text-sm text-gray-400">Ups, data tidak ditemukan</p>
    </div>
  );
};

export default PerizinanNotFound;
