import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../component/navbar/DashboardNavbar";
import Sidebars from "../../component/sidebars/DashboardSidebar";
import styles from "./DashboardLayout.module.css";

export interface DashlayoutProps {
  children: React.ReactNode;
}

const DashboardLayout: React.FC<DashlayoutProps> = (props) => {
  return (
    <div className={styles.wrapper}>
      <Navbar children={undefined} />
      <div className={styles.main}>
        <Sidebars children={undefined} />
        <div className={styles.content}>{props.children}</div>
      </div>
    </div>
  );
};

export default DashboardLayout;
