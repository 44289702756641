import React from "react";
import Navbar from "../../component/navbar/DashboardNavbar";
import SidebarsAdmin from "../../component/sidebarsadmin/DashboardSidebarAdmin";
import styles from "./DashLayoutAdmin.module.css";

export interface DashlayoutProps {
    children: React.ReactNode;
}

const DashLayoutAdmin: React.FC<DashlayoutProps>= (props) => {
    return (
      <div className={styles.wrapper}>
        <Navbar children={undefined} />
        <div className={styles.main}>
          <SidebarsAdmin children={undefined} />
          <div className={styles.content}>{props.children}</div>
        </div>
      </div>
    );
  }

export default DashLayoutAdmin;