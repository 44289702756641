import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TableColumn } from "../../../../component/table/table";
import DashLayoutAdmin from "../../../../layouts/dashboard-layout-admin/DashLayoutAdmin";
import { adminPerizinanService } from "../../../../services";
import { AdminPerizinanResponse } from "../../../../types/adminPerizinan";
import styles from "./Perizinan.module.css";

const AdminPerizinan: React.FC = () => {
  const [perizinan, setPerizinan] = useState<AdminPerizinanResponse[]>([]);

  // useEffect(() => {
  //   const data = async () => {
  //     try {
  //       const user = await adminPerizinanService.getAdminPerizinan();
  //       setPerizinan(user.data);
  //       console.log(user);
  //     } catch (error: any) {
  //       if (error.response.status == 400) {
  //         console.log(error.data);
  //       }
  //     }
  //   };
  //   data();
  // }, []);
  // const column: TableColumn<AdminPerizinanResponse>[] = [
  //   {
  //     title: "Nama",
  //     dataIndex: "name",
  //     sort: "name",
  //   },
  //   {
  //     title: "Address",
  //     render: (data) => {
  //       const { street, city, zipcode } = data.address;
  //       return `${street}, ${city}, ${zipcode}`;
  //     },
  //   },
  // ];
  return (
    <DashLayoutAdmin>
      <div className={styles.wrapper}>
        <div className={styles.topWrapper}></div>
      </div>
    </DashLayoutAdmin>
  );
};

export default AdminPerizinan;
