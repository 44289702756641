import React from "react";
import {
  gpBlack,
  laporanKegiatanLP,
  presensidigital,
  riwayatPresensi,
} from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import style from "./LaporanKegiatanLP.module.css";

const LaporanKegiatanLP: React.FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.wrapperLeft}>
        <img src={laporanKegiatanLP} alt="" />
      </div>
      {/* <Gap height={undefined} width={50} /> */}
      <div className={style.wrapperRight}>
        <div className={style.title}>
          Fitur Laporan Harian agar tak pusing diakhir bulan
        </div>
        <Gap height={14} width={undefined} />
        <div className={style.subTitle}>
          Memfasilitasi anda mencatat laporan pekerjaan setiap harinya agar
          rekap laporan pekerjaan akhir bulan nanti jadi lebih mudah.
        </div>
        <Gap height={44} width={undefined} />
        <div>
          <button className={style.button}>Mulai Gratis</button>
        </div>
        <Gap height={40} width={undefined} />
        <div>
          <img src={gpBlack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default LaporanKegiatanLP;
