import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./DashboardSidebarAdmin.module.css";
import {
  AbsensiBlue,
  AbsensiWhite,
  DashboardBlue,
  DashboardWhite,
  PerizinanBlue,
  PerizinanWhite,
} from "../../assets/index";

export interface SidebarsProps {
  children: React.ReactNode;
}

const SidebarsAdmin: React.FC<SidebarsProps> = () => {
  let location = useLocation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {/* {location.pathname === "/admin" ? (
          <Link to="/" className={styles.linkSidebarActive}>
            <img
              src={DashboardBlue}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />

            <h5 className={styles.textColor}>Dashboard Admin</h5>
          </Link>
        ) : (
          <Link to="/admin" className={styles.linkSidebar}>
            <img
              src={DashboardWhite}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />

            <h5 className={styles.text}>Dashboard Admin</h5>
          </Link>
        )} */}
        {location.pathname === "/admin/monitoring-kehadiran" ? (
          <Link
            to="/admin/monitoring-kehadiran"
            className={styles.linkSidebarActive}
          >
            <img
              src={AbsensiBlue}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5 className={styles.textColor}>Monitoring Kehadiran</h5>
          </Link>
        ) : (
          <Link to="/admin/monitoring-kehadiran" className={styles.linkSidebar}>
            <img
              src={AbsensiWhite}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5 className={styles.text}>Monitoring Kehadiran</h5>
          </Link>
        )}
        {/* {location.pathname === "/admin/perizinan" ? (
          <Link to="/admin/perizinan" className={styles.linkSidebarActive}>
            <img
              src={AbsensiBlue}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5 className={styles.textColor}>Perizinan</h5>
          </Link>
        ) : (
          <Link to="/admin/perizinan" className={styles.linkSidebar}>
            <img
              src={AbsensiWhite}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5 className={styles.text}>Perizinan</h5>
          </Link>
        )} */}
        {location.pathname === "/admin/perizinan" ? (
          <Link to="/admin/perizinan" className={styles.linkSidebarActive}>
            <img
              src={AbsensiBlue}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5 className={styles.textColor}>Perizinan</h5>
          </Link>
        ) : (
          <Link to="/admin/perizinan" className={styles.linkSidebar}>
            <img
              src={AbsensiWhite}
              alt="icon sidebar"
              className={styles.iconSidebar}
            />
            <h5 className={styles.text}>Perizinan</h5>
          </Link>
        )}
      </div>
    </div>
  );
};

export default SidebarsAdmin;
