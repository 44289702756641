import React from "react";
import { gpBlack, presensidigital, riwayatPresensi } from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import style from "./RiwayatPresensi.module.css";

const RiwayatPresensi: React.FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.wrapperLeft}>
        <img src={riwayatPresensi} alt="" />
      </div>
      <Gap height={undefined} width={270} />
      <div className={style.wrapperRight}>
        <div className={style.title}>Riwayat</div>
        <Gap height={14} width={undefined} />
        <div className={style.subTitle}>
          Detail kehadiran terdata secara akurat di database Perusahaan dan
          karyawan lebih mudah melihat riwayat kehadirannya secara keseluruhan,
          total hadir dan tidak hadir beserta dokumentasi presensi.
        </div>
        <Gap height={44} width={undefined} />
        <div>
          <button className={style.button}>Mulai Gratis</button>
        </div>
        <Gap height={40} width={undefined} />
        <div>
          <img src={gpBlack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default RiwayatPresensi;
