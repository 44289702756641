import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import PerizinanNotFound from "../PerzinanNotFound/PerizinanNotFound";
import { useEffect } from "react";
import { Link } from "react-router-dom";

interface DataPerizinan {
  id: number;
  type: string;
  start_date: string;
  end_date: string;
  attachment: string;
  description: string;
  status: string;
  created_at: string;
}

interface PropsVerifed {
  permission: DataPerizinan[] | undefined;
}

const VerifedPerizinanList: React.FC<PropsVerifed> = (props) => {
  useEffect(() => {
    console.log("datanya ya : ", !props.permission);
  }, []);
  return (
    <div className="container w-full px-10">
      {props.permission?.length === 0 ? (
        <PerizinanNotFound />
      ) : (
        props.permission?.map((item, index) => (
          <Link key={index} to={`/detail-perizinan/${item.id}`}>
            <div className="list flex flex-row w-full justify-between items-center border-solid border-gray-400 border-2 rounded-lg py-4 px-6">
              <div className="">
                <div className="font-semibold text-base">
                  {item.type === "sick" ? "Sakit" : "Cuti"}
                </div>
                <div className="text-gray-400 text-xs">{item.created_at}</div>
              </div>
              {item.status === "accept" ? (
                <div className="bg-green-600 text-green-400 rounded-full text-2xl">
                  <FaCheckCircle />
                </div>
              ) : (
                <div className="bg-red-600 text-red-400 rounded-full text-2xl">
                  <FaTimesCircle />
                </div>
              )}
            </div>
          </Link>
        ))
      )}
    </div>
  );
};

export default VerifedPerizinanList;
