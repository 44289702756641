import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { calendar, imgnotfound } from "../../../../assets";
import Gap from "../../../../component/atoms/Gap";
import ModalClockIn from "../../../../component/modal-clock-out/modalclockin";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import { clockInService, userService } from "../../../../services";
import { clockIn } from "../../../../services/clockIn";
import { UserResponse } from "../../../../types/user";
import styles from "./ClockIn.module.css";
import { LoadScript } from "@react-google-maps/api";
import ClockOut from "../../ClockOut/ClockOut";
import { CheckPresensiResponse } from "../../../../types/checkpresensi";
import Attendance from "../../Attendance/Attendance";

const ClockIn: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());
  const [date, setDate] = useState();
  const [clockIn, setClockIn] = useState<string>("");
  const [profile, setProfile] = useState<UserResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [presensi, setPresensi] = useState<CheckPresensiResponse>();

  const history = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (form: any) => {
    const formData: any = new FormData();
    console.log(formData);
    localStorage.getItem("@token");
    setLoading(true);
    try {
      const clockIn = await clockInService.clockIn(form);
      console.log(clockIn);
    } catch (error: any) {
      if (error.response.status == 400) {
        console.log("kesalahan");
      } else if (error.response.status == 403) {
        alert("Sudah melakukan clock in");
      } else if (error.response.status === 401) {
        localStorage.removeItem("@token");
        history("/");
        alert("Sesi anda berakhir, silahkan login ulang!");
      } else {
        if (error.response.data.meta.message) {
          alert(error.response.data.meta.message);
        } else {
          alert("Terjadi masalah!");
        }
      }
    }

    setLoading(false);
  };

  // useEffect(() => {
  //     const data = async (form: any) => {
  //       // const formData: any = new FormData();
  //       // formData.append("full_name", form.full_name);
  //       // formData.append("department", form.department);
  //       try {
  //         const user = await userService.getProfile();
  //         setProfile (user.data);
  //         console.log(user.data)
  //         localStorage.getItem("@token");
  //         return(false);
  //       } catch (error: any) {
  //         if (error.response.status == 400) {
  //           console.log("kesalahan");
  //         }
  //       }
  //     };

  //   } ,[]);
  useEffect(() => {
    const data = async () => {
      try {
        const user = await userService.getProfile();
        setProfile(user.data);
        console.log(user.data);
        localStorage.getItem("@token");
        return false;
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        }
      }
    };
    data();
    const present = async () => {
      try {
        const present = await clockInService.getCheckClockIn();
        setPresensi(present.data);
        // console.log(present.data);
        console.log(present.data.data.clock_in, "isinyadetail");

        localStorage.getItem("@token");
      } catch (error: any) {
        if (error.response.status == 400) {
          console.log("kesalahan");
        } else if (error.response.status == 401) {
          localStorage.removeItem("@token");
          history("/");
          alert("Sesi anda berakhir, silahkan login ulang!");
        }
      }
    };
    present();
  }, []);

  // komen

  const approve = () => {
    console.log("approved");
  };

  const toggleModal = () => {
    // Cara 2.1
    // setShowModal(!showModal)

    // Cara 2.2
    setShowModal((prevState) => !prevState);
  };
  return (
    <DashboardLayout>
      {/* <div className="mb-28">
        <Attendance
          name={profile?.data.employee.full_name}
          departement={profile?.data.employee.department}
          time={presensi?.data.attendance[0]?.createdAt.slice(11, 16)}
          valueBtn="Clock In"
          img={
            presensi?.data.clock_in === true
              ? presensi?.data.attendance[0]?.image
              : imgnotfound
          }
        >
          <div className={styles.wrapperbtnClockIn}>
            <LoadScript
              googleMapsApiKey="AIzaSyAn_Dndb3iqrnn3z05ML9WtyqhdGhJjJRg"
              libraries={["geometry", "places"]}
            >
              <div>
                <button
                  className={styles.btnClockIn}
                  style={
                    presensi?.data.clock_in === true
                      ? { background: "#b0b0b0" }
                      : {}
                  }
                  onClick={() => {
                    localStorage.setItem("state", "Clock In");
                    history("/absensi/clock-in/user-location");
                  }}
                  disabled={presensi?.data.clock_in === true ? true : false}
                >
                  Clock In
                </button>
              </div>
            </LoadScript>
          </div>
        </Attendance>
        <Attendance
          name={profile?.data.employee.full_name}
          departement={profile?.data.employee.department}
          time={presensi?.data.attendance[1]?.createdAt.slice(11, 16)}
          valueBtn="Clock Out"
          img={
            presensi?.data.clock_out === true
              ? presensi?.data.attendance[1]?.image
              : imgnotfound
          }
        >
          <div className={styles.wrapperbtnClockIn}>
            <LoadScript
              googleMapsApiKey="AIzaSyAn_Dndb3iqrnn3z05ML9WtyqhdGhJjJRg"
              libraries={["geometry", "places"]}
            >
              <div>
                <button
                  className={styles.btnClockIn}
                  onClick={() => {
                    localStorage.setItem("state", "Clock Out");
                    history("/absensi/clock-in/user-location");
                  }}
                  style={
                    presensi?.data.clock_in === true
                      ? presensi?.data.clock_out === true
                        ? { background: "#b0b0b0" }
                        : {}
                      : { background: "#b0b0b0" }
                  }
                  disabled={
                    presensi?.data.clock_in === true
                      ? presensi?.data.clock_out === true
                        ? true
                        : false
                      : false
                  }
                >
                  Clock Out
                 
                </button>
              </div>
            </LoadScript>
          </div>
        </Attendance>
      </div> */}
      <div className={styles.wrapper}>
        <div className={styles.topWrapper}>
          <div className={styles.detailContainer}>
            <div className={styles.container}>
              <div className={styles.leftContainerWrapper}>
                <div className={styles.leftContainer}>
                  <Gap height={30} width={undefined} />
                  <div className={styles.rectangleProfileWrapper}>
                    <img
                      className={styles.rectangleProfile}
                      src={
                        presensi?.data.clock_in === true
                          ? presensi?.data.attendance[0].image
                          : imgnotfound
                      }
                      alt=""
                    />
                  </div>
                  <div className={styles.wrapperInputClockIn}>
                    <h2 className={styles.pageTitle}>Clock In</h2>
                    <Gap height={10} width={undefined} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className={styles.perizinanFullname}>
                        <label
                          className={styles.labelFullname}
                          htmlFor="fullname"
                        >
                          Nama Lengkap
                        </label>
                        <Gap height={5} width={undefined} />
                        <p
                          className={styles.inputFullname}
                        >{`${profile?.data.employee.full_name}`}</p>
                      </div>

                      <Gap height={10} width={undefined} />

                      <div className={styles.perizinanDepartemen}>
                        <label
                          className={styles.labelDepartemen}
                          htmlFor="departemen"
                        >
                          Departemen
                        </label>
                        <Gap height={5} width={undefined} />
                        <p
                          className={styles.inputDepartemen}
                        >{`${profile?.data.employee.department}`}</p>
                      </div>

                      <Gap height={10} width={undefined} />

                      <div className={styles.tanggalClockIn}>
                        <label
                          className={styles.labelTanggalClockIn}
                          htmlFor="date"
                        >
                          Tanggal
                        </label>
                        <Gap height={5} width={undefined} />

                        {/* <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  /> */}
                        {/* <DatePicker
                      className={styles.inputTanggalClockIn}
                      selected={startDate}
                      onChange={(date) => date && setStartDate(date)}
                      showDisabledMonthNavigation
                      readOnly
                    /> */}
                        {presensi?.data.clock_in === true ? (
                          <DatePicker
                            className={styles.inputTanggalClockIn}
                            selected={startDate}
                            onChange={(date) => date && setStartDate(date)}
                            showDisabledMonthNavigation
                            readOnly
                            dateFormat="dd MMMM yyyy"
                          />
                        ) : (
                          <p>-</p>
                        )}
                      </div>

                      <Gap height={10} width={undefined} />

                      <div className={styles.jamMasuk}>
                        <label
                          className={styles.labelJamMasuk}
                          htmlFor="fclockin"
                        >
                          Jam Masuk
                        </label>
                        <Gap height={5} width={undefined} />

                        {/* <DatePicker
                      className={styles.inputJamMasuk}
                      selected={startDate}
                      onChange={(date) => date && setStartDate(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      showDisabledMonthNavigation
                      readOnly
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    /> */}
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {presensi?.data.clock_in === true ? (
                            <DatePicker
                              className={styles.inputJamMasuk}
                              selected={startDate}
                              onChange={(date) => date && setStartDate(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              showDisabledMonthNavigation
                              readOnly
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              value={`${presensi?.data.attendance[0].createdAt.slice(
                                11,
                                16
                              )}`}
                            />
                          ) : (
                            <p>-</p>
                          )}
                          {presensi?.data.clock_in === true ? (
                            <div className={styles.wrapperStatus}>
                              <p
                                className={`${styles.onTimeStatus} ${
                                  presensi?.data.attendance[0].status ===
                                  "on time"
                                    ? ""
                                    : styles.lateStatus
                                }`}
                              >
                                {presensi?.data.attendance[0].status ===
                                "on time"
                                  ? "Tepat Waktu"
                                  : "Terlambat"}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <Gap height={15} width={undefined} />
                    </form>
                  </div>
                </div>

                <div className={styles.wrapperbtnClockIn}>
                  <LoadScript
                    googleMapsApiKey="AIzaSyAn_Dndb3iqrnn3z05ML9WtyqhdGhJjJRg"
                    libraries={["geometry", "places"]}
                  >
                    <div>
                      <button
                        className={styles.btnClockIn}
                        style={
                          presensi?.data.clock_in === true
                            ? { background: "#b0b0b0" }
                            : {}
                        }
                        onClick={() => {
                          localStorage.setItem("state", "Clock In");
                          history("/absensi/clock-in/user-location");
                        }}
                        disabled={
                          presensi?.data.clock_in === true ? true : false
                        }
                      >
                        Clock In
                      </button>
                    </div>
                  </LoadScript>
                </div>
              </div>

              {/* <div className={styles.rightContainer}>
              <div>
                <Gap height={30} width={undefined} />

                <h3 className={styles.rightTitle}>Riwayat Absensi</h3>
                <Gap height={10} width={undefined} />
                <img
                  className={styles.calendar}
                  src={calendar}
                  alt="calendar"
                />
              </div>
            </div> */}
              <ClockOut />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ClockIn;
