import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Face1Color, Face2Color, Face3Color, Face4Color, Face5Color } from "../../assets";
import { clockInService } from "../../services";
import { clockInRequest } from "../../types/clockIn";
import Gap from "../atoms/Gap";
// import Button, { ButtonProps } from "../Button/Button";
import "./modalface.css";

interface ModalProps {
  title: string;
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children?: React.ReactNode;
  closeText?: string;
  submitText?: string;
  // closeBtnProps?: Omit<ButtonProps, "children">;
  // submitBtnProps?: Omit<ButtonProps, "children">;
}





const ModalFace: React.FC<ModalProps> = (props) => {
  // const [smile, setSmile] = useState({
  //   smile_scale: ""
  // });
  const [smile, setSmile] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [smile_scale, setSmile_Scale] = useState<string>("")
  
  const {  children, onClose, visible } = props;
  const [active, setActive] = useState<string>('')
  const [data, setData] = useState({
   smile_scale: ""
  })
  const[btnDisable, setBtnDisable] = useState<boolean>(false)
  
  const history = useNavigate();

  // const handleChange = (e: ChangeEvent<HTMLImageElement>) => {
  //   const image = e.target as HTMLImageElement;
  //   setData ({
  //     ...data,
  //     [e.target.name]: image ,
  //   })
  //  }
  
  //  const btnStateFn = () => {
  //   if(
  //     data.smile_scale.length>0  
  //   ){
  //     return false;
  //   } else {
  //     return true;
  //   }
  //  }
  
  //  useEffect(() => {
  //   const state = btnStateFn();
  //   setBtnDisable(state);
  //  },[
  //   data.smile_scale
  //  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e: FormEvent<HTMLFormElement>, smile_scale: string) => {   
      // console.log(form);
      e.preventDefault()
      setLoading(true);
      let data: clockInRequest =  {
        smile_scale: `${smile_scale}`,
        description: ""
      } 
      try {
        const check_smiles = await clockInService.clockIn(data);
        // setSmile(check_smiles.data.data.attendance.smile_scale)
        console.log(check_smiles)
        localStorage.getItem("@token");
        history('/absensi/clock-in')
        props.onClose()
    } catch (error: any){
        if (error.response.status == 400) {
          console.log("kesalahan");
          console.log(error)
        } else if (error.response.status == 403){
          alert("Sudah mengisi data")
            {
              window.location.href = '/absensi/clock-in/detail-clock-in'
          }
          
        } else if (error.response.status == 401){
          history('/')
          }
      }
      setLoading(false);
  
  }
// console.log(smile === '0.1', smile, 'tambahan')
// console.log(smile)
  if (!visible) return <></>;
  return (
    <div className="modal-face-wrapper">
      <div className="modal-face-box">
        <div className="modal-face-box-header">
          <div className="modal-face-box-title">
            <Gap height={20} width={undefined} />
            <h3>Bagaimana Kondisi Anda Hari Ini?</h3>
          </div>
        </div>

        <form onSubmit={(e) => onSubmit(e, smile)}>    
        <div className="modal-face-content">
          <div className="face">
            <div className="face1" >
              <img src={Face1Color} alt=""  onClick={() => setSmile("0.1")} className={smile === '0.1' ? 'xyz' : "img-gray"}/>
            </div>
            <div className="face2">
              <img src={Face2Color} alt=""  onClick={() => setSmile("0.3")} className={smile === '0.3' ? 'xyz' : "img-gray"}/>
            </div>
            <div className="face3">
              <img src={Face3Color} alt=""  onClick={() => setSmile("0.5")}  className={smile === '0.5' ? 'xyz' : "img-gray"}/>
            </div>
            <div className="face4">
              <img src={Face4Color} alt=""  onClick={() => setSmile("0.7")} className={smile === '0.7' ? 'xyz' : "img-gray"}/>
            </div>
            <div className="face5">
              <img src={Face5Color} alt=""   onClick={() => setSmile("0.9")} className={smile === '0.9' ? 'xyz' : "img-gray"} />
            </div>
          </div>
        </div>

        <div className="modal-face-footer">
          <button className="button-face" type="submit">
            Simpan
          </button>
        </div>
        </form>
      </div>
    </div>
  );
};

export default ModalFace;