import { AxiosResponse } from "axios";
import { GetStatusResponse } from "../types/orderId";
import { SubscriptionResponse } from "../types/subs-type";
import { Transaction, TransaksiRequest, TransaksiResponse } from "../types/transaksi";
import httpRequestV2 from "./apiv2";

export const transaksi = (
    request: TransaksiRequest
  ): Promise<AxiosResponse<TransaksiResponse>> => {
    return httpRequestV2.post("sub-admin/transaction/charge", request);
  };  
  export const getTransaksiResponse = (
    params: string
  ): Promise<AxiosResponse<GetStatusResponse>> => {
    return httpRequestV2.get('sub-admin/' + params + '/status');
  };  

  export const getSubscriptionType = (): Promise<AxiosResponse<SubscriptionResponse>> => {
    return httpRequestV2.get('subscription-item');
  };  

  export const getTransaksi2 = (
  ): Promise<AxiosResponse<TransaksiResponse>> => {
    return httpRequestV2.get('sub-admin/transaction/charge');
  };  