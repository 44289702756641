import { AxiosResponse } from "axios";
import { ProfileResponse, UserProfile } from "../types/profile";
import httpRequestV2 from "./apiv2";
import { UserResponse } from "../types/user";
import { ChangeProfileRequest } from "../types/changeProfileRequest";
import LogRocket from "logrocket";

export const getProfile = async (): Promise<AxiosResponse<ProfileResponse>> => {
  try {
    const response = await httpRequestV2.get("user/profile");
    const userProfile = response.data.data.user_profile;

    if (!userProfile) {
      console.error("User profile is null or undefined");
      return Promise.resolve({ data: {} } as AxiosResponse<ProfileResponse>);
    }

    LogRocket.identify(userProfile.email, {
      name: userProfile.full_name,
      email: userProfile.email,
      role: "employee",
      company: userProfile.company_name,
      phone: userProfile.phone,
      address: userProfile.address,
    });

    return response;
  } catch (error) {
    console.error("Error fetching profile:", error);
    return Promise.resolve({ data: {} } as AxiosResponse<ProfileResponse>);
  }
};

export const putProfile = async (
  request: ChangeProfileRequest
): Promise<AxiosResponse<UserResponse>> => {
  return await httpRequestV2.patch("user/profile", request);
};
