import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Alfamart, BNI, BRI, Indomart, rectangle } from "../../../assets";
import Gap from "../../../component/atoms/Gap";
import { TransaksiService } from "../../../services";
import { TransaksiResponse } from "../../../types/transaksi";
import styles from "./paymentIndomaret.module.css";
import { BsFillCaretUpFill } from "react-icons/bs";
import { GetStatusResponse } from "../../../types/orderId";

type idParams = {
  order_id: string;
};

// interface va_numbers {
//     bank: string
//     va_number: string
// }

// interface meta {
//     currency: string
//     fraud_status: string
//     gross_amount: string
//     merchant_id: string
//     order_id: string
//     payment_type: string
//     status_code : string
//     status_message: string
//     // status_message: string
//     transaction_id: string
//     transaction_status: string
//     transaction_time: string
//     va_numbers: va_numbers[]
// }

const PaymentIndomaret: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [show2, setShow2] = useState<boolean>(false);
  const [show3, setShow3] = useState<boolean>(false);
  // const [getDataTransaksi, setGetDataTransaksi] = useState<GetStatusResponse>();
  const [getStatusTransaksi, setGetStatusTransaksi] =
    useState<GetStatusResponse>();
  const { order_id } = useParams<idParams>();
  // const [meta, setMeta] = useState<meta>(
  //     {
  //         currency: "",
  //         fraud_status: "",
  //         gross_amount:  "",
  //         merchant_id: "",
  //         order_id: "",
  //         payment_type: "",
  //         status_code: "",
  //         status_message: "",
  //         transaction_id: "",
  //         transaction_status: "",
  //         transaction_time: "",
  //         va_numbers : []
  //     }
  // )
  const history = useNavigate();
  const [copyText, setCopyText] = useState("");
  const [qParam, setQParam] = useSearchParams();

  const handleCopy = () => {
    navigator.clipboard.writeText(copyText);
    alert("Berhasil di copy");
  };

  useEffect(() => {
    const getTransaksi = async (params: string | null) => {
      if (params === null) return;
      try {
        const getTransaksi = await TransaksiService.getTransaksiResponse(
          params
        );
        setGetStatusTransaksi(getTransaksi.data);
        //   setMeta(JSON.parse(getDataTransaksi?.data.transaction.transaction_meta ? getDataTransaksi?.data.transaction.transaction_meta: ""))
        console.log("sadasd", getTransaksi);
        console.log(
          JSON.parse(
            getStatusTransaksi?.data.transaction.transaction_meta
              ? getStatusTransaksi.data.transaction.transaction_meta
              : ""
          )
        );
      } catch (error: any) {
        if (error.response.status == 401) {
          history("/");
        }
      }
    };
    getTransaksi(qParam.get("oid"));
  }, []);
  // console.log(getStatusTransaksi?.data.);
  //   console.log(meta)

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperTop}>
        <p className={styles.topTitle}>
          Selesaikan pembayaran sebelum
          <span className={styles.spanTitle}>21 NOV 2022, 23 : 00 WITA</span>
        </p>
      </div>

      <div className={styles.wrapperContent}>
        <div className={styles.leftWrapper}>
          <div className={styles.formLeftWrapper}>
            <div className={styles.leftTitleWrapper}>
              <img src={Indomart} alt="" />
              <div className={styles.leftTitleTopWrapper}>
                <h4>Media Kreasi Abadi</h4>
                <h4>50 Karyawan</h4>
              </div>
            </div>
            <Gap height={10} width={undefined} />
            <div className={styles.leftcontentWrapperFirst}>
              <h4>Nomor Virtual Akun</h4>
              {/* <div className={styles.leftSubTitleTopWrapper}> */}
              <h4>Kode Perusahaan : 70012</h4>
              {/* </div> */}
            </div>
            <Gap height={10} width={undefined} />
            <div className={styles.leftContentWrapper}>
              <div className={styles.inputContentWrapperVA}>
                <p
                // onChange={(e)=>setCopyText(e.target.value)}
                >
                  {/* {meta.va_numbers.length > 0 ? meta.va_numbers[0].va_number: "loading"} */}
                </p>
                <span onClick={handleCopy}>Salin</span>
              </div>
            </div>
            <Gap height={20} width={undefined} />
            <div className={styles.leftContentWrapper}>
              <p>Jumlah Bayar</p>
              <Gap height={10} width={undefined} />
              <div className={styles.inputContentWrapper}>
                <p>
                  Rp.{getStatusTransaksi?.data.transaction.transaction_amount}
                </p>
                <span onClick={handleCopy}>Salin</span>
              </div>
              <div className={styles.danger}>
                {/* <div className={styles.segitigabiadap}>
                            </div> */}
                <img src={rectangle} alt="" />
                <div className={styles.dangerText}>
                  <p>
                    PENTING!{" "}
                    <span>
                      {" "}
                      Mohon melakukan transaksi sesuai jumlah yang tertera
                      (termasuk 3 digit terakhir)
                    </span>
                  </p>
                </div>
              </div>
              <Gap height={10} width={undefined} />
              <div className={styles.textTransaksi}>
                <h3>Sudah Menyelesaikan Transaksi?</h3>
                <Gap height={5} width={undefined} />
                <p>
                  Setelah pembayaran Anda dikonfirmasi, kami akan mengirim bukti
                  pembelian ke alamat email Anda.
                </p>
              </div>
              <Gap height={20} width={undefined} />
              <div className={styles.buttonTransaksi}>
                <button className={styles.buttonBlue}>Saya Sudah Bayar</button>
                <Gap height={10} width={undefined} />
                <button className={styles.buttonWhite}>Batalkan Pesanan</button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightWrapper}>
          <div>
            <div
              className={styles.inputPetunjukWrapper}
              onClick={() => {
                setShow((prev) => !prev);
              }}
            >
              <p>Cara melakukan pembayaran di Indomaret</p>
              <BsFillCaretUpFill className={styles.iconDropdown} />
            </div>
            <Gap height={10} width={undefined} />
            {show ? (
              <div className={styles.list_child}>
                <div className={styles.listpetunjuk}>
                  <ol className={styles.list}>
                    <li>
                      Pergi ke Indomaret terdekat dan berikan Kode Pembayaran
                      Anda ke kasir
                    </li>
                    <li>
                      Kasir Indomaret akan mengkonfirmasi transaksi dengan
                      menanyakan Jumlah Tagihan dan Nama Toko
                    </li>
                    <li>Bayar sesuai Jumlah Tagihan Anda</li>
                    <li>
                      Setelah pembayaran diterima, Anda akan menerima konfirmasi
                      yang dikirimkan ke email
                    </li>
                    <li>
                      Simpanlah struk transaksi Indomaret Anda sebagai bukti
                      pembayaran
                    </li>
                  </ol>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentIndomaret;
