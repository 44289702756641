import axios from "axios"

export const httpRequest = axios.create({
    baseURL: 'https://www.apis.ngabsign.id/api/v2/'  
})

httpRequest.interceptors.request.use(config => {
    const appToken = localStorage.getItem('@token')

    if (appToken) {
        config.headers = {
            'Authorization': `Bearer ${appToken}`
        }
    } 
    return config
})

httpRequest.interceptors.response.use(response => {
    return response
}, error => {
    if (error.response.status === 401) {
        localStorage.removeItem("@token");
        window.location.href = '/'
        console.log("Error", error )
        alert("Invalid Token")  
    }
    return Promise.reject(error)
})

export default httpRequest