import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DataPerizinan,
  ListPerizinanResponse,
} from "../../../types/ListPerizinanEmployee";
import { perizinanService } from "../../../services";
import {
  DataPerizinanByID,
  PermissionById,
} from "../../../types/perizinanById";
import { FaFilePdf } from "react-icons/fa";

const DetailPerizinan: React.FC = () => {
  const [permission, setPermission] = useState<PermissionById>();

  const param = useParams();
  const history = useNavigate();

  const getPermissionById = async (param: string) => {
    const resp = await perizinanService.getPerizinanById(param);
    console.log("resp: ", resp);
    setPermission(resp.data.data.permission);
  };

  useEffect(() => {
    getPermissionById(String(param.id));
  }, []);
  return (
    <>
      <div>
        <div
          className="toggle-close w-full h-12 grid grid-cols-3 items-center justify-center shadow  sticky top-0"
          onClick={() => history("/perizinan")}
        >
          <div className="pl-4">
            <BiArrowBack className="text-2xl text-black" />
          </div>
          <h6 className="col-span-2 text-black font-semibold">
            Pengajuan Izin
          </h6>
        </div>
        <div className="mt-12 w-full px-9 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold">
              {permission?.type === "permit" ? "Cuti" : "Sakit"}
            </h5>
            <p className="text-sm text-black font-semibold">
              {permission?.start_date} - {permission?.end_date}
            </p>
            <p className="text-xs italic text-gray-400">
              {permission?.status === "pending"
                ? "Menunggu Persetujuan"
                : permission?.status === "accept"
                ? "Diterima"
                : "Ditolak"}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="text-xs text-gray-400">Keterangan</p>
            <p className="text-xs">{permission?.description}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-xs text-gray-400">Lampiran</p>
            <a href={permission?.attachment} target="_blank">
              <div className="relative w-44 h-44 flex items-center flex-col justify-center border-solid border-2 border-gray-400 rounded-lg">
                <div className="w-full h-12 bg-white/70 absolute bottom-0 top-auto flex flex-row items-center  overflow-hidden border-solid border-t-2 border-gray-400">
                  <p className="text-xs flex-shrink-0 p-2">
                    {permission?.attachment.slice(-16)}
                  </p>
                </div>

                {permission?.attachment.slice(-3) === "pdf" ? (
                  <span className="text-8xl text-red-600">
                    <FaFilePdf />
                  </span>
                ) : (
                  <img src={permission?.attachment} alt="" />
                )}
              </div>
            </a>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailPerizinan;
